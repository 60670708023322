import React, { useState, createRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import config from "../../config"

import { useLocation } from 'react-router-dom';
import { useNetworkState } from 'react-use';

const Verify_sms = () => {
   const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    let navigate = useNavigate()
    const location = useLocation();
    // console.log("location",location);
    //location.state.email
    const [state, setState] = React.useState({ mobile_number:location.state.mobile_number , otp:"" })
    const [userState, setUserState] = useState({ full_name: "" });
    const initialTimeInSeconds = 120; // 2 minutes
    const [timeLeft, setTimeLeft] = useState(initialTimeInSeconds);
    const [timerActive, setTimerActive] = useState(true); // State to track whether the timer is active
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
      })
    const handleChangeBroker = (event) => {
        setState({
                 ...state,
                 [event.target.name]: event.target.value,
               });
  
      }
      useEffect(() => {
        let timerInterval;

        if (timerActive) {
            timerInterval = setInterval(() => {
                if (timeLeft > 0) {
                    setTimeLeft((prevTime) => prevTime - 1);
                } else {
                    setTimerActive(false); // Timer has reached 0, so deactivate it
                }
            }, 1000);
        }

        return () => {
            clearInterval(timerInterval);
        };
    }, [timeLeft, timerActive]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
  
      const axios_registration_api = (event) => {
        // event.preventDefault();
        if (state.otp != '') {
          let full_api = config.apiUrl + `/user/sms_verification`;
         // let fullOtp = state.otp1+""+state.otp2+""+state.otp3+""+state.otp4+""+state.otp5+""+state.otp6
          let sendData = {  otp: state.otp , mobile_number:state.mobile_number };
        
          // console.log(sendData);
          axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
            // console.log("response11",res);
           if(res.data.status==true){
            localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
             setUserState({full_name:res.data.output.full_name})
             config.UserId = res.data.output.customer_id
            Toast.fire({
                background:"#206bc4",
                type: 'success',
                title: res.data.message ,
                color:"white"
              });
              setTimeout(()=>{
                navigate('/customer/dashboard')
              },2000)
            
           }
           else{
            Toast.fire({
                background:"#206bc4",
                type: 'unsuccess',
                title: res.data.message ,
                color:"white"
              });
           }
            
          }).catch((e) => {
           
          });
        }
      };
      const handleResendClick = () => {
        // Reset the timer and make it active again
        setTimeLeft(initialTimeInSeconds);
        setTimerActive(true);
        let full_api = config.apiUrl + `/user/sms_resend`;
        // let fullOtp = state.otp1+""+state.otp2+""+state.otp3+""+state.otp4+""+state.otp5+""+state.otp6
         let sendData = { mobile_number: state.mobile_number, otp: state.otp };
         // console.log(sendData);
         axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
           // console.log(res);
           if (res.data.status == true) {
            Toast.fire({
                background:"#206bc4",
                type: 'success',
                title: res.data.message ,
                color:"white"
              });
            
           }else {
            Toast.fire({
                background:"#206bc4",
                type: 'success',
                title: res.data.message ,
                color:"white"
              });
           }
           
         }).catch((e) => {
           //setState({...state,isLoading: false});
 
           // toast.configure();
           // toast.error("Some thing went wrong");
           // // console.log("----error:   ", e);
         });
    };
  return (
    <div>
      {/* <?php include 'meta.php';?> */}

<section class="verify_email_box_sec">
    <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
        <div class="logo pb-5">
            <h2><img src="../../../img/logo_5.png" class="img-fluid" /></h2>
        </div>
        <div class="card login_box border-0 shadow-sm py-4 px-5 rounded-5">
            <div class="card-body">
                <div class="alert alert-info mb-4" role="alert">
                    We have sent you a six-digit OTP on your mobile no <strong>{state.mobile_number}</strong>. Please check it and enter below.
                </div>
                {/* <form action="verify_email_process.php" method="post" autocomplete="off"> */}
                    <div class="mb-3 d-flex justify-content-center">
                    <input type="text" maxLength="6" className="form-control  " id="" placeholder="XXXXXX" required  style={{width:90}} name="otp" onChange={(e)=>handleChangeBroker(e)}/>
                    </div>
                    <div class="text-center pt-3">
                        <div class="d-grid">
                            <a onClick={(e)=>axios_registration_api(e)} class="btn btn-primary btn-block">Verify OTP</a>
                        </div>
                        <div class="pt-4">
                        Didn't receive OTP? {timerActive ? (
                                        <span id="resendTimer">Resend OTP in {formatTime(timeLeft)} </span>
                                    ) : (
                                        <button onClick={handleResendClick} className="btn btn-primary">Resend OTP</button>
                                    )}
                        </div>
                    </div>
                {/* </form> */}
            </div>
        </div>
    </div>
</section>


    </div>
  )
}

export default Verify_sms