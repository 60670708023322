import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar, ModalHeader, ModalTitle } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import moment from 'react-moment';
import download from 'downloadjs'
import TablePagination from '@mui/material/TablePagination';
import { useNetworkState } from 'react-use';
import UseProgressTracker from './UseProgressTracker'
import Footer from './Footer'
import UseBulkShipmentTracker from './UseBulkShipmentTracker';
const Kyc = () => {
    let navigate = useNavigate()
    let userDetail = localStorage.getItem('ship_rocket_user');
    console.log("userDetail====", userDetail);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
  const { showProgressBar, completedOrders, totalOrders, recordId, status, toggleProgressBar } = UseProgressTracker();
  const{showorderBar, completedOrdersbulk, totalOrdersbulk, logidstate, statusstateorder, toggleProgressBarbulk}= UseBulkShipmentTracker();
    const [formData, setFormData] = React.useState({
        aadharNumber: '',
        panNumber: '',
        gstNumber: '',
        aadharFrontImage: null,
        aadharBackImage: null,
        panFrontImage: null,
        gstImage:null
      });
      const [errors, setErrors] = React.useState({});
      const[state, setState] = React.useState({})
    const [btnstate, setbtnstate] = React.useState(false);

    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({})
      };
    
      const handleFileChange = (e, type) => {
        setFormData({ ...formData, [type]: e.target.files[0] });
        setErrors({})
      };
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    });
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setbtnstate(true)
    let errors = {};
    let isValid = true;

    if (!formData.aadharNumber) {
      errors.aadharNumber = 'Aadhar Number is required';
      isValid = false;
    }
    else if (formData.aadharNumber.length !== 12) {
        errors.aadharNumber = 'Aadhar Number must be exactly 12 digits';
        isValid = false;
    }

    if (!formData.panNumber) {
      errors.panNumber = 'PAN Number is required';
      isValid = false;
    }
    else if (formData.panNumber.length > 10) {
        errors.panNumber = 'PAN Number must not exceed 10 digits';
        isValid = false;
    }


    if (formData.gstNumber && formData.gstNumber.length > 15) {
        errors.gstNumber = 'GST Number must not exceed 15 digits';
        isValid = false;
    }

    if (!formData.aadharFrontImage) {
      errors.aadharFrontImage = 'Front side of Aadhar card is required';
      isValid = false;
    }

    if (!formData.aadharBackImage) {
      errors.aadharBackImage = 'Back side of Aadhar card is required';
      isValid = false;
    }

    if (!formData.panFrontImage) {
      errors.panFrontImage = 'Front side of PAN card is required';
      isValid = false;
    }

    if (!isValid) {
      setErrors(errors);
      setbtnstate(false)
      return;
    }
        const data = new FormData();
        
        data.append('customer_id', userData.customer_id);

        data.append('aadharNumber', formData.aadharNumber);
        data.append('panNumber', formData.panNumber);
        data.append('gstNumber', formData.gstNumber);
        data.append('aadharFrontImage', formData.aadharFrontImage);
        data.append('aadharBackImage', formData.aadharBackImage);
        data.append('panFrontImage', formData.panFrontImage);
        data.append('gstImage', formData.gstImage);

        for (let [key, value] of data.entries()) {
            console.log(key, value);
          }

        try {
            let full_api = config.apiUrl + `/user/add_customer_proof`;
            axios.post(full_api, data, { headers: config.headers }).then((res) => {
                if(res){
                    Toast.fire({
                        background:"#206bc4",
                        type: 'success',
                        title: res.data.message ,
                        color:"white"
                      });
                      navigate('/customer/bank_details')
                      setbtnstate(false)
                 }
                 else{
                    Toast.fire({
                        background:"#206bc4",
                        type: 'success',
                        title: "Something Went Wrong" ,
                        color:"white"
                      });
                      setbtnstate(false)

                    }
            })
            // Handle success or redirect user
          } catch (error) {
            console.error('Error:', error);
            setbtnstate(false)

            // Handle error
          }
      }

      React.useEffect(()=>{
        shipment_list()
      },[])

      const shipment_list=()=>{
        
          let dataToSend = {
      };
        // console.log("datatoSend",dataToSend)
        let url = config.apiUrl + '/user/get_company_info';
       
       // console.log("headers =========> ", config.headers);
       
        
        axios.post(url, dataToSend, { headers: config.headers })
        // // console.log("headers ====== " , config.headers)
            .then((res) => {
              // console.log("responseJson => ", res);
              if(res.data.status==true){
                setState(res.data.output)
              }
            
           
            })
            .catch((error) => {
                //Hide Loader
              //   setLoadingstate({...loadingstate,loading:false})
                // console.log(error);
            });    
          
        
  
      }
  return (
    <div>
      <section class=" register_box_00">
    <nav class="navbar navbar-expand-sm pt-4">   
        <div class="container">
            <a class="navbar-brand" href="javascript:void(0)">
                <img src="/img/logo_5.png" class="img-fluid" />
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="mynavbar">
            <ul class="navbar-nav me-auto">
                
            </ul>
            <div class="d-flex">
                
            </div>
            </div>
        </div>
    </nav>



    <div class="row g-0 justify-content-center signup_box_oo">
        <div class="col-12 col-md-6 ">

            <div class="card my-4 document_upload_bx">
              <div class="card-body">
                <div class="text-center mb-5">
                    <h2>Upload a proof of your identity</h2>
                    <p>Requires a valid government issued ID (Aadhard Card, PAN Card)</p>
                </div>  
                <div class="mb-3">
                    <h5>Aadhar Card</h5>
                    <div class="mb-3">
                      <div class="input-group">
                          <span class="input-group-text"><i class="material-icons-outlined">badge</i></span>
                          <input type="number" class="form-control form-control-lg" placeholder="Enter Aadhar Number" required=""  name="aadharNumber"
                        value={formData.aadharNumber}
                        onChange={handleChange}/>
                      </div>
                      {errors.aadharNumber && <div className="text-danger mt-2">{errors.aadharNumber}</div>}
                    </div>
                    <div class="row mb-4">
                        <div class="col-12 col-md text-center ">
                            <div class="upload_bx">
                                <h4>Front side of your document</h4>
                                <small class="mb-3">Upload Front side of your document</small>
                                <div class="mt-3">
                                    {/* <a href="#" class="btn btn-outline-dark btn-sm">Choose File</a> */}
                                    <input className='form-control'
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => handleFileChange(e, 'aadharFrontImage')}
                          />
                           {errors.aadharFrontImage && <div className="text-danger mt-2">{errors.aadharFrontImage}</div>}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md text-center ">
                            <div class="upload_bx">
                                <h4>Back side of your document</h4>
                                <small class="mb-3">Upload Back side of your document</small>
                                <div class="mt-3">
                                    {/* <a href="#" class="btn btn-outline-dark btn-sm">Choose File</a> */}
                                    <input className='form-control'
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => handleFileChange(e, 'aadharBackImage')}
                          />
                           {errors.aadharBackImage && <div className="text-danger mt-2">{errors.aadharBackImage}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h5>PAN Card</h5>
                    <div class="mb-3">
                        <div class="input-group">
                            <span class="input-group-text"><i class="material-icons-outlined">badge</i></span>
                            <input type="text" class="form-control form-control-lg" placeholder="Enter PAN Number" required=""   name="panNumber"
                        value={formData.panNumber}
                        onChange={handleChange}/>
                        </div>
                        {errors.panNumber && <div className="text-danger mt-2">{errors.panNumber}</div>}
                    </div>
                    <div class="row mb-4">
                        <div class="col-12 col-md text-center ">
                            <div class="upload_bx">
                                <h4>Front side of your document</h4>
                                <small class="mb-3">Upload Front side of your document</small>
                                <div class="mt-3">
                                    {/* <a href="#" class="btn btn-outline-dark btn-sm">Choose File</a> */}
                                    <input className='form-control'
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => handleFileChange(e, 'panFrontImage')}
                          />
                        {errors.panFrontImage && <div className="text-danger mt-2">{errors.panFrontImage}</div>}

                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md text-center ">
                            
                        </div>
                    </div>
                </div>
                <div>
                    <h5>GST No</h5>
                    <div class="mb-3">
                        <div class="input-group">
                            <span class="input-group-text"><i class="material-icons-outlined">badge</i></span>
                            <input type="text" class="form-control form-control-lg" placeholder="Enter GST Number"   required
                        name="gstNumber"
                        value={formData.gstNumber}
                        onChange={handleChange}/>
                        </div>
                        {errors.gstNumber && <div className="text-danger mt-2">{errors.gstNumber}</div>}
                    </div>

                    <div class="row mb-4">
                        <div class="col-12 col-md text-center ">
                            <div class="upload_bx">
                                <h4>Front side of your document</h4>
                                <small class="mb-3">Upload Front side of your document</small>
                                <div class="mt-3">
                                    {/* <a href="#" class="btn btn-outline-dark btn-sm">Choose File</a> */}
                                    <input className='form-control'
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => handleFileChange(e, 'gstImage')}
                          />
                        {errors.gstImage && <div className="text-danger mt-2">{errors.gstImage}</div>}

                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md text-center ">
                            
                        </div>
                    </div>

                </div>
                <hr/>
                <div class="ps-3">
                    <h6>Note:</h6>
                    <ul class="text-muted text-start ">
                        <li>File accepted: JPEG/JPG/PNG (Max size: 1MB)</li>
                        <li>ID must be clear visible</li>
                        <li>Document should be in good condition</li>
                        <li>Document must be valid</li>
                    </ul>
                </div>
                <div class="row justify-content-between mt-5">
                    <div class="col-12 col-md-8">
                        <div class="d-grid">
                    {btnstate==true?<div className='' style={{marginLeft:"60px"}}><ReactLoading type={"spin"} color={"black"} height={40} width={30}/></div>:      <a onClick={(e)=>handleSubmit(e)} class="btn btn-primary btn-block">Upload Documents</a>}
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="d-grid">
                            <a href="" class="btn btn-light border btn-block" data-bs-toggle="modal" data-bs-target="#no_kyc">I'll do later!</a>
                        </div>
                    </div>
                </div>
              </div>
            </div>

        </div>
    </div>


 
</section>
<div class="modal " id="no_kyc">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">

      <div class="error_kyc">
        <h1><i class="material-icons-outlined">badge</i></h1>
        <p>To enhance your experience and ensure seamless transactions, we kindly request your cooperation in completing your Know Your Customer (KYC) process. KYC verification is essential to maintaining security and compliance standards.</p>

        <div class="text-start mt-4">
          <h5>Benefits:</h5>
          <ul>
            <li>Unlock full account features</li>
            <li>No restrictions on bookings</li>
          </ul>

          <h5>Without completing KYC:</h5>
          <ul>
            <li>Limit of {state.limit_shipments} shipments bookings</li>
          </ul>
        </div>



        <div class="row justify-content-between p-1 py-3">
          <div class="col-12 col-md-6">
            <div class="d-grid">
              <a href="" class="btn btn-primary btn-block " data-bs-dismiss="modal">I understand</a>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="d-grid">
              <a href="/customer/bank_details" class="btn btn-outline-danger  btn-block" >I'll do later!</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<Footer
                    showProgressBar={showProgressBar}
                    completedOrders={completedOrders}
                    totalOrders={totalOrders}
                    viewLogs={recordId}
                    status ={status}
                     toggleProgressBar={toggleProgressBar} 
                     showorderBar={showorderBar}
                     completedOrdersbulk={completedOrdersbulk}
                     totalOrdersbulk={totalOrdersbulk}
                     viewLogslogid={logidstate}
                     statusbulk ={statusstateorder}
                      toggleProgressBarbulk={toggleProgressBarbulk} 
                  />  
    </div>
  )
}

export default Kyc
