import React from 'react'
import Left_menu from './Left_menu'

import Billing_nav from './Billing_nav'
import {  useNavigate } from 'react-router-dom'

import axios from "axios";
import Swal from 'sweetalert2';

import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import config from "../../config"
import Header from './Header';
import { useNetworkState } from 'react-use';
import Chart from 'chart.js/auto';
import Footer from './Footer';
import UseProgressTracker from './UseProgressTracker';
import UseBulkShipmentTracker from './UseBulkShipmentTracker';


const Dashboard = () => {
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const[balancestate,setbalanceState]= React.useState({total_balance:[]})
  const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
  const [userState, setUserState] = React.useState({ full_name: "" });
  const [kycstate , setkyc] = React.useState(true)
  const [state, setstate] = React.useState({ today_shipments:0 , yesterday_shipments:0  , today_orders:0 , yesterday_orders:0 , total_shipments:0 , pickup_shipments:0 , transit_shipments:0 , delivered_shipments:0 , today_revenue:0 , yesterday_revenue:0});
  const [fromDate, setFromDate] = React.useState(getFormattedDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)));
  const [toDate, setToDate] = React.useState(getFormattedDate(new Date()));
  const [countstate, setCount] = React.useState({count:{}});
  const [countstatecarrier, setCountcarrier] = React.useState({count:{}});
  const [shipmentData, setShipmentData] = React.useState([]);
  const [filter, setFilter] = React.useState("All");
  const [lastcod, setlastcod] = React.useState(0);

  const [chartData, setChartData] = React.useState(null);
  // const [showProgressBar, setShowProgressBar] = React.useState(false);
  // const [statusstate, setstatus] = React.useState({status:""});

  // const [completedOrders, setCompletedOrders] = React.useState(0);
  // const [totalOrders, setTotalOrders] = React.useState(0);  
  // const [recordid, setRecordid] = React.useState({ record_id: "" })
  const { showProgressBar, completedOrders, totalOrders, recordId, status, toggleProgressBar } = UseProgressTracker();
  const{showorderBar, completedOrdersbulk, totalOrdersbulk, logidstate, statusstateorder, toggleProgressBarbulk}= UseBulkShipmentTracker();
  console.log("completedOrdersbulk == " , completedOrdersbulk)
  console.log("showorderBar == " , showorderBar)
  console.log("totalOrdersbulk == " , totalOrdersbulk)
  console.log("logidstate == " , logidstate)
  console.log("statusstateorder == " , statusstateorder)


  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };



  // Function to get formatted date in YYYY-MM-DD format
 


  let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
  React.useEffect(()=>{
   
    get_wallet_balance()
    get_dashboard_data()
    getShipmentCounts()
   
  },[])

    const get_wallet_balance=()=>{
        let dataToSend = {customer_id : userData.customer_id};
       
         // console.log("datatoSend",dataToSend)
         let url = config.apiUrl + '/user/get_customer_wallet';
         axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
               // console.log("responseJson => ", res);
               if(res.data.status==true){
           setbalanceState({total_balance:res.data.find_customer_wallet})
           setlastcod(res.data.last_cod)
        }
       else{
           const Toast = Swal.mixin({
               toast: true,
               position: 'bottom-end',
               showConfirmButton: false,
               timer: 3000
             })
             Toast.fire({
               background:"rgb(231, 91, 75)",
               type: 'Unsuccess',
               title: "Something Went Wrong !",
               color:"white"
             });
       } })
           
             .catch((error) => {
               const Toast = Swal.mixin({
                   toast: true,
                   position: 'bottom-end',
                   showConfirmButton: false,
                   timer: 3000
                 })
                 Toast.fire({
                   background:"rgb(231, 91, 75)",
                   type: 'Unsuccess',
                   title: "Something Went Wrong !",
                   color:"white"
                 });
                 // console.log(error);
             });    
       
       }

       function getFormattedDate(date) {
        return date.toISOString().slice(0, 10);
      }

       const get_dashboard_data=()=>{
        let dataToSend = {customer_id : userData.customer_id};
       
         // console.log("datatoSend",dataToSend)
         let url = config.apiUrl + '/home/dashboard_api';
         axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
               // console.log("responseJson => ", res);
               if(res.data.status==true){
        //    setbalanceState({total_balance:res.data.find_customer_wallet})
        setstate({...state , today_shipments:res.data.today_shipments , yesterday_shipments:res.data.yesterday_shipments , today_orders:res.data.today_orders , yesterday_orders:res.data.yesterday_orders , total_shipments:res.data.total_shipments , pickup_shipments:res.data.pickup_shipments ,transit_shipments:res.data.transit_shipments , delivered_shipments:res.data.delivered_shipments , today_revenue: res.data.today_revenue , yesterday_revenue:res.data.yesterday_revenue })
        }
       else{
           const Toast = Swal.mixin({
               toast: true,
               position: 'bottom-end',
               showConfirmButton: false,
               timer: 3000
             })
             Toast.fire({
               background:"rgb(231, 91, 75)",
               type: 'Unsuccess',
               title: "Something Went Wrong !",
               color:"white"
             });
       } })
           
             .catch((error) => {
               const Toast = Swal.mixin({
                   toast: true,
                   position: 'bottom-end',
                   showConfirmButton: false,
                   timer: 3000
                 })
                 Toast.fire({
                   background:"rgb(231, 91, 75)",
                   type: 'Unsuccess',
                   title: "Something Went Wrong !",
                   color:"white"
                 });
                 // console.log(error);
             });    
       
       }

       const recharge_page =(sub)=>{
          // console.log("sub == " , sub[0].aadhar_kyc)
          if(sub[0].aadhar_kyc!=undefined && sub[0].aadhar_kyc==true){
         navigate('/customer/wallet_recharge')
          }
          else{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'Unsuccess',
                title: "Need to do Kyc first !",
                color:"white"
              });
          }
       }

       React.useEffect(()=>{
        getShipmentCounts()
       },[fromDate , toDate])
       
       const getShipmentCounts=()=>{
        let dataToSend = {customer_id : userData.customer_id , from_date: fromDate , to_date:toDate};
       
         // console.log("datatoSend",dataToSend)
         let url = config.apiUrl + '/shipment/getShipmentCountsByDateRange';
         axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
                console.log("responseJson => ", res);
                setCount({count:res.data.counts})
             
      })
           
             .catch((error) => {
               const Toast = Swal.mixin({
                   toast: true,
                   position: 'bottom-end',
                   showConfirmButton: false,
                   timer: 3000
                 })
                 Toast.fire({
                   background:"rgb(231, 91, 75)",
                   type: 'Unsuccess',
                   title: "Something Went Wrong !",
                   color:"white"
                 });
                 // console.log(error);
             });    
       
       }

       React.useEffect(() => {
        // Fetch shipment data from backend API
        const fetchData = async () => {
          try {
            const response = await axios.post(config.apiUrl + '/shipment/getShipmentCountsByCarrierAndStatus', {
              
              filter: filter
            },{headers:config.headers});
            setShipmentData(response.data.counts);
          } catch (error) {
            console.error("Error fetching shipment data:", error);
          }
        };
    
        fetchData();
      }, [filter]); // Fetch data when filter changes

      
      React.useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(config.apiUrl +'/shipment/zones_counts_for_dashboard' , {headers:config.headers});
            console.log("response ==" , response)
            // setShipmentCounts(response.data);
            setChartData(response.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [])

      React.useEffect(() => {
        if (chartData) {
          const ctx = document.getElementById('zonechart').getContext('2d');
          var zonechart = new Chart(ctx, {
            type: 'bar',
            data: chartData,
            options: {
              animation: {
                duration: 2000, // Animation duration in milliseconds
                easing: 'easeInOutQuart' // Animation easing function
              },
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  }
                }]
              },
              scales: {
                x: {
                  grid: {
                    display: false
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            }
          });
          return () => zonechart.destroy();
        }
       
      }, [chartData]);

    //   React.useEffect(() => {
    //     // Retrieve state from localStorage on page load
    //     const storedState = JSON.parse(localStorage.getItem('progressBarState'));
    //     console.log("storedState == " , storedState)
    //     if (storedState) {
    //       setShowProgressBar(storedState.showProgressBar);
    //       setCompletedOrders(storedState.completedOrders);
    //       setTotalOrders(storedState.totalOrders);
    //       setRecordid({record_id:storedState.recordId});
    //       setstatus({status:storedState.completedOrders==storedState.totalOrders?"finished":"pending"});
    //     }
    //        if(storedState!=null){
    //     if(storedState.completedOrders && storedState.totalOrders && storedState.completedOrders !=storedState.totalOrders){
    //       let dataToSend = {
    //         customer_id: userData.customer_id, record_id: storedState.recordId
    //       };
      
    //       // console.log("datatoSend",dataToSend)
    //       let url = config.apiUrl + '/shipment/view_summary_upload_record?customer_id=' + userData.customer_id + '&record_id=' + storedState.recordId + '';
      
    //       // // console.log("headers => ", config.headers);
    //       axios({
    //         method: "get",
    //         url: url,
    //         headers: config.headers,
    //         responseType: "stream",
    //         onDownloadProgress: progressEvent => {
    //           // console.log("progressEvent ", progressEvent.currentTarget.response);
    //           let parseData = progressEvent.currentTarget.response;
    //           parseData = parseData.split("Z");
    //           parseData = JSON.parse(parseData[parseData.length - 1]);
      
    //           console.log(parseData)
    //           localStorage.setItem('progressBarState', JSON.stringify({
    //             showProgressBar:true,
    //             completedOrders:parseData.uploaded_record,
    //            recordId:storedState.recordId,
    //            totalOrders:parseData.total_record,
    //            status: parseData.status
    //           }));
    //           setShowProgressBar(storedState.showProgressBar);
    //           setCompletedOrders(parseData.uploaded_record);
    //           setTotalOrders(parseData.total_record);
    //           setRecordid({record_id:storedState.recordId});
    //           setstatus({status:parseData.uploaded_record==parseData.total_record?"finished":"pending"});
    //           // console.log(state);
    //           // setStateRecord({ uploaded_record: parseData.uploaded_record, total_record: parseData.total_record, status: parseData.status })
    //         }
    //       })
      
    //     }
    //   }
    //   }, []);

    //   const toggleProgressBar = () => {
    //     // Toggle the visibility of the progress bar
    //     setShowProgressBar(!showProgressBar);
    //     localStorage.removeItem('progressBarState');
    // // You may add other logic related to closing here
    // // For example, resetting state variables
    // setShowProgressBar(false);
    // setCompletedOrders(0);
    // setTotalOrders(0);
    // setRecordid({ record_id: null });
    // setstatus({ status: '' });
    //   };
      
    return (
        // <div>
        //     <Left_menu value={1} />
        //     <section class="home ">
        //         <Header/>
        //         <section class="pt-4">
        //             <div class="container">
        //                 <ul class="nav nav-pills">
        //                     <li class="nav-item">
        //                         <a class="nav-link active" href="#">Overview</a>
        //                     </li>
        //                     <li class="nav-item">
        //                         <a class="nav-link" href="/customer/orders">Orders</a>
        //                     </li>
        //                     <li class="nav-item">
        //                         <a class="nav-link" href="/customer/shipments">Shipments</a>
        //                     </li>
        //                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        //                     <li className='nav-item'>
        //                     <a class="nav-link" >Credit Limit :  {balancestate.total_balance.map((sub)=>(
        //               sub.credit_limit? parseFloat(sub.credit_limit).toFixed(2):0))}</a>
        //                     </li>
                           
        //                 </ul>
                       
        //             </div>
        //         </section>

        //         <section class="mb-3 pt-3">
        //             <div class="container">
        //                 <div class="header-body">
        //                     <div class="row">
        //                         <div class="col-xl-3 col-lg-6">
        //                             <div class="card card-stats mb-4 mb-xl-0 bg-primary text-white">
        //                                 <div class="card-body">
        //                                     <div class="row">
        //                                         <div class="col">
        //                                             <h5 class="card-title text-uppercase mb-0">Wallet </h5>
        //                                             <span class="h2 font-weight-bold mb-0">&#8377; {balancestate.total_balance.map((sub)=>(
        //                parseFloat(sub.wallet_balance).toFixed(2)))}</span>
        //                                         </div>
        //                                         <div class="col-auto">
        //                                             <div class="icon icon-shape bg-light text-primary rounded-circle shadow">
        //                                                 <i class="material-icons-outlined">account_balance_wallet</i>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                     <div class="pt-3 ">
        //                                         <a  onClick={(e)=>recharge_page(balancestate.total_balance.map((sub)=>(
        //             sub)))} class="btn btn-outline-light btn-sm">Recharge</a>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                         <div class="col-xl-3 col-lg-6">
        //                             <div class="card card-stats mb-4 mb-xl-0">
        //                                 <div class="card-body">
        //                                     <div class="row">
        //                                         <div class="col">
        //                                             <h5 class="card-title text-uppercase text-muted mb-0">Today's Order</h5>
        //                                             <span class="h2 font-weight-bold mb-0">{state.today_orders}</span>
        //                                         </div>
        //                                         <div class="col-auto">
        //                                             <div class="icon icon-shape bg-warning text-white rounded-circle shadow">
        //                                                 <i class="material-icons-outlined">local_mall</i>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                     <p class="mt-2 mb-0 text-muted text-sm">
        //                                         <span class="text-primary mr-2">{state.yesterday_orders}</span><br />
        //                                         <span class="text-nowrap">Yesterday's Order</span>
        //                                     </p>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                         <div class="col-xl-3 col-lg-6">
        //                             <div class="card card-stats mb-4 mb-xl-0">
        //                                 <div class="card-body">
        //                                     <div class="row">
        //                                         <div class="col">
        //                                             <h5 class="card-title text-uppercase text-muted mb-0">Today's Revenue</h5>
        //                                             <span class="h2 font-weight-bold mb-0"><small></small>&#8377;{state.today_revenue}</span>
        //                                         </div>
        //                                         <div class="col-auto">
        //                                             <div class="icon icon-shape bg-success text-white rounded-circle shadow">
        //                                                 <i class="material-icons-outlined">savings</i>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                     <p class="mt-2 mb-0 text-muted text-sm">
        //                                         <span class="text-primary mr-2">&#8377;{state.yesterday_revenue}</span><br />
        //                                         <span class="text-nowrap">Yesterday's Revenue</span>
        //                                     </p>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                         <div class="col-xl-3 col-lg-6">
        //                             <div class="card card-stats mb-4 mb-xl-0">
        //                                 <div class="card-body">
        //                                     <div class="row">
        //                                         <div class="col">
        //                                             <h5 class="card-title text-uppercase text-muted mb-0">Today's Shipments</h5>
        //                                             <span class="h2 font-weight-bold mb-0">{state.today_shipments}</span>
        //                                         </div>
        //                                         <div class="col-auto">
        //                                             <div class="icon icon-shape bg-primary text-white rounded-circle shadow">
        //                                                 <i class="material-icons-outlined">view_in_ar</i>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                     <p class="mt-2 mb-0 text-muted text-sm">
        //                                         <span class="text-primary mr-2">{state.yesterday_shipments}</span><br />
        //                                         <span class="text-nowrap">Yesterday's Shipments</span>
        //                                     </p>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </section>

        //         <section class="mb-3">
        //             <div class="container">
        //                 <div class="card border-0">
        //                     <div class="card-body">
        //                         <div class="d-flex justify-content-between">
        //                             <div class="">
        //                                 <h6>Shipments Details</h6>
        //                             </div>
        //                             <div class="">
        //                                 <small>Last 30 days</small>
        //                             </div>
        //                         </div>
        //                         <div class="row states py-3">
        //                             <div class="col-12 col-md-2 mb-3">
        //                                 <div class="stbox text-center py-4 border rounded bg-light">
        //                                     <h5>{state.total_shipments}</h5>
        //                                     <small>Total Shipments</small>
        //                                 </div>
        //                             </div>
        //                             <div class="col-12 col-md-2 mb-3">
        //                                 <div class="stbox text-center py-4 border rounded bg-light">
        //                                     <h5>{state.pickup_shipments}</h5>
        //                                     <small>Pickup Pending</small>
        //                                 </div>
        //                             </div>
        //                             <div class="col-12 col-md-2 mb-3">
        //                                 <div class="stbox text-center py-4 border rounded bg-light">
        //                                     <h5>{state.transit_shipments}</h5>
        //                                     <small>In-Transit</small>
        //                                 </div>
        //                             </div>
        //                             <div class="col-12 col-md-2 mb-3">
        //                                 <div class="stbox text-center py-4 border rounded bg-light">
        //                                     <h5>{state.delivered_shipments}</h5>
        //                                     <small>Delivered</small>
        //                                 </div>
        //                             </div>
        //                             <div class="col-12 col-md-2 mb-3">
        //                                 <div class="stbox text-center py-4 border rounded bg-light">
        //                                     <h5>----</h5>
        //                                     <small>NDR Pending</small>
        //                                 </div>
        //                             </div>
        //                             <div class="col-12 col-md-2 mb-3">
        //                                 <div class="stbox text-center py-4 border rounded bg-light">
        //                                     <h5>----</h5>
        //                                     <small>RTO</small>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </section>

        //         <section class="mb-3">
        //             <div class="container">
        //                 <div class="card border-0">
        //                     <div class="card-body">
        //                         <div class="d-flex justify-content-between">
        //                             <div class="">
        //                                 <h6>COD Details</h6>
        //                             </div>
        //                             <div class="">
        //                                 <small>Last 30 days</small>
        //                             </div>
        //                         </div>
        //                         <div class="row states py-3">
        //                             <div class="col-12 col-md mb-3">
        //                                 <div class="stbox text-center py-4 border rounded bg-light">
        //                                     <h5>--</h5>
        //                                     <small>Total COD (Last 30 Days)</small>
        //                                 </div>
        //                             </div>
        //                             <div class="col-12 col-md mb-3">
        //                                 <div class="stbox text-center py-4 border rounded bg-light">
        //                                     <h5>--</h5>
        //                                     <small>COD Available</small>
        //                                 </div>
        //                             </div>
        //                             <div class="col-12 col-md mb-3">
        //                                 <div class="stbox text-center py-4 border rounded bg-light">
        //                                     <h5>--</h5>
        //                                     <small>COD Pending (Greater than 8 days)</small>
        //                                 </div>
        //                             </div>
        //                             <div class="col-12 col-md mb-3">
        //                                 <div class="stbox text-center py-4 border rounded bg-light">
        //                                     <h5>--</h5>
        //                                     <small>Last COD Remitted</small>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </section>

        //         {/* <section class="mb-3 pb-4">
        //             <div class="container">
        //                 <div class="card border-0">
        //                     <div class="card-body">
        //                         <div class="d-flex justify-content-between">
        //                             <div class="">
        //                                 <h6>NDR Details</h6>
        //                             </div>
        //                             <div class="">
        //                                 <small>Last 30 days</small>
        //                             </div>
        //                         </div>
        //                         <div class="row states py-3">
        //                             <div class="col-12 col-md mb-3">
        //                                 <div class="stbox text-center py-4 border rounded bg-light">
        //                                     <h5>0</h5>
        //                                     <small>Total NDR (Last 30 Days)</small>
        //                                 </div>
        //                             </div>
        //                             <div class="col-12 col-md mb-3">
        //                                 <div class="stbox text-center py-4 border rounded bg-light">
        //                                     <h5>0</h5>
        //                                     <small>Your Reattempt Request</small>
        //                                 </div>
        //                             </div>
        //                             <div class="col-12 col-md mb-3">
        //                                 <div class="stbox text-center py-4 border rounded bg-light">
        //                                     <h5>0</h5>
        //                                     <small>Buyer Reattempt Request</small>
        //                                 </div>
        //                             </div>
        //                             <div class="col-12 col-md mb-3">
        //                                 <div class="stbox text-center py-4 border rounded bg-light">
        //                                     <h5>0</h5>
        //                                     <small>NDR Delivered</small>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </section> */}

        //     </section>


        //     {/* <?php include 'footer.php';?> */}
        // </div>
        <div>
        <Left_menu value={1} />
            <section class="home ">
                 <Header/>
    
            <section class="mb-3">
              <div class="container-fluid">
    
                
              { balancestate.total_balance.map((sub)=>(
                   <>
                   {sub.aadhar_kyc==false && kycstate==true?
                <div class="row justify-content-center mt-3">
                  <div class="col-12 col-md-10">
                    <div class="alert alert-header">
                  
                 
                        <div class="row">
                            <div class="col-12 col-md">
                                Your KYC is pending. Please check and update your KYC or contact Support.
                            </div>
                            <div class="col-12 col-md-4">
                                <a href="/customer/customer_kyc" class="btn btn-primary-oo  btn-sm m-1">Update KYC Now</a>
                                <a onClick={(e)=>setkyc(false)} class="btn btn-outline-primary-oo  btn-sm">Remind me later!</a>
                            </div>
                        </div>
                        
                    </div>
                  </div>
                </div>
               
                :""}
              </>   ))}
                <section class="pt-3 top_state">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-xl-3 mb-4">
                            <div class="card border-0 shadow">
                                <div class="card-body">
                                    <div class="row d-block d-xl-flex align-items-center">
                                        <div class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                            <div class="icon-shape icon-shape-home rounded me-4 me-sm-0">
                                                <i class="bx bx-wallet"></i>
                                            </div>
                                        </div>
                                        <div class="col-12 col-xl-7 px-xl-0">
                                            <div class="d-none d-sm-block">
                                                <h2 class="h6 text-gray-400 mb-0"> Wallet Balance</h2>
                                                <h3 class="fw-extrabold mb-2">
                                                    <a style={{cursor:"pointer"}}>&#8377; {balancestate.total_balance.map((sub)=>(
                        parseFloat(sub.wallet_balance).toFixed(2)))}</a>
                                                </h3>
                                              
                                            </div>
                                            <small class="text-gray-500">
                                            Current Balance
                                            </small> 
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-xl-3 mb-4">
                            <div class="card border-0 shadow">
                                <div class="card-body">
                                    <div class="row d-block d-xl-flex align-items-center">
                                        <div class="col-6 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                            <div class="icon-shape icon-shape-home rounded me-4 me-sm-0">
                                                <i class="bx bx-package"></i>
                                            </div>
                                        </div>
                                        <div class="col-6 col-xl-7 px-xl-0">
                                            <div class="d-none d-sm-block">
                                                <h2 class="h6 text-gray-400 mb-0">Shipments</h2>
                                                <h3 class="fw-extrabold mb-2">
                                                    <a style={{cursor:"pointer"}}>{state.today_shipments}</a>
                                                </h3>
                                            </div>
                                            <small class="d-flex align-items-center text-gray-500">
                                                Today
                                            </small> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-xl-3 mb-4">
                            <div class="card border-0 shadow">
                                <div class="card-body">
                                    <div class="row d-block d-xl-flex align-items-center">
                                        <div class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                            <div class="icon-shape icon-shape-home rounded me-4 me-sm-0">
                                                <i class="bx bx-cart"></i>
                                            </div>
                                        </div>
                                        <div class="col-12 col-xl-7 px-xl-0">
                                            <div class="d-none d-sm-block">
                                                <h2 class="h6 text-gray-400 mb-0">Orders</h2>
                                                <h3 class="fw-extrabold mb-2">
                                                    <a style={{cursor:"pointer"}}>{state.today_orders}</a>
                                                </h3>
                                            </div>
                                            <small class="d-flex align-items-center text-gray-500">
                                               Today
                                            </small> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-xl-3 mb-4">
                            <div class="card border-0 shadow">
                                <div class="card-body">
                                    <div class="row d-block d-xl-flex align-items-center">
                                        <div class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                            <div class="icon-shape icon-shape-home rounded me-4 me-sm-0">
                                                <i class="bx bx-rupee"></i>
                                            </div>
                                        </div>
                                        <div class="col-12 col-xl-7 px-xl-0">
                                            <div class="d-none d-sm-block">
                                                <h2 class="h6 text-gray-400 mb-0"> Revenue</h2>
                                                <h3 class="fw-extrabold mb-2">
                                                    <a style={{cursor:"pointer"}}>&#8377;{state.yesterday_revenue}</a>
                                                </h3>
                                            </div>
                                            <small class="text-gray-500">
                                           Today
                                            </small> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    
                <div class="row justify-content-start mb-3">
                    <div class="col-12 col-md-6 customer_pan_box1">
                        <div class="card border-0 shadow-sm ">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-md-8">
                                    <h3>Welcome Back <small>{userData.full_name}</small></h3>
                                    <p>Choose the most Conveniet Delivery partner</p>
                                    <div class="py-3">
                                        <a style={{cursor:"pointer"}} onClick={()=>navigate({
            pathname: '/customer/create_order_1',
            search: `value=0`,
          })} class="btn btn-primary btn-sm">Add a new Parcel</a>
                                        <a style={{cursor:"pointer"}} href="/customer/rate_calculator" class="btn btn-primary-oo  btn-sm">Rate Calculator</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 ">
                        <div class="card border-0 shadow-sm customer_pan_box2">
                        <div class="card-body">
                            <div id="banner_bx" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-indicators">
                                  <button type="button" data-bs-target="#banner_bx" data-bs-slide-to="0" class="active"></button>
                                  <button type="button" data-bs-target="#banner_bx" data-bs-slide-to="1"></button>
                                  <button type="button" data-bs-target="#banner_bx" data-bs-slide-to="2"></button>
                                </div>
                              
                                <div class="carousel-inner">
                                  <div class="carousel-item active">
                                    <img src="https://picsum.photos/600" alt="Los Angeles" class="d-block w-100"/>
                                  </div>
                                  <div class="carousel-item">
                                    <img src="https://picsum.photos/400" alt="Chicago" class="d-block w-100"/>
                                  </div>
                                  <div class="carousel-item">
                                    <img src="https://picsum.photos/200" alt="New York" class="d-block w-100"/>
                                  </div>
                                </div>
                              </div>
                        </div>
                        </div>
                    </div>
                </div>
    
    
                
    
    
                
    
                <section class="customer_pan_boxe_3 mt-3">
                    <div class="row justify-content-between mb-3">
                        <div class="col-12 col-md-4">
                            <h5>Shipment Stats</h5>
                        </div>
                        <div class="col-12 col-md-3">
                            <div class="d-flex justify-content-between">
                            <input type="date" className="form-control form-control-sm" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
            <input type="date" className="form-control form-control-sm" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-12 col-md-3 mb-3">
                            <div class="card shadow-sm">
                              <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="icon_st">
                                            <i class="bx bx-package"></i>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md">
                                        <h4>Total</h4>
                                        <h3>{countstate.count.total}</h3>
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mb-3">
                            <div class="card shadow-sm">
                              <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="icon_st">
                                            <i class="bx bx-package"></i>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md">
                                        <h4>Picked</h4>
                                        <h3>{countstate.count.total}</h3>
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mb-3">
                            <div class="card shadow-sm">
                              <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="icon_st">
                                            <i class="bx bx-package"></i>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md">
                                        <h4>Delivered</h4>
                                        <h3>{countstate.count.delivered}</h3>
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mb-3">
                            <div class="card shadow-sm">
                              <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="icon_st">
                                            <i class="bx bx-package"></i>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md">
                                        <h4>RTO</h4>
                                        <h3>{countstate.count.rto}</h3>
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mb-3">
                            <div class="card shadow-sm">
                              <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="icon_st">
                                            <i class="bx bx-package"></i>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md">
                                        <h4>In Transit</h4>
                                        <h3>{countstate.count.inTransit}</h3>
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mb-3">
                            <div class="card shadow-sm">
                              <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="icon_st">
                                            <i class="bx bx-package"></i>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md">
                                        <h4>Cancelled</h4>
                                        <h3>{countstate.count.cancelled}</h3>
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mb-3">
                            <div class="card shadow-sm">
                              <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="icon_st">
                                            <i class="bx bx-package"></i>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md">
                                        <h4>Failed</h4>
                                        <h3>{countstate.count.failed}</h3>
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </section>
    
    
                {/* <section>
                    <div class="card border-0 shadow-sm mb-3">
                      <div class="card-body">
                        <div class="row mb-3">
                            <div class="col-12 col-md-4">
                                <h6>Order Summary: Today</h6>
                            </div>
                            <div class="col-12 col-md-4">
                              
                            </div>
                            <div class="col-12 col-md-4">
                                <ul class="nav nav-pills justify-content-end">
                                    <li class="nav-item">
                                        <a class="nav-link active" href="#">All</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#">Prepaid</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#">COD</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                       
                            <table class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>Carrier</th>
                                    <th>Pickup Awaitied</th>
                                    <th>Pickedup</th>
                                    <th>In Transit</th>
                                    <th>NDR</th>
                                    <th>Delivered</th>
                                    <th>RTO</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td class="bg-info">Delhivery 1KG</td>
                                    <td></td>
                                    <td>12</td>
                                    <td>33</td>
                                    <td>4</td>
                                    <td>2</td>
                                    <td>1</td>
                                  </tr>
                                  <tr>
                                    <td class="bg-info">DTDC</td>
                                    <td>01</td>
                                    <td>12</td>
                                    <td>33</td>
                                    <td>4</td>
                                    <td>2</td>
                                    <td>1</td>
                                  </tr>
                                  <tr>
                                    <td class="bg-info">Xpressbees billing</td>
                                    <td>01</td>
                                    <td>12</td>
                                    <td>33</td>
                                    <td>4</td>
                                    <td>2</td>
                                    <td>1</td>
                                  </tr>
                                  <tr>
                                    <td class="bg-info">Delhivery 20 to 100 KG</td>
                                    <td>01</td>
                                    <td>12</td>
                                    <td>33</td>
                                    <td>4</td>
                                    <td>2</td>
                                    <td>1</td>
                                  </tr>
                                  <tr>
                                    <td class="bg-info">Delhivery 10 to 19 KG</td>
                                    <td>01</td>
                                    <td>12</td>
                                    <td>33</td>
                                    <td>4</td>
                                    <td>2</td>
                                    <td>1</td>
                                  </tr>
                                  <tr>
                                    <td class="bg-info">Delhivery 2kg</td>
                                    <td>01</td>
                                    <td>12</td>
                                    <td>33</td>
                                    <td>4</td>
                                    <td>2</td>
                                    <td>1</td>
                                  </tr>
                                  <tr>
                                    <td class="bg-info"> Ecom Express 2kg</td>
                                    <td>01</td>
                                    <td>12</td>
                                    <td>33</td>
                                    <td>4</td>
                                    <td>2</td>
                                    <td>1</td>
                                  </tr>
                                  <tr>
                                    <td class="bg-info">Xpressbees logistics</td>
                                    <td>01</td>
                                    <td>12</td>
                                    <td>33</td>
                                    <td>4</td>
                                    <td>2</td>
                                    <td>1</td>
                                  </tr>
                                </tbody>
                              </table>
                       
                        </div>
                      </div>
                    </div>
                </section> */}
                  <section>
      <div className="card border-0 shadow-sm mb-3">
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-12 col-md-4">
              <h6>Order Summary: Today</h6>
            </div>
            <div className="col-12 col-md-4"></div>
            <div className="col-12 col-md-4">
              <ul className="nav nav-pills justify-content-end">
                <li className="nav-item">
                  <button
                    className={`nav-link ${filter === "All" ? "active" : ""}`}
                    onClick={() => handleFilterChange("All")}
                  >
                    All
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${filter === "prepaid" ? "active" : ""}`}
                    onClick={() => handleFilterChange("prepaid")}
                  >
                    Prepaid
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${filter === "cod" ? "active" : ""}`}
                    onClick={() => handleFilterChange("cod")}
                  >
                    COD
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Carrier</th>
                  <th>Pickup Awaited</th>
                  <th>Pickedup</th>
                  <th>In Transit</th>
                  <th>NDR</th>
                  <th>Delivered</th>
                  <th>RTO</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(shipmentData).map((carrier, index) => (
                  <tr key={index}>
                    <td className="bg-info">{carrier}</td>
                    {Object.values(shipmentData[carrier]).map((count, idx) => (
                      <td key={idx}>{count}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
                
    
                <section>
                    <div class="row">
                        <div class="col-12 col-md-12 mb-3">
                            <div class="row">
                                <div class="col-12 col-md-6 text-end">
                                    <div class="card shadow-sm border-0">
                                      <div class="card-body">
                                        <div class="d-flex justify-content-between shipment_booking_bx_1">
                                            <h6>Shipment Zones</h6>
                                            <small>Last 30 Days</small>
                                        </div>
                                        <canvas id="zonechart" width="400" height="115"></canvas>
                                      </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 text-end">
                                    <div class="card shadow-sm border-0">
                                      <div class="card-body">
                                        <div class="d-flex justify-content-between shipment_booking_bx_1">
                                            <h6>NDR Report</h6>
                                            <small>Last 30 Days</small>
                                        </div>
                                        <div class="row ndr_box_count">
                                            <div class="col-12">
                                                <div class="ndr_reot">
                                                    <div class="d-flex justify-content-between">
                                                        <div class="">
                                                            <h6>NDR Count</h6>
                                                            <h3>---</h3>
                                                        </div>
                                                        <div class="">
                                                            <i class="bx bx-file"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="ndr_reot">
                                                    <div class="d-flex justify-content-between">
                                                        <div class="">
                                                            <h6>NDR Inisiated</h6>
                                                            <h3>---</h3>
                                                        </div>
                                                        <div class="">
                                                            <i class="bx bx-checkbox-checked"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    
                <section class="customer_pan_boxe_3 mt-3">
                    <div class="row justify-content-between mb-3">
                        <div class="col-12 col-md-4">
                            <h5>COD Stats</h5>
                        </div>
                        <div class="col-12 col-md-3">
                        </div>
                    </div>
                    
                    <div class="row cod_state_dash">
                        <div class="col-12 col-md-3 mb-3">
                            <div class="card shadow-sm">
                              <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="icon_st">
                                            <i class="bx bx-money-withdraw"></i>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md">
                                        <h4>Last COD </h4>
                                        <h3>&#8377;{lastcod}</h3>
                                        {/* <small>12/12/12</small> */}
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mb-3">
                            <div class="card shadow-sm">
                              <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="icon_st">
                                            <i class="bx bx-money-withdraw"></i>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md">
                                        <h4> COD Wallet</h4>
                                        <h3>&#8377; {balancestate.total_balance.map((sub)=>(
                        parseFloat(sub.cod_wallet_balance).toFixed(2)))}</h3>
                                        {/* <small>12/12/12</small> */}
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        {/* <div class="col-12 col-md-3 mb-3">
                            <div class="card shadow-sm">
                              <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="icon_st">
                                            <i class="bx bx-money-withdraw"></i>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md">
                                        <h4>Upcoming COD </h4>
                                        <h3>$12,3398</h3>
                                        <small>12/12/12</small>
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div> */}
                    </div>
                </section>
    
                
                
                
              </div>
            </section>
            
        </section>
        <Footer
                    showProgressBar={showProgressBar}
                    completedOrders={completedOrders}
                    totalOrders={totalOrders}
                    viewLogs={recordId}
                    status ={status}
                     toggleProgressBar={toggleProgressBar} 

                     showorderBar={showorderBar}
                     completedOrdersbulk={completedOrdersbulk}
                     totalOrdersbulk={totalOrdersbulk}
                     viewLogslogid={logidstate}
                     statusbulk ={statusstateorder}
                      toggleProgressBarbulk={toggleProgressBarbulk} 
                  />
        </div>
    
    )
}

export default Dashboard
