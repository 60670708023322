import React, { useState, useEffect } from 'react'

import Left_menu from './Left_menu'
import Header from './Header'
import Create_order_nav from './Create_order_nav'
import axios from 'axios'
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';


import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import { useNetworkState } from 'react-use';
import UseProgressTracker from './UseProgressTracker'
import Footer from './Footer'
import UseBulkShipmentTracker from './UseBulkShipmentTracker'
const Customer_kyc = () => {
  const isOnline = useNetworkState();
  console.log("isONline  === " ,isOnline )
    const [state, setState] = React.useState({aadhar_number:""})
    const[refId , setrefId] = React.useState({ref_id:""})
    const[optstate , setOtp] = React.useState({otp:""})
    const[userstate , setUserState] = React.useState({})
    const[companystate , setcompanyState] = React.useState({})
    const { showProgressBar, completedOrders, totalOrders, recordId, status, toggleProgressBar } = UseProgressTracker();

    const [aadharNumber, setAadharNumber] = useState('');
    const [gststate , setgstState] = useState({gst_number:""})
    let userDetail = localStorage.getItem('ship_rocket_user');
    console.log("userDetail====", userDetail);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 5000
    })
    const [formData, setFormData] = React.useState({
      aadharNumber: '',
      panNumber: '',
      gstNumber: '',
      aadharFrontImage: null,
      aadharBackImage: null,
      panFrontImage: null,
      gstImage:null
    });
    console.log("formData == " , formData)
    const [errors, setErrors] = React.useState({});
    const [btnstate, setbtnstate] = React.useState(false);
    const{showorderBar, completedOrdersbulk, totalOrdersbulk, logidstate, statusstateorder, toggleProgressBarbulk}= UseBulkShipmentTracker();
  
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      setErrors({})
    };
  
    const handleFileChange = (e, type) => {
      setFormData({ ...formData, [type]: e.target.files[0] });
      setErrors({})
    };
   
    const handleSubmit = async (e) => {
      setbtnstate(true)
      e.preventDefault();
  let errors = {};
  let isValid = true;

  if (!formData.aadharNumber) {
    errors.aadharNumber = 'Aadhar Number is required';
    isValid = false;
  }
  else if (formData.aadharNumber.length !== 12) {
      errors.aadharNumber = 'Aadhar Number must be exactly 12 digits';
      isValid = false;
  }

  if (!formData.panNumber) {
    errors.panNumber = 'PAN Number is required';
    isValid = false;
  }
  else if (formData.panNumber.length > 10) {
      errors.panNumber = 'PAN Number must not exceed 10 digits';
      isValid = false;
  }


  if (formData.gstNumber && formData.gstNumber.length > 15) {
      errors.gstNumber = 'GST Number must not exceed 15 digits';
      isValid = false;
  }

  if (!formData.aadharFrontImage) {
    errors.aadharFrontImage = 'Front side of Aadhar card is required';
    isValid = false;
  }

  if (!formData.aadharBackImage) {
    errors.aadharBackImage = 'Back side of Aadhar card is required';
    isValid = false;
  }

  if (!formData.panFrontImage) {
    errors.panFrontImage = 'Front side of PAN card is required';
    isValid = false;
  }

  if (!isValid) {
    setErrors(errors);
    setbtnstate(false)
    return;
  }
      const data = new FormData();
      
      data.append('customer_id', userData.customer_id);

      data.append('aadharNumber', formData.aadharNumber);
      data.append('panNumber', formData.panNumber);
      data.append('gstNumber', formData.gstNumber);
      data.append('aadharFrontImage', formData.aadharFrontImage);
      data.append('aadharBackImage', formData.aadharBackImage);
      data.append('panFrontImage', formData.panFrontImage);
      data.append('gstImage', formData.gstImage);
 
      for (let [key, value] of data.entries()) {
          console.log(key, value);
        }

      try {
          let full_api = config.apiUrl + `/user/add_customer_proof`;
          axios.post(full_api, data, { headers: config.headers }).then((res) => {
              if(res){
                  Toast.fire({
                      background:"#206bc4",
                      type: 'success',
                      title: res.data.message ,
                      color:"white"
                    });
                    user()
                    setbtnstate(false)
                    // navigate('/customer/bank_details')
                    
               }
               else{
                  Toast.fire({
                      background:"#206bc4",
                      type: 'success',
                      title: "Something Went Wrong" ,
                      color:"white"
                    });
                    setbtnstate(false)
                  }
          })
          // Handle success or redirect user
        } catch (error) {
          console.error('Error:', error);
          setbtnstate(false)

          // Handle error
        }
    }
    // const handleChange =(e)=>{
    //   setState({...state , [e.target.name]:e.target.value})
    // }
    // const handleChange1 =(e)=>{
    //     setOtp({...optstate , [e.target.name]:e.target.value})
    //   }
    // function obscureAadharNumber(aadhar) {
    //     // console.log("aadhar ==" , aadhar)
    //     if (aadhar.length >= 4) {
    //       return '*'.repeat(aadhar.length - 4) + aadhar.slice(-4);
    //     } else {
    //       return aadhar;
    //     }
    //   }
    // const sendOTP =()=>{
    //     // // const obscuredAadhar = obscureAadharNumber(state.aadhar_number);
    //     // // console.log("obscuredAadhar == " , obscuredAadhar)
    //     // setAadharNumber(obscuredAadhar);
    //     let full_api = config.apiUrl + `/user/otp_send_aadhar`;
    //     let sendData = {aadhar_number:state.aadhar_number};
      
    //     axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        
    //       // console.log("rnmmmmm", res);
    //       if(res.data.status==true){
    //         setrefId({...refId , ref_id:res.data.ref_id})
           
    //         Toast.fire({
    //             background: "#10b93b",
    //             type: 'success',
    //             title: res.data.message,
    //             color: "white"
    //         });
           
    //       }
    //       else{
    //         Toast.fire({
    //             background: "#10b93b",
    //             type: 'success',
    //             title: res.data.message,
    //             color: "white"
    //         });
    //       }

    //     }).catch((e) => {
    //         Toast.fire({
    //             background: "#10b93b",
    //             type: 'success',
    //             title: 'Something Went Wrong',
    //             color: "white"
    //         });
    //       // console.log("----error:   ", e);
    //     })
    // }
    // const verifyOTP =()=>{
      
    //     let full_api = config.apiUrl + `/user/verify_otp_aadhar`;
    //     let sendData = {otp:optstate.otp , ref_id:refId.ref_id , aadhar_number:state.aadhar_number};
      
    //     axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        
    //       // console.log("rnmmmmm", res);
    //       if(res.data.status==true){
    //         // setrefId({...refId , red_id:res.data.ref_id})
    //         Toast.fire({
    //             background: "#10b93b",
    //             type: 'success',
    //             title: res.data.message,
    //             color: "white"
    //         });
    //         window.location.reload();
    //       }
    //       else{
    //         Toast.fire({
    //             background: "#10b93b",
    //             type: 'success',
    //             title: res.data.message,
    //             color: "white"
    //         });
    //       }

    //     }).catch((e) => {
    //         Toast.fire({
    //             background: "#10b93b",
    //             type: 'success',
    //             title: 'Something Went Wrong',
    //             color: "white"
    //         });
    //       // console.log("----error:   ", e);
    //     })
    // }
     React.useEffect(()=>{
        user()
     },[])
    const user=()=>{
        let dataToSend = {
    };
   
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/user/find_customer_detail';
    //  console.log("headers =========> ", config.headers);
      axios.post(url, dataToSend, { headers: config.headers })
      .then((res) => {
        console.log("res ===" , res.data.output)
        setUserState(res.data.output)
        setcompanyState(res.data.company)
       
        setFormData({...formData ,aadharNumber:res.data.output.aadhaar_number ,panNumber:res.data.output.pan_number , gstNumber:res.data.output.gst_number , aadharFrontImage:res.data.output.aadharFrontImage , aadharBackImage:res.data.output.aadharBackImage , panFrontImage:res.data.output.panFrontImage , gstImage:res.data.output.gstImage })
        // console.log("response ===" , response)
        //   const obscuredAadhar = obscureAadharNumber(res.data.ouptut.aadhaar_number);
        // console.log("obscuredAadhar == " , obscuredAadhar)
        // setAadharNumber(obscuredAadhar);
      
          })
          .catch((error) => {
              console.log(error);
          });    
   }
  //  const handlegstchange =(e)=>{
  //    setgstState({...gststate , [e.target.name]:e.target.value})
  //  }
//    const verifyGST =()=>{
      
//     let full_api = config.apiUrl + `/user/verify_gst_number`;
//     let sendData = {gst_number : gststate.gst_number};
  
//     axios.post(full_api, sendData, { headers: config.headers }).then(res => {
    
//       // console.log("rnmmmmm", res);
//       if(res.data.status==true){
//         // setrefId({...refId , red_id:res.data.ref_id})
//         Toast.fire({
//             background: "#10b93b",
//             type: 'success',
//             title: res.data.message,
//             color: "white"
//         });
//         window.location.reload();
//       }
//       else{
//         Toast.fire({
//             background: "#10b93b",
//             type: 'success',
//             title: res.data.message,
//             color: "white"
//         });
//       }

//     }).catch((e) => {
//         Toast.fire({
//             background: "#10b93b",
//             type: 'success',
//             title: 'Something Went Wrong',
//             color: "white"
//         });
     
//     })
// }
  return (
    <div>
        <Left_menu/>
        <section className='home'>
            <Header/>
   {/* <section class="mb-3 mt-3 tools">
            <div class="container">
                <div class="row py-3">
                    <div class="col-12 col-md">
                        <h3>KYC Settings</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-3">
                    <h5>KYC Setting</h5>
                    <p>Customer KYC settings</p>
                    </div>
                    <div class="col-12 col-md">
                        <div class="row">
                            {companystate.aadhar_verification==1?
                            <div class="col-12 col-md">
                                <div class="card border-0 shadow-sm">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between">
                                            <div class="p-2">
                                                <h6>Aadhar Card Verification</h6>
                                            </div>
                                            <div class="p-2 ">
                                              {userstate.aadhar_kyc!=undefined && userstate.aadhar_kyc==true? <span class="badge rounded-pill bg-success">Verified</span>
                                              : <span class="badge rounded-pill bg-danger">Pending</span>}
                                                
                                            </div>
                                        </div>
                                        <hr/>
                                        <div class="d-grid">
                                        {userstate.aadhar_kyc!=undefined && userstate.aadhar_kyc==true? "":   <button href="" class="btn btn-primary btn-block"  data-bs-toggle="collapse" data-bs-target="#aadhar_verification">Verify Now</button>}
                                        </div>
                                        <div id="aadhar_verification" class="collapse">
                                            <div class=" mt-3">
                                                <div class="input-group mb-3">
                                                    <input type="number" class="form-control" id="email" placeholder="Enter aadhar number" name="aadhar_number"  autocomplete="new-username" value={state.addhar_number} onChange={(e)=>handleChange(e)} />
                                                    <button class="btn btn-primary" onClick={()=>sendOTP()}>Send OTP</button>
                                                </div>
                                                <small class="mt-0 pt-0">You will get an OTP on registered number, need to enter the value for verfication.</small>
                                            </div>
                                            <div class="mb-3 mt-3">
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control" placeholder="Enter OTP" name="otp" onChange={(e)=>handleChange1(e)} value={optstate.otp}/>
                                                    <button class="btn btn-success" onClick={()=>verifyOTP()}>Verify</button>
                                                </div>
                                              
                                            </div>
                                        </div>
                                        <hr/>
                                      {userstate.aadhaar_number!=undefined && userstate.aadhaar_number!=""?  <h6>Aadhar No: {obscureAadharNumber(userstate.aadhaar_number)}</h6>:""}
                                    </div>
                                </div>
                            </div>:""}

                            {companystate.gst_verification==1?
                            <div class="col-12 col-md">
                                <div class="card border-0 shadow-sm">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between">
                                            <div class="p-2">
                                                <h6>GST Verification</h6>
                                            </div>
                                            <div class="p-2 ">
                                            {userstate.gst_kyc!=undefined && userstate.gst_kyc==true? <span class="badge rounded-pill bg-success">Verified</span>
                                              : <span class="badge rounded-pill bg-danger">Pending</span>}
                                            </div>
                                        </div>
                                        <hr/>
                                        <div class="d-grid">
                                        {userstate.gst_kyc!=undefined && userstate.gst_kyc==true? "":    <button class="btn btn-primary btn-block"  data-bs-toggle="collapse" data-bs-target="#gst_verification" >Verify Now</button>}
                                        </div>
                                        <div id="gst_verification" class="collapse">
                                            <div class=" mt-3">
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control" id="email" placeholder="Enter GST number" name="gst_number"  value={gststate.gst_number} onChange={(e)=>handlegstchange(e)} />
                                                    <button class="btn btn-success" onClick={()=>verifyGST()} >Verify</button>
                                                </div>
                                              
                                            </div>
                                          
                                        </div>
                                        <hr/>
                                        {userstate.gst_number!=undefined && userstate.gst_number!=""?  <h6>GST No: {obscureAadharNumber(userstate.gst_number)}</h6>:""}
                                    </div>
                                </div>
                            </div>
                            :""}
                        </div>
                    </div>
                </div>
            </div>  
        </section> */}
          <section class="py-3">
              <div class="container customer_kyc_bx">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8">
                        <div class="">

                            <div>
                            {userstate.aadhar_kyc==true?
                                <div class="alert alert-success">
                                    <strong>Verified!</strong> Your KYC has been verified already.
                                </div>: <div class="alert alert-warning">
                                    <strong>Pending!</strong> Your details are under review. Our team will update you soon.
                                </div>}
                               
                            </div>
                            <div class="mb-3">
                                <h2>Verify your identity</h2>
                                <h6 class="">Requires a valid government issued ID (Aadhard Card, PAN Card)</h6>
                            </div>
        
                            <div class="card mb-3">
                              <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="email" class="form-label">Aadhar Card Number:</label>
                                        <input type="email" class="form-control" id="email" placeholder="Enter Aadhar Number" required=""  name="aadharNumber"
                        value={formData.aadharNumber}
                        onChange={handleChange}/>
                          {errors.aadharNumber && <div className="text-danger mt-2">{errors.aadharNumber}</div>}
                      </div>
                    
                                    <div class="col-12 col-md-6">
                                        <div class="upload_bx">
                                            <h4>Front side of your document</h4>
                                            <small class="mb-3">Upload Front side of your document</small>
                                            <img src={formData.aadharFrontImage} class="img-fluid img-thumbnail" />
                                            <div class="mt-3">
                                            <input className='form-control'
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => handleFileChange(e, 'aadharFrontImage')}
                          />
                           {errors.aadharFrontImage && <div className="text-danger mt-2">{errors.aadharFrontImage}</div>}
                                </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="upload_bx">
                                            <h4>Back side of your document</h4>
                                            <small class="mb-3">Upload Back side of your document</small>
                                            <img src={formData.aadharBackImage} class="img-fluid img-thumbnail" />
                                            <div class="mt-3">
                                            <input className='form-control'
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => handleFileChange(e, 'aadharBackImage')}
                          />
                           {errors.aadharBackImage && <div className="text-danger mt-2">{errors.aadharBackImage}</div>}
                                </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
        
                            <div class="card mb-3">
                              <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="email" class="form-label">PAN Card Number:</label>
                                        <input type="email" class="form-control" id="email" placeholder="Enter PAN Number" name="panNumber"
                        value={formData.panNumber}
                        onChange={handleChange}/>
                          {errors.panNumber && <div className="text-danger mt-2">{errors.panNumber}</div>}
                        </div>
                      
                                    <div class="col-12 col-md-6">
                                        <div class="upload_bx">
                                            <h4>Front side of your document</h4>
                                            <small class="mb-3">Upload Front side of your document</small>
                                            <img src={formData.panFrontImage} class="img-fluid img-thumbnail" />
                                            <div class="mt-3">
                                            <input className='form-control'
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => handleFileChange(e, 'panFrontImage')}
                          />
                        {errors.panFrontImage && <div className="text-danger mt-2">{errors.panFrontImage}</div>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                            
                            <div class="card">
                              <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="email" class="form-label">GST Number:</label>
                                        <input type="text" class="form-control form-control-lg" placeholder="Enter GST Number"   required
                        name="gstNumber"
                        value={formData.gstNumber}
                        onChange={handleChange}/>
                           {/* {errors.gstNumber && <div className="text-danger mt-2">{errors.gstNumber}</div>} */}
                        </div>
                        <div class="col-12 col-md-6">
                                        <div class="upload_bx">
                                            <h4>Front side of your document</h4>
                                            <small class="mb-3">Upload Front side of your document</small>
                                            <img src={formData.gstImage} class="img-fluid img-thumbnail" />
                                            <div class="mt-3">
                                            <input className='form-control'
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => handleFileChange(e, 'gstImage')}
                          />
                        {errors.gstImage && <div className="text-danger mt-2">{errors.gstImage}</div>}

                                            </div>
                                        </div>
                                    </div>
                    
                 
                                </div>
                              </div>
                            </div>
                        </div> 
{userstate.aadhar_kyc==true?"":
                        <div class="py-3">
                           {btnstate==true?<div className='' style={{marginLeft:"60px"}}><ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={30}/></div>: <a onClick={(e)=>handleSubmit(e)} class="btn btn-primary">Update</a>}
                        </div>
}
                    </div>
                </div>     
              </div>
            </section>
        </section>
        <Footer
                    showProgressBar={showProgressBar}
                    completedOrders={completedOrders}
                    totalOrders={totalOrders}
                    viewLogs={recordId}
                    status ={status}
                     toggleProgressBar={toggleProgressBar} 
                     showorderBar={showorderBar}
                     completedOrdersbulk={completedOrdersbulk}
                     totalOrdersbulk={totalOrdersbulk}
                     viewLogslogid={logidstate}
                     statusbulk ={statusstateorder}
                      toggleProgressBarbulk={toggleProgressBarbulk} 
                  />

    </div>
  )
}

export default Customer_kyc