import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNetworkState } from 'react-use';
const Create_order_nav = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const { value } = props;
    const [activeTab, setActiveTab] = React.useState(1);
    const handleChangeTab = (val) => {
        if (val === 2) {
          navigate('/customer/order_import');
        } else if (val === 0) {
          setActiveTab(1);
          navigate('/customer/create_order_1?value=0');
        } else {
          setActiveTab(val);
          navigate({
            pathname: '/customer/create_order_1',
            search: `value=${val}`,
          });
        }
      };
  
    // Use state to track the active tab
    
  
    React.useEffect(() => {
        // Determine the active tab based on the value prop or the current route location
        if (value !== undefined) {
          setActiveTab(value);
        } else if (location.pathname.includes('/customer/order_import')) {
          setActiveTab(2);
        }
        else {
            setActiveTab(1); // Set the default tab to 1 (Domestic Order)
          }
      }, [value, location.pathname]);
    
      const handleInternational = () => {
        if (activeTab !== 3) {
            setActiveTab(3);
            navigate('/customer/create_order_1?value=1');
          }
      };
  return (
    <div>
      {/* <section class="mb-3 pt-4">
          <div class="container">
            <div class="row mb-3">
              <div class="col-12 col-md-3">
                <div class="d-flex">
                    <div class="">
                        <h3><small><a href=""><i class='bx bx-chevron-left icon'></i></a></small>Orders</h3>
                    </div>
                </div>
              </div>
              <div class="col-12 col-md">
                
              </div>
              <div class="col-12 col-md-4 text-end">
                <a href="#" class="btn btn-warning btn-sm "><i class="fa fa-lightbulb-o" aria-hidden="true"></i> Need help with creating order? </a>
              </div>
            </div> 
            <div>
                <ul class="nav nav-pills">
                    <li class="nav-item">
                      <a    className={activeTab === 1 ? 'nav-link active' : 'nav-link'}
            style={{ cursor: 'pointer' }}
            onClick={() => handleChangeTab(0)}>Domestic Order</a>
                    </li>
                    <li class="nav-item">
                      <a   className={activeTab === 3 ? 'nav-link active' : 'nav-link'}
            style={{ cursor: 'pointer' }}
            onClick={() => handleInternational()} >International Order</a>
                    </li>
                    <li class="nav-item">
                      <a  className={activeTab == 2 ? 'nav-link active' : 'nav-link'}
            style={{ cursor: 'pointer' }}
            onClick={() => handleChangeTab(2)}>Bulk Order</a>
                    </li>
                  </ul>
            </div> 
            <hr/>    
          </div>
        </section> */}
        <section class="mb-3 pt-4">
          <div class="container-fluid">

          <div class="row mb-3">
            <div class="col-12 col-md-4">
                <div class="d-flex">
                  <a href="#" class="btn-back">
                    <i class="bx bx-arrow-back"></i>
                  </a>
                  <h4>Create Order</h4>
                </div>
            </div>
            <div class="col-12 col-md text-end">
            <a href="#" class="btn btn-warning btn-sm "><i class="fa fa-lightbulb-o" aria-hidden="true"></i> Need help with creating order? </a>
            </div>
        </div>


             
            {/* <!-- <div>
                <ul class="nav nav-pills">
                    <li class="nav-item">
                      <a class="nav-link active" href="create_order_1.php">Domestic Order</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="create_order_1.php">International Order</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="order_import.php">Bulk Order</a>
                    </li>
                  </ul>
            </div>
            <hr>      --> */}
          </div>
        </section>
    </div>
  )
}

export default Create_order_nav
