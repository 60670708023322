import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Billing_nav from './Billing_nav'
import {  useNavigate } from 'react-router-dom'

import axios from "axios";
import Swal from 'sweetalert2';

import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import config from "../../config"
import { useNetworkState } from 'react-use';

const Billing_wallet_recharge = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const[state,setState] = React.useState({wallet_balance:"" })
    const[balancestate, setbalanceState ]= React.useState({add_balance:[], total_balance:[] , total_credit:0 , total_debit:0})
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})

   React.useEffect(()=>{
    get_wallet_balance()
    dimension_detail()
   },[])

    const get_wallet_balance=()=>{
     let dataToSend = {customer_id : userData.customer_id};
    
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/user/get_wallet_balance';
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            if(res.data.status==true){
        setbalanceState({add_balance:res.data.added_wallet_transactions, total_balance:res.data.find_customer_wallet , total_credit:res.data.credit_value , total_debit:res.data.debit_value})
     }
    else{
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          Toast.fire({
            background:"rgb(231, 91, 75)",
            type: 'Unsuccess',
            title: "Something Went Wrong !",
            color:"white"
          });
    } })
        
          .catch((error) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'Unsuccess',
                title: "Something Went Wrong !",
                color:"white"
              });
              // console.log(error);
          });    
    
    }

    // const handleChange = (e) => {
    //     // console.log("e ", e.target.name);
    //     // console.log("e checked", e.target.value);
    //    setState({wallet_balance:e.target.value})
    //   }

    //   const addbalance=()=>{
    //      if(state.wallet_balance!=""){
    //     navigate({pathname:`/checkout`},{state:state.wallet_balance})
    //      }
    //      else{
    //         const Toast = Swal.mixin({
    //             toast: true,
    //             position: 'bottom-end',
    //             showConfirmButton: false,
    //             timer: 3000
    //           })
    //           Toast.fire({
    //             background:"rgb(231, 91, 75)",
    //             type: 'Unsuccess',
    //             title: "Please Fill the Amount !",
    //             color:"white"
    //           });

    //      }
    // }
    const dimension_detail = () =>{
        let full_api = config.apiUrl + `/user/get_company_info_api`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
          // console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }
  return (
    <div>
          {/* <?php include 'meta.php';?> */}
    
    {/* <?php include 'left_menu.php';?> */}
<Left_menu/>
    <section class="home ">
        {/* <?php include 'header.php';?>  */}
<Header/>
        

        {/* <?php include 'billing_nav.php';?>    */}
<Billing_nav/>
<section class="mb-3">
          <div class="container">
            <div class="card border-0">
              <div class="card-body">
                <div class="row states stboxbil py-3">
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded  ">
                        {balancestate.total_balance.map((sub)=>( <h5>&#8377;{parseFloat(sub.wallet_balance).toFixed(2)}</h5>))}
                            <small>Total Balance</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;{parseFloat(balancestate.total_credit).toFixed(2)}</h5>
                            <small>Total Credit</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377; -{parseFloat(balancestate.total_debit).toFixed(2)}</h5>
                            <small>Total Debit</small>
                        </div>
                    </div>
                </div>
              </div>
            </div>     
          </div>
        </section>

        <section class="mb-3">
          <div class="container">
            <div class="row">
                <div class="col-12 col-md-4">
                  <ul class="nav nav-tabs">
                    <li class="nav-item">
                      <a class="nav-link " href="/customer/billing_wallet">Wallet Hisotry</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link active" href="/customer/billing_wallet_recharge">Recharge History</a>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-md-4">
                  <div class="input-group mb-3 input-group-sm">
                      <input type="date" class="form-control" placeholder="Search by Order ID"/>
                      <button class="btn btn-primary" type="submit">Update</button>
                  </div>
                </div>
            </div>
            <div class=" table-responsive">
                <table class="table table-bordered table_box billing_table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Transaction ID(PG)</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Mon, Sep 6, 2021</td>
                        <td><a href="">3005667</a></td>
                        <td>1009.00</td>
                        <th><small class=""><span class="badge rounded-pill bg-success">Success</span></small></th>
                        <td>Bank ReferenceNo: pay_JlmCDB4NpZYpNB | Order ID: order_JlmBgW9i6Ra95R | Payment Gateway: RZ</td>
                      </tr>
                      <tr>
                        <td colspan="12" class="text-center">
                          <h1 class="fs-1"><i class="material-icons-outlined">filter_hdr</i></h1>
                          <p>We could not find any data for the applied filters.</p>
                          <small>Please change filters and retry.</small>
                        </td>
                      </tr>
                    </tbody>
                </table>  
            </div>
          </div>
        </section>
        
    </section>


    {/* <?php include 'footer.php';?> */}
    </div>
  )
}

export default Billing_wallet_recharge
