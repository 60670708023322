import React, { useRef } from 'react'
import Header from './Header'
import Left_menu from './Left_menu'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
// import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import download from 'downloadjs'
import Moment from 'react-moment';
import TablePagination from '@mui/material/TablePagination';
// import { DateRangePicker } from 'react-date-range';
import { addDays } from "date-fns";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import * as XLSX from 'xlsx'
import FileSaver from 'file-saver';
import { useDownloadExcel } from 'react-export-table-to-excel';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useNetworkState } from 'react-use';
import UseProgressTracker from './UseProgressTracker'
import Footer from './Footer'
import UseBulkShipmentTracker from './UseBulkShipmentTracker';
const Reports = () => {
  const tableRef = useRef(null);
  const isOnline = useNetworkState();
  console.log("isONline  === ", isOnline)
  let navigate = useNavigate();
  let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail====shipments====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
  const { showProgressBar, completedOrders, totalOrders, recordId, status, toggleProgressBar } = UseProgressTracker();
  const{showorderBar, completedOrdersbulk, totalOrdersbulk, logidstate, statusstateorder, toggleProgressBarbulk}= UseBulkShipmentTracker();
const [date,setdate] = React.useState({start_date:"" , end_date:""})
console.log("date == " , date)
  const [state, setState] = React.useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
      isOpen: false,
    },
  ]);
  // console.log("state === " ,  state)
  var months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  var days = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];

  const xcc =
    state[0].startDate.getFullYear() +
    "-" +
    months[state[0].startDate.getMonth()] +
    "-" +
    days[state[0].startDate.getDate()];
  const ycc =
    state[0].endDate.getFullYear() +
    "-" +
    months[state[0].endDate.getMonth()] +
    "-" +
    days[state[0].endDate.getDate()];
  const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });
  const [modals, setModals] = React.useState({ show: false, invoice_id: "" })
  const [otherStates, setOtherState] = React.useState({ activePage: 1, rowsPerPage: 10, page: 0, total_count: 0, onload: true });

  const [searchfilters, setsearchFilters] = React.useState({ filterStatus: [] })
  // console.log("searchfilters == " , searchfilters)
  const [dimensionState, setdimensionState] = React.useState([])
  // console.log("dimensionState= " , dimensionState)
  const [isLoading, setIsLoading] = React.useState(false);
  const [shipstate, setShipstate] = React.useState([])
  const [shipcount, setShipcount] = React.useState({ count: 0, amount: 0 })

  const [rstate, setRState] = React.useState({
    suggestions: [],
    text: "",
    customer_id: ""
  });
  // console.log("rstate= " , rstate)

  const filter = (e, values) => {
    const name = e.target.name;
    const value = values;
    const isChecked = e.target.checked;

    // Create a new array by spreading the existing filterStatus array
    const updatedFilterStatus = [...searchfilters.filterStatus];

    if (isChecked) {
      updatedFilterStatus.push(value);
    } else {
      // Remove the value from the array if unchecked
      const index = updatedFilterStatus.indexOf(value);
      if (index !== -1) {
        updatedFilterStatus.splice(index, 1);
      }
    }

    // Update the searchfilters state with the updated filterStatus array
    setsearchFilters((prevFilters) => ({
      ...prevFilters,
      filterStatus: updatedFilterStatus,
    }));
  }
  const getData = () => {
    setIsLoading(true)
    let sendData = {
      //   indexValue: index,
      //   limit: perPage,
      from_start_date: date.start_date,
      to_start_date: date.end_date,
      customer_id: userData.customer_id,
      status: searchfilters.filterStatus,

    };
    let full_api = config.apiUrl + '/shipment/shipment_reports';


    // console.log("sendData",sendData)
    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
      // setdimensionState(res.data.output);
      if (res.data.status == true) {
        setIsLoading(false)
        setShipstate(res.data.output)
        setShipcount({ count: res.data.count, amount: res.data.amount })
      }

    }).catch((e) => {

    })
  };
  const transformDataForExcel = () => {
    return shipstate.map(item => {
      console.log("created == ", item.created)
      const formattedDate = item.created
        ? new Date(item.created * 1000).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
        : '';

      const formattedTime = item.created
        ? new Date(item.created * 1000).toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        })
        : '';
      return {
        'Tracking Id': item?.carrier_tracking_number || '',
        'Reference Number': item?.order_number || '',
        'Pickup Warehouse': item?.warehouse_id || item.warehouse_name ||"",
        'Order Mode': item?.channel_name || "Custom-Single",
        'Receiver Name': item?.receiver[0]?.name || '',
        'Address': item?.receiver[0]?.address || '',
        'Mobile Number': item?.receiver[0]?.mobile || '',
        'City': item?.receiver[0]?.city || '',
        'State': item?.receiver[0]?.state || '',
        'Pincode': item?.receiver[0]?.pincode || '',
        'Carrier': item?.carrier_name || '',
        'Mode':  item?.product_type_name || '',
        'Carrier Product': item?.service_provider || '',
        'Current Status': getStatusText(item?.status) || '',
        'Weight (kg)': item?.weight_discrepancies==1?item.discrepencies_weight:item.total_weight || '',
        'L': item?.length || '',
        'B':item?.width || '',
        'H':item?.height || '',
        'Volumetric Weight (kg)': item?.volumetric_weight || '',
        'Chargeable Weight': item?.dead_weight || '',
        'Shipping Fee (₹)': item?.weight_discrepancies ==1? item?.discrepencies_amount:item.total_amount || '',
        'Shipment type': item?.type || '',
        'Payment Mode': item?.payment_mode === 'cod' || item?.payment_mode === 'cash' ? 'COD' : item?.payment_mode || '',
        'COD Amount': item?.cod_amount || '',
        'Date': formattedDate,
        'Time': formattedTime,
      }
    });
  };

  const getStatusText = (status) => {
    // Define your status mappings here
    const statusMappings = {
      0: 'Pending',
      10: 'RTO',
      1: 'Pick Up Awaited',
      2: 'Picked Up',
      3: 'In Transit',
      4: 'Out For Delivery',
      5: 'Delivered',
      6: 'Cancelled',
      7: 'Failed',
      8: 'NDR',
      9: 'Pickup Scheduled',

    };


    return statusMappings[status] || '';
  }
  // const { onDownload } = useDownloadExcel({
  //   data: transformDataForExcel(),
  //   filename: 'Reports Table',
  //   sheet: 'Shipment Reports'
  // });
  const downloadExcel = () => {
    const data = transformDataForExcel();
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Shipment Reports');
    XLSX.writeFile(wb, 'Reports Table.xlsx');
  };

  const handleselectstartdate =(e)=>{
setdate({...date,start_date:e.target.value})
  }
  const handleselectenddate =(e)=>{
    setdate({...date,end_date:e.target.value})
      }
  return (
    <div>
      <Left_menu value={13} />
      <section className='home'>
        <Header />
        <div class="row mt-5">
          <div class="col-12 col-md-2">

          </div>
          <div class="col-12 col-md-2">
            <div class=" btn-toolbar mb-2 mb-md-0" >
              <div class="btn-group">
                <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"><i class="fa fa-filter" aria-hidden="true"></i> &nbsp;
                  Filter
                </button>
                <div class="dropdown-menu">
                  <section class="p-1">
                    <h6>Filter</h6>
                    <hr />
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="check1" name="pickup_awaited" value="something" onChange={(e) => filter(e, 1)} />
                      <label class="form-check-label">Pickup Awaited</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="check1" name="pickup_scheduled" value="something" onChange={(e) => filter(e, 9)} />
                      <label class="form-check-label">Pickup Scheduled</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="check1" name="picked_up" value="something" onChange={(e) => filter(e, 2)} />
                      <label class="form-check-label">Picked Up</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="check1" name="in_transit" value="something" onChange={(e) => filter(e, 3)} />
                      <label class="form-check-label">In Transit</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="check1" name="out_for_delivery" value="something" onChange={(e) => filter(e, 4)} />
                      <label class="form-check-label">Out for Delivery</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="check1" name="delivered" value="something" onChange={(e) => filter(e, 5)} />
                      <label class="form-check-label">Delivered</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="check1" name="cancelled" value="something" onChange={(e) => filter(e, 6)} />
                      <label class="form-check-label">Cancelled</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="check1" name="failed" value="something" onChange={(e) => filter(e, 7)} />
                      <label class="form-check-label">Failed</label>
                    </div>
                  </section>

                </div>
              </div>

            </div>
          </div>
          <div className='col-12 col-md-2'>
            {/* <button
              type="button"
              class="btn btn-block btn-primary btn-icon text-white "
              data-bs-toggle="modal"
              data-bs-target="#bd-example-modal-lg"
            >
              Select Date
            </button> */}
            <label>Start Date</label>
            <input className='form-control' type='date' onChange={(e)=>handleselectstartdate(e)}></input> 
            
            {/* <div
              class="modal modal fade bd-example-modal-lg"
              id="bd-example-modal-lg"
              tabindex="-1"
              role="document"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-body">
                    
                     <DateRangePicker
                                    onChange={(item) =>
                                      setState([item.selection])
                                    }
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={1}
                                    ranges={state}
                                    showPreview={false}
                                    direction="horizontal"
                                  /> 
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className='col-md-2'>
          <label>End Date</label>
            <input className='form-control' type='date'  onChange={(e)=>handleselectenddate(e)}></input> 
          </div>
          <div className='col-md-2'>
            <button className='btn btn-primary' onClick={(e) => getData()}>Get Report</button>
          </div>
          <div className='col-md-2'>
            <button className='btn btn-primary' onClick={downloadExcel} > Export excel </button>
          </div>
        </div>
        <div className='mt-5 me-5 ms-5'>
          {(isLoading) ?
            <div style={{ backgroundColor: "#f1f5f9", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.4" }}>
              <div style={{ left: "47%", top: "45%", position: "absolute" }}>
                <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
              </div>
            </div> :

            <div class="card card-body border-0 shadow table-wrapper table-responsive mt-3">
              <table class="table table_box" ref={tableRef}>
                <thead>
                  <tr class="brd">
                    <th>Shipment Details</th>
                    <th>Customer details</th>
                    <th>Package Details</th>
                    <th>Shipping Fee</th>
                    {/* <th>Pickup Address</th> */}
                    <th>Shipping Carrier</th>
                    <th style={{ width: " 4%" }}>Status</th>
                  </tr>
                </thead>
                {shipstate.map((item) =>
                  <tbody>
                    <tr class="brd">
                      <td>
                        <h2>
                          {item.carrier_tracking_number != undefined && item.carrier_tracking_number != "" ? item.carrier_tracking_number : ""}
                        </h2>
                        <small>
                          <a href={`/customer/shipment_detail/${item.shipment_id}`} style={{ textDecoration: "none" }} >{item.order_number}</a><br />
                        </small>
                        {/* <h5><a href="" class="text-muted">982838383</a></h5> */}
                        <small><Moment format="MMM DD YYYY">
                          {new Date(item.created * 1000)}
                        </Moment> - <Moment format="hh:mm:ss a">
                            {new Date(item.created * 1000)}
                          </Moment></small>
                      </td>
                      {item.receiver.map((sub) => (
                        <td>
                          <h4>{sub.name}</h4>
                          <small>{sub.email}</small>
                          <h6>{sub.mobile}</h6>
                        </td>
                      ))}
                      <td>
                        <h4>Dead wt. : {item.weight_discrepancies==1?item.discrepencies_weight:item.total_weight} kg</h4>
                        <small>{item.height} x {item.width} x {item.length} (cm)</small>
                        <h6>Volumetric wt.:  {item.volumetric_weight} Kg</h6>
                      </td>
                      <td>
                        <h4> ₹{item.weight_discrepancies==1?item.discrepencies_amount:item.total_amount}</h4>
                        <small>{item.payment_mode == "cod" || item.payment_mode == "cash" ? <span class="badge rounded-pill bg-info fw-400">cod</span> : <span class="badge rounded-pill bg-warning fw-400">{item.payment_mode}</span>}</small>
                      </td>
                      <td>
                        <h6 class="text-bold"><strong>{item.carrier_name}</strong></h6>
                        <small>{item.product_type_name}</small>
                      </td>
                      <td>
                        <h2>{item.status == 0 ? <span class="badge rounded-pill bg-warning fw-400">Pending</span> : item.status == 10 ? <span class="badge rounded-pill bg-danger fw-400">RTO</span> : item.status == 1 ? <span class="badge rounded-pill bg-success fw-400">Pick Up Awaited</span> : item.status == 2 ? <span class="badge rounded-pill bg-warning fw-400">Picked Up</span> : item.status == 3 ? <span class="badge rounded-pill bg-danger fw-400">In Transit</span> : item.status == 4 ? <span class="badge rounded-pill bg-primary fw-400">Out For Delivery</span> : item.status == 5 ? <span class="badge rounded-pill bg-success fw-400">Delivered</span> : item.status == 6 ? <span class="badge rounded-pill bg-dark fw-400">Cancelled</span> : item.status == 7 ? <span class="badge rounded-pill bg-secondary fw-400">Failed</span> : item.status == 8 ? <span class="badge rounded-pill bg-warning fw-400">NDR</span> : item.status == 9 ? <><span class="badge rounded-pill bg-danger fw-400">Pickup Scheduled</span><br /><br />
                          {item.pickup_date != undefined && item.pickup_date != "" ? <small>on {item.pickup_date != undefined ? item.pickup_date : ""} at {item.pickup_time != undefined ? item.pickup_time : ""}</small> : ""} </> : <span class="badge rounded-pill bg-danger fw-400">Not Available</span>}</h2>
                      </td>
                    </tr>
                  </tbody>
                )}
                <tr className='mt-5'>
                  <th className='mt-5 '>Total Shipments ({shipcount.count})</th>
                  <th className='mt-5'></th>
                  <th className='mt-5'></th>

                  <th className='mt-5'> ₹{shipcount.amount}</th>
                  <th className='mt-5'></th>
                  <th className='mt-5'></th>
                </tr>
              </table>
            </div>

          }
        </div>

      </section>
      <Footer
                    showProgressBar={showProgressBar}
                    completedOrders={completedOrders}
                    totalOrders={totalOrders}
                    viewLogs={recordId}
                    status ={status}
                     toggleProgressBar={toggleProgressBar} 
                     showorderBar={showorderBar}
                     completedOrdersbulk={completedOrdersbulk}
                     totalOrdersbulk={totalOrdersbulk}
                     viewLogslogid={logidstate}
                     statusbulk ={statusstateorder}
                      toggleProgressBarbulk={toggleProgressBarbulk} 
                  />  
    </div>
  )
}

export default Reports
