import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar, ModalHeader, ModalTitle } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import moment from 'react-moment';
import download from 'downloadjs'
import TablePagination from '@mui/material/TablePagination';
import { useNetworkState } from 'react-use';
import UseProgressTracker from './UseProgressTracker'
import Footer from './Footer'
import UseBulkShipmentTracker from './UseBulkShipmentTracker';
const Bank_details = () => {
    let navigate = useNavigate()
  const { showProgressBar, completedOrders, totalOrders, recordId, status, toggleProgressBar } = UseProgressTracker();
  const{showorderBar, completedOrdersbulk, totalOrdersbulk, logidstate, statusstateorder, toggleProgressBarbulk}= UseBulkShipmentTracker();
    let userDetail = localStorage.getItem('ship_rocket_user');
    console.log("userDetail====", userDetail);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const [formData, setFormData] = React.useState({
        accountname: '',
        accountNumber: '',
        accountifsc: '',
        cancelchequeimage: null,
      
      });
      const [errors, setErrors] = React.useState({});
      const [btnstate, setbtnstate] = React.useState(false);
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({})
      };
    
      const handleFileChange = (e, type) => {
        setFormData({ ...formData, [type]: e.target.files[0] });
        setErrors({})
      };
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    });
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setbtnstate(true)
    let errors = {};
    let isValid = true;

    if (!formData.accountname) {
      errors.accountname = 'Account Name is required';
      isValid = false;
    }

    if (!formData.accountNumber) {
      errors.accountNumber = 'Account Number is required';
      isValid = false;
    }

    if (!formData.accountifsc) {
      errors.accountifsc = 'IFSC Code is required';
      isValid = false;
    }

    if (!formData.cancelchequeimage) {
      errors.cancelchequeimage = 'Cancel Cheque Image is required';
      isValid = false;
    }

    

    if (!isValid) {
      setErrors(errors);
      setbtnstate(false)

      return;
    }
        const data = new FormData();
        
        data.append('customer_id', userData.customer_id);

        data.append('accountname', formData.accountname);
        data.append('accountNumber', formData.accountNumber);
        data.append('accountifsc', formData.accountifsc);
        data.append('cancelchequeimage', formData.cancelchequeimage);
       
        for (let [key, value] of data.entries()) {
            console.log(key, value);
          }

        try {
            let full_api = config.apiUrl + `/user/add_customer_bank_details`;
            axios.post(full_api, data, { headers: config.headers }).then((res) => {
                if(res){
                    Toast.fire({
                        background:"#206bc4",
                        type: 'success',
                        title: res.data.message ,
                        color:"white"
                      });
                      navigate('/customer/dashboard')
      setbtnstate(false)

                 }
                 else{
                    Toast.fire({
                        background:"#206bc4",
                        type: 'success',
                        title: "Something Went Wrong" ,
                        color:"white"
                      });
      setbtnstate(false)
                      
                    }
            })
            // Handle success or redirect user
          } catch (error) {
            console.error('Error:', error);
      setbtnstate(false)

            // Handle error
          }
      }
  return (
    <div>
      <section class=" register_box_00">
    <nav class="navbar navbar-expand-sm pt-4">   
        <div class="container">
            <a class="navbar-brand" href="javascript:void(0)">
                <img src="/img/logo_5.png" class="img-fluid" />
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="mynavbar">
            <ul class="navbar-nav me-auto">
                
            </ul>
            <div class="d-flex">
                
            </div>
            </div>
        </div>
    </nav>



    <div class="row g-0 justify-content-center signup_box_oo">
        <div class="col-12 col-md-5 ">

            <div class="card my-4 document_upload_bx">
              <div class="card-body">
                <div class="text-center mb-5">
                    <h2>add Your Bank details</h2>
                    <p>Requires a valid Bank details to tranfer the payments into your account</p>
                </div>  
                
                <div>
                    <h5>Bank Details</h5>
                    <div class="mb-3">
                        <div class="input-group">
                            <span class="input-group-text"><i class="material-icons-outlined">portrait</i></span>
                            <input type="text" class="form-control form-control-lg" placeholder="Enter Account Holder Name" required=""  name="accountname"
                        value={formData.accountname}
                        onChange={handleChange}/>
                        </div>
                        {errors.accountname && <div className="text-danger mt-2">{errors.accountname}</div>}
                    </div>
                    <div class="mb-3">
                        <div class="input-group">
                            <span class="input-group-text"><i class="material-icons-outlined">local_atm</i></span>
                            <input type="text" class="form-control form-control-lg" placeholder="Enter Account Number " required=""  name="accountNumber"
                        value={formData.accountNumber}
                        onChange={handleChange}/>
                        </div>
                        {errors.accountNumber && <div className="text-danger mt-2">{errors.accountNumber}</div>}
                    </div>
                    <div class="mb-3">
                        <div class="input-group">
                            <span class="input-group-text"><i class="material-icons-outlined">account_balance</i></span>
                            <input type="text" class="form-control form-control-lg" placeholder="Enter Branch IFSC CODE" required="" name="accountifsc"
                        value={formData.accountifsc}
                        onChange={handleChange}/>
                        </div>
                        {errors.accountifsc && <div className="text-danger mt-2">{errors.accountifsc}</div>}
                    </div>
                    
                    <div class="row mb-4">
                        <div class="col-12 col-md text-center ">
                            <div class="upload_bx">
                                <h4>Upload Cancel Cheque</h4>
                                <small class="mb-3">Upload Bank cacnel cheque image</small>
                                <div class="mt-3">
                                    <input
                                className='form-control'
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => handleFileChange(e, 'cancelchequeimage')}/>
                             {errors.cancelchequeimage && <div className="text-danger mt-2">{errors.cancelchequeimage}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div class="ps-3 text-start">
                    <h6>Note:</h6>
                    <ul class="text-muted">
                        <li>File accepted: JPEG/JPG/PNG (Max size: 1MB)</li>
                        <li>Image must be clear visible</li>
                        <li>Document should be in good condition</li>
                        <li>Document must be valid</li>
                    </ul>
                </div>
                <div class="row justify-content-between mt-5">
                    <div class="col-12 col-md-8">
                        <div class="d-grid">
                        {btnstate==true?<div className='' style={{marginLeft:"60px"}}><ReactLoading type={"spin"} color={"black"} height={40} width={30}/></div>:   <a onClick={(e)=>handleSubmit(e)} class="btn btn-primary btn-block">Upload Documents</a>}
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="d-grid">
                            <a href="/customer/dashboard" class="btn btn-light border btn-block">I'll do later!</a>
                        </div>
                    </div>
                </div>
              </div>
            </div>

        </div>
    </div>


 
</section>
<Footer
                    showProgressBar={showProgressBar}
                    completedOrders={completedOrders}
                    totalOrders={totalOrders}
                    viewLogs={recordId}
                    status ={status}
                     toggleProgressBar={toggleProgressBar} 
                     showorderBar={showorderBar}
                     completedOrdersbulk={completedOrdersbulk}
                     totalOrdersbulk={totalOrdersbulk}
                     viewLogslogid={logidstate}
                     statusbulk ={statusstateorder}
                      toggleProgressBarbulk={toggleProgressBarbulk} 
                  />
    </div>
  )
}

export default Bank_details
