import React from 'react'

const Login_footer = () => {
  return (
    <div>
      <ul>
    <li><a href="https://shiport.in/terms-condition"  target="_blank" >Terms & Condition</a></li>
    <li><a href="https://shiport.in/privacy-policy" target="_blank">Privacy Policy</a></li>
    <li><a href="https://shiport.in/cancellation-policy" target="_blank">Cancellation/Refund Policy</a></li>
    <li><a href="https://shiport.in/contact-us" target="_blank">Support</a></li>

</ul>
    </div>
  )
}

export default Login_footer