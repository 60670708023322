import React, { useState, createRef, useEffect } from "react";
import Left_menu from './Left_menu'
import Header from './Header'
import axios from "axios";
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import queryString from 'query-string';
import { useNetworkState } from 'react-use';
import UseProgressTracker from './UseProgressTracker'
import Footer from './Footer'
import UseBulkShipmentTracker from "./UseBulkShipmentTracker";
import { parse } from "papaparse";
const Customer_b2b_package = () => {
    let navigate = useNavigate();
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
  const[loadingstate,setLoadingstate] = React.useState({loading:false})
  const[errorstate,seterrorstate] = React.useState({message:""})
  const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
  const [otherState, setOtherState] = useState({ submit_button: false });
  const location = useLocation();
  const { showProgressBar, completedOrders, totalOrders, recordId, status, toggleProgressBar } = UseProgressTracker();
  const{showorderBar, completedOrdersbulk, totalOrdersbulk, logidstate, statusstateorder, toggleProgressBarbulk}= UseBulkShipmentTracker();
  let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
  
  //  console.log("location==>",location)
  let  parsed = queryString.parse(location.search);
// console.log("parsed",parsed);
const boxes = JSON.parse(decodeURIComponent(parsed.boxes));
const invoices = JSON.parse(decodeURIComponent(parsed.invoices));
console.log("boxes == " , boxes)
console.log("invoices == " , invoices)

const loc = window.location.search;
const urlParams = new URLSearchParams(loc);

const senderMobile = urlParams.get('sender_mobile');

// Remove the '+' and '91' prefixes from the mobile number
const cleanedSenderMobile = senderMobile.replace(/\D/g, '');
let formattedSenderMobile ;
if (cleanedSenderMobile.length > 10 && cleanedSenderMobile.startsWith('91')) {
  formattedSenderMobile = cleanedSenderMobile.substring(2);
} else {
  formattedSenderMobile = cleanedSenderMobile;
}

// cleanedSenderMobile.startsWith('91')
//   ? cleanedSenderMobile.substring(2)
//   : cleanedSenderMobile;

// // console.log("formattedSenderMobile === ", formattedSenderMobile);

const locreceiver = window.location.search;
const urlParamsreceiver = new URLSearchParams(locreceiver);

const receiverMobile = urlParamsreceiver.get('receiver_mobile');

// Remove the '+' and '91' prefixes from the mobile number
const cleanedReceiverMobile = receiverMobile.replace(/\D/g, '');
// const formattedReceiverMobile = cleanedReceiverMobile.startsWith('91')
//   ? cleanedReceiverMobile.substring(2)
//   : cleanedReceiverMobile;
  let formattedReceiverMobile;

if (cleanedReceiverMobile.length > 10 && cleanedReceiverMobile.startsWith('91')) {
    formattedReceiverMobile = cleanedReceiverMobile.substring(2);
} else {
    formattedReceiverMobile = cleanedReceiverMobile;
}

// console.log("formattedReceiverMobile === ", formattedReceiverMobile);

const locreturn = window.location.search;
const urlParamsreturn = new URLSearchParams(locreturn);

const returnMobile = urlParamsreturn.get('return_mobile');

// Remove the '+' and '91' prefixes from the mobile number
const cleanedReturnMobile = returnMobile.replace(/\D/g, '');
let formattedReturnMobile ;
if (cleanedReturnMobile.length > 10 && cleanedReturnMobile.startsWith('91')) {
  formattedReturnMobile = cleanedReturnMobile.substring(2);
} else {
  formattedReturnMobile = cleanedReturnMobile;
}
//  cleanedReturnMobile.startsWith('91')
//   ? cleanedReturnMobile.substring(2)
//   : cleanedReturnMobile;

// console.log("formattedReturnMobile === ", formattedReturnMobile);


// let dimensions = []
// if(Array.isArray(parsed.dimensions)){
//    dimensions = parsed.dimensions
//    dimensions = dimensions.map((sub)=>{
    
//     console.log("dimensions------>",
//     Object.assign({},sub.split(',') ))
//     return  Object.assign({},sub.split(',') )
    
//    })
//   //  console.log("arr",arr)
// }else{
//   dimensions.push(parsed.dimensions)
//   dimensions = dimensions.map((sub)=>{
    
//     console.log("dimensions------>",
//     Object.assign({},sub.split(',') ))
//     return  Object.assign({},sub.split(',') )
    
//    })
// }
// console.log("dimensions",dimensions)
// let total_weight = 0
// dimensions.map((sub)=>{
//   total_weight = Number(total_weight)+ Number(sub[3])
// })
// console.log("total_weight",total_weight)


// console.log("total_weight",total_weight)

  const [state, setState] = useState([]);
  let apiFlag = true;

  useEffect(() => {
    if(apiFlag){
      apiFlag = false;
    get_list()  
    dimension_detail()    
    }
}, [])

const dimension_detail = () =>{
  let full_api = config.apiUrl + `/user/get_company_info_api`;
  let sendData = {};

  axios.post(full_api, sendData, { headers: config.headers }).then(res => {
    setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
    // console.log("rnmmmmm", res);
  }).catch((e) => {
    // toast.configure()
     //toast.error("Some thing went wrong")
    // console.log("----error:   ", e);
  })
}

  const get_list = async (  sorting) => {
    setLoadingstate({...loadingstate,loading:true})
    
    // let sort = "default";
    // if(sortValue) {
    //   sort = sortValue
    // }
    let dataToSend = {customer_id:userData.customer_id,from_postal_code:parsed.sender_postal_code , from_country_code:parsed.sender_country_code , from_city_id:parsed.sender_city_id, to_postal_code:parsed.receiver_postal_code,to_country_code:parsed.receiver_country_code , weight:parsed.weight,length:parsed.length,height:parsed.height,width:parsed.width , parcel_type:parsed.shipment_type , mode:parsed.mode , payment_mode:parsed.payment_mode , order_amount:parsed.cod_amount , carrier_type:parsed.carrier_type
    };
    
  
    // console.log("datatoSend",dataToSend)
    let url = config.apiUrl + '/shipment/b2b_shipment_rate_time';
   
   // console.log("headers => ", config.headers);
   
    
    axios.post(url, dataToSend, { headers: config.headers })
        .then((responseJson) => {
          // console.log("responseJson => ", responseJson.data);
          setLoadingstate({...loadingstate,loading:false})
          if(responseJson.data.status=="success"){
            let sortedPackages = responseJson.data.output;

              
            // Sort the packages based on total charges
            if (sorting === 'Cheapest') {
              sortedPackages.sort((a, b) => a.total_charges - b.total_charges);
            }
            else if(sorting === 'Highest'){
              sortedPackages.sort((a, b) => b.total_charges - a.total_charges);
            }
    console.log("sortedPackages = " , sortedPackages)
    setState(sortedPackages);
           
            if(responseJson.data.output.length==0){
              seterrorstate({...errorstate,message:"No services available for the selected route and package combination."})
            }
            
          }

          else{
          
          //  seterrorstate({...errorstate,message:"No services available for the selected route and package combination."})
          //  console.log("message",responseJson.data.message);
           Swal.fire({
            icon: 'error',
            // title: 'Oops...',
            text: 'Something Went wrong',
            
          })
          }
           
        })
        .catch((error) => {
            //Hide Loader
            setLoadingstate({...loadingstate,loading:false})
            // console.log(error);
        });
};

// const sort_by = (e) =>{
// // console.log(e.target.value);
// get_list(e.target.value)
// }


const submit_detail = async (type,item) => {
  setOtherState({ ...otherState, submit_button: true })
    // console.log("type",type)
    // console.log("courier_id",item)
    
      let dataToSend = { sender_name: parsed.sender_name,
                        sender_mobile: formattedSenderMobile,
                        sender_email: parsed.sender_email,
                        sender_address: parsed.sender_address,
                        sender_city: parsed.sender_city,
                        sender_city_id: parsed.sender_city_id,
                        sender_country_name: parsed.sender_country,
                        sender_country_code: parsed.sender_country_code,
                        sender_postal_code: parsed.sender_postal_code,
                        company_name: item.service_name,
                        receiver_name: parsed.receiver_name,
                        receiver_mobile: formattedReceiverMobile,
                        receiver_email: parsed.receiver_email,
                        receiver_address: parsed.receiver_address,
                        receiver_city: parsed.receiver_city,
                        receiver_country_name: parsed.receiver_country,
                        receiver_country_code: parsed.receiver_country_code,
                        receiver_postal_code: parsed.receiver_postal_code,
                        receiver_state_id:parsed.receiver_state_id,
                        receiver_city_id:parsed.receiver_city_id,

                        sender_state_name:parsed.sender_state_name,
                        receiver_state_name:parsed.receiver_state_name,
                        return_name: parsed.return_name,
                        return_mobile: formattedReturnMobile,
                        return_email: parsed.return_email,
                        return_address: parsed.return_address,
                        return_city: parsed.return_city,
                        return_state_name: parsed.return_state_name,
                        return_country_name: parsed.return_country,
                        return_country_code: parsed.return_country_code,
                        return_postal_code: parsed.return_postal_code,
                        courier_id : item.courier_id?item.courier_id:"",
                        product_id: item.service_provider_id ? item.service_provider_id:"",
                        discount:0,
                        payment_mode:parsed.payment_mode,
                        shipment_type:parsed.shipment_type,
                        cod_amount : parsed.cod_amount,
                        weight: parsed.weight, length: parsed.length, height: parsed.height,
                        width: parsed.width, item_name:"",item_value:"",
                        product_type_name:item.product_type_name?item.product_type_name:"",
                        total_amount: Number(item.total_charges)+Number(item.commission_charge),
                        tax_amount: 0,
                        rate_price : item.total_charges,
                        service_name: item.service_provider?item.service_provider:"",
                        carrier_id: item.carrier_id,
                        insurance_amount: parsed.insurance,
                        type: parsed.shipment_type,
                        carrier_id: item.carrier_id ,
                        address_id : parsed.sender_address_id,
                        order_id:parsed.order_id,
                        order_date:parsed.order_date,
                        mode:parsed.mode,
                        store_id:parsed.store_id,
                        channel_name:parsed.channel_name,
                        created_by : "Customer",
                        volumetric_weight:parsed.volumetric_weight,
                        channel_id:parsed.channel_id?parsed.channel_id:"",
                         total_weight: parsed.weight ,
                         payment_status : 1,
                         zone_name:item.zone_name?item.zone_name:"",
                         payment_type: type!=undefined ? type :0
                         , carrier_type:2 ,  
                        boxes: boxes , invoices:invoices , rv_insurance:parsed.rv_insurance }
                          
       
                         
                         
      
      dataToSend.cust_id = userData.customer_id;
      // console.log("datatoSend", dataToSend)

      let url = 
       config.apiUrl + '/shipment/new_shipment_create';
      
     
      axios.post(url, dataToSend, { headers: config.headers })
        .then((responseJson) => {
          // console.log("responseJson => ", responseJson.data.output);
          // setOtherState({ ...otherState, submit_button: false })
          if (responseJson.data.status == "success") {
          setTimeout(()=>{
            setOtherState({ ...otherState, submit_button: false })
                   navigate(`/customer/shipments`, { replace: true, state: [] })
          },2000)
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
            Toast.fire({
            background:"rgb(25, 135, 84)",
            type: 'unsuccess',
            title: "Shipment created successfully.",
            color:"white"
          });

          }
          else {
            setOtherState({ ...otherState, submit_button: false })
            Swal.fire({
              icon: 'error',
            
              text: responseJson.data.message,

            })
            
          }
        
        }).catch((error) => {
          setOtherState({ ...otherState, submit_button: false })
          // console.error(error);
        });
      
      
    }
    const handleSortChange = (value) => {
     
    
        get_list( value); // Use the updated 'sub' value along with sorting option
    
     
    };
  return (
    <div>
          {/* <?php include 'meta.php';?> */}
    
    {/* <?php include 'left_menu.php';?> */}
<Left_menu/>
    <section class="home ">
        {/* <?php include 'header.php';?>  */}
<Header/>
        
    <div class="container pt-3">
        <div class="row">
            <div class="col-12 col-md-12">
                <div class="row">
                    <div class="col-12 col-md">
                        <h4>Select Courier Partner</h4>
                    </div>
                    <div class="col-12 col-md-4 text-end">
                       <small> Pickup From
                        <strong>{parsed.sender_postal_code}</strong> | 
                        Deliver To
                        <strong>{parsed.receiver_postal_code}</strong></small>
                    </div>
                </div>
                <div class="row pt-3 mb-3">
                    <div class="col-12 col-md">
                        {/* <ul class="nav nav-pills">
                            <li class="nav-item">
                            <a class="nav-link active" href="#">All</a>
                            </li>
                            <li class="nav-item">
                            <a class="nav-link" href="#">Air</a>
                            </li>
                        </ul> */}
                    </div>
                    <div class="col-12 col-md-4">
                    <select class="form-select form-select-sm" onChange={(e) => handleSortChange(e.target.value)}>
                        <option value="Recommended">Sort By: Recommended</option>
                        <option value="Cheapest">Sort By: Low to High</option>
                        <option value="Highest">Sort By: High to Low</option>
                        </select>
                    </div>
                </div>
                <section class="package_box">
                {loadingstate.loading?
          <div style={{width:"100%", display: "flex",
  justifyContent: "center"}}>

    
        <ReactLoading type={"spin"} color={"#0d6efd"} height={300} width={89} />
        </div>
        :""}
         <h3>
            {errorstate.message}</h3>
                    <div class="card mb-3">
                    {(state.length != 0) ?
          state.map((item,index)=>
                      <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-md">
                                <div class="d-flex">
                                    <div class="p-2">
                                        <figure>
                                            <img src={`https://cdn.shiport.in/images/${item.carrierImage}`} class="img-fluid" />
                                        </figure>
                                    </div>
                                    <div class="ps-2 ">
                                        <h4>{item.service_provider}</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 text-end">
                                <h6>{item.product_type_name?item.product_type_name:""} 
                                {/* | Min-weight: 0.5 kg */}
                                </h6>
                            </div>
                        </div>
                       
                        <div class="row">
                            <div class="col-12 col-md-2">
                                <div class="card rating_card">
                                  <div class="card-body">
                                    <h5>Rating 3.5</h5>
                                    <div class="text-warning">
                                        <i class="material-icons-outlined">star</i>
                                        <i class="material-icons-outlined">star</i>
                                        <i class="material-icons-outlined">star</i>
                                        <i class="material-icons-outlined">star_half</i>
                                        <i class="material-icons-outlined">star_border</i>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="col-12 col-md pt-3">
                                <h5>Expected Pickup</h5>
                                <small>-----</small>
                            </div>
                            <div class="col-12 col-md pt-3">
                                <h5>Estimated Delivery</h5>
                                <small>----</small>
                            </div>
                            <div class="col-12 col-md pt-3">
                                <h5>Weight</h5>
                                <small>-----</small>
                            </div>
                            <div class="col-12 col-md pt-3">
                                <h3>
                                  {dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}
                                 {parseFloat(Number(item.total_charges)+Number(item.commission_charge)).toFixed(2)}</h3>
                            </div>
                            {/* {(otherState.submit_button) ? */}
                            {(otherState.submit_button==false) ?
                            <div class="col-12 col-md pt-2">
                                <a onClick={()=>submit_detail(1,item )} class="btn btn-primary">Ship Now</a>
                            </div>
                               
                            :    ""} 
                               
                            {/* :  <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />} */}
                        </div>
                        <div className="mt-3">
                        {/* <hr/> */}
                        </div>
                      </div>
                      
                      ):""}
                    </div>
                 
                    
                  
                </section>
            </div>
        </div>
    </div>
        
        
    </section>
    <Footer
                    showProgressBar={showProgressBar}
                    completedOrders={completedOrders}
                    totalOrders={totalOrders}
                    viewLogs={recordId}
                    status ={status}
                     toggleProgressBar={toggleProgressBar} 
                     showorderBar={showorderBar}
                     completedOrdersbulk={completedOrdersbulk}
                     totalOrdersbulk={totalOrdersbulk}
                     viewLogslogid={logidstate}
                     statusbulk ={statusstateorder}
                      toggleProgressBarbulk={toggleProgressBarbulk} 
                  />

    {/* <?php include 'footer.php';?> */}
    </div>
  )
}

export default Customer_b2b_package
