import React, { useState, createRef, useEffect } from "react";
import Left_menu from './Left_menu'
import Header from './Header'
import axios from "axios";
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import queryString from 'query-string';
import { useNetworkState } from 'react-use';
import UseProgressTracker from './UseProgressTracker'
import Footer from './Footer'
import UseBulkShipmentTracker from "./UseBulkShipmentTracker";

const Tickets = () => {
   const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====shipments====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const{showorderBar, completedOrdersbulk, totalOrdersbulk, logidstate, statusstateorder, toggleProgressBarbulk}= UseBulkShipmentTracker();
    const { showProgressBar, completedOrders, totalOrders, recordId, status, toggleProgressBar } = UseProgressTracker();

    const[state,setState]=React.useState({ticket_detail:[] , isLoading:true})
    const [shipmentstate, setshipStatus] = useState({ status: null });



    const axios_get_api = (status) => {
 
        let full_api = config.apiUrl + `/home/ticket_list`;
        let sendData = {
          dstatus: 1,
          ticket_createdById:userData.customer_id,
          status:status
        }
    
          
    
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          // console.log("-----res-----  ",res);
         setState({...state,ticket_detail:res.data.output , isLoading:false})
          // console.log("response", res);
        
    
        }).catch((e) => {
    
          // toast.configure()
          // toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }
      React.useEffect(() => {
        axios_get_api()
      }, [])
      const shipmentFilter = (status) => {
        // console.log("action ", status);
        setshipStatus({ ...shipmentstate, status: status })
        axios_get_api(status)
      }
    
  return (
    <div>
 <Left_menu value={12}/>
 <section class="home ">
 <Header/>
{state.isLoading==true?<ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%'}} />:

<section class="bg-light p-5">
  <div class="container ">
       
    <section class="bg-white p-5 mb-2">
      <div class="row">
        <div className="col-8">
        <h2>Tickets</h2>
        </div>
        <div className=" col-4 text-end">
        <a className="btn btn-primary" href="/customer/create_ticket">Create Ticket</a>
      </div>
      </div>

 
      <section>
        <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class={(shipmentstate.status == null) ? 'nav-link active' : 'nav-link'}  onClick={() => shipmentFilter(null)} style={{cursor:"pointer"}} >All</a>
            </li>
            <li class="nav-item">
              <a class={(shipmentstate.status == 0) ? 'nav-link active' : 'nav-link'} onClick={() => shipmentFilter(0)} style={{cursor:"pointer"}}>Pending</a>
            </li>
            <li class="nav-item">
              <a class={(shipmentstate.status == 1) ? 'nav-link active' : 'nav-link'} onClick={() => shipmentFilter(1)} style={{cursor:"pointer"}}>Answered</a>
            </li>
            <li class="nav-item">
              <a class={(shipmentstate.status == 2) ? 'nav-link active' : 'nav-link'} onClick={() => shipmentFilter(2)} style={{cursor:"pointer"}}>Closed</a>
            </li>
          </ul>
      </section>
      
      <div class="row pt-1">
        <div class="col-md-12">
        <div className="card card-body border-0 shadow table-wrapper table-responsive">
            <table class="table table-hover">

                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Ticket ID</th>
                    <th>Subject</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {state.ticket_detail.length>0?
                state.ticket_detail.map((sub,index)=>(
                  <tr>
                    <td>{sub.time} / {sub.date} </td>
                    <td><a href={"/customer/ticket_details/" + sub.ticket_id} className="text-decoration-none">{sub.ticket_id}</a></td>
                    <td>{sub.details}</td>
                    <td><span class={sub.status==0?"badge bg-warning":sub.status==1?"badge bg-success":"badge bg-danger"}>{sub.status==0?"Pending": sub.status==1?"Answered":"Closed" }</span></td>
                  </tr>
                )): <section className="text-center p-5 h-100" style={{marginLeft:"80px"}}>
                <h4 className="text-muted fw-bold">NO TICKETS</h4>
                <p className="pb-5">You do not currently have any tickets in this status. Find what you're looking for in another tab or...</p>
                <a href="/customer/create_ticket"><a className="btn btn-outline-primary btn-lg">Start creating a new ticket</a></a>
              </section>}
                </tbody>
              </table>
              </div>
     
        </div>
        
      </div>
    
    </section>

    
  </div>
</section>

}
</section>
<Footer
                    showProgressBar={showProgressBar}
                    completedOrders={completedOrders}
                    totalOrders={totalOrders}
                    viewLogs={recordId}
                    status ={status}
                     toggleProgressBar={toggleProgressBar} 
                     showorderBar={showorderBar}
                     completedOrdersbulk={completedOrdersbulk}
                     totalOrdersbulk={totalOrdersbulk}
                     viewLogslogid={logidstate}
                     statusbulk ={statusstateorder}
                      toggleProgressBarbulk={toggleProgressBarbulk} 
                  />  
    
    </div>
  )
}
 

export default Tickets
