import { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';

const UseBulkShipmentTracker = () => {
    const [showorderBar, setShoworderBar] = useState(false);
    const [statusstateorder, setstatusorder] = useState("");
    
    const [completedOrdersbulk, setCompletedOrdersbulk] = useState(0);
   
    const [totalOrdersbulk, setTotalOrdersbulk] = useState(0);
    const [logidstate, setlogId] = useState(null);
  let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }

    useEffect(() => {
        // Retrieve state from localStorage on page load
        const storedState = JSON.parse(localStorage.getItem('orderBarState'));
        console.log("storedState bulk == " , storedState)
        if (storedState!=null) {
          setShoworderBar(true);
          setCompletedOrdersbulk(storedState.completedOrdersbulk);
          setTotalOrdersbulk(storedState.totalOrdersbulk);
          setlogId(storedState.logId);
          setstatusorder(storedState.completedOrdersbulk==storedState.totalOrdersbulk?"finished":"pending");
        }
           if(storedState!=null){
        if(storedState.completedOrdersbulk && storedState.totalOrdersbulk && storedState.completedOrdersbulk !=storedState.totalOrdersbulk){
         
            let url = config.apiUrl + '/shipment/view_summary_bulk_order_record?customer_id=' + userData.customer_id + '&log_id=' +  storedState.logId + '';
  
            // // console.log("headers => ", config.headers);
            axios({
              method: "get",
              url: url,
              headers: config.headers,
              responseType: "stream",
              onDownloadProgress: progressEvent => {
                // console.log("progressEvent ", progressEvent.currentTarget.response);
                let parseData = progressEvent.currentTarget.response;
                parseData = parseData.split("Z");
                parseData = JSON.parse(parseData[parseData.length - 1]);
        
                console.log("parsedData===== usebulk====",parseData)
      
                if(parseData.status!="pending"){
               
                // setUploadState({ ...uploadState, status: "" })
                // setShoworderBar(storedState.showorderBar )
                setCompletedOrdersbulk(parseData.uploaded_record)
                setTotalOrdersbulk(parseData.total_record)
                setstatusorder("finished")
                setlogId(storedState.logId)
                localStorage.setItem('orderBarState', JSON.stringify({
                  showordersBar:showorderBar,
                  completedOrdersbulk:parseData.uploaded_record,
                  logId:storedState.logId,
                 totalOrdersbulk:parseData.total_record,
                 statusbulk: "finished"
                }));
              }else{
               
                // setShoworderBar(storedState.showorderBar )
                setCompletedOrdersbulk(parseData.uploaded_record)
                setTotalOrdersbulk(parseData.total_record)
                setstatusorder("pending")
                setlogId(storedState.logId)
                localStorage.setItem('orderBarState', JSON.stringify({
                  showordersBar:showorderBar,
                  completedOrdersbulk:parseData.uploaded_record,
                 logId:storedState.logId,
                 totalOrdersbulk:parseData.total_record,
                 statusbulk: "pending"
                }));
              }
                // console.log(state);
                // setStateRecord({ uploaded_record: parseData.uploaded_record, total_record: parseData.total_record, status: parseData.status })
        
        
              }
            }).then((res) => {
              //  console.log("responseJson11 => ", res);
              // setState(res.data.output)
        
            })
        
      
        }
        else{
            console.log("not coming in else part")
        }
      }
      }, []);

      const toggleProgressBarbulk = () => {
        console.log("eeeeeeee")
        setShoworderBar(!showorderBar);
        localStorage.removeItem('orderBarState');
        // You may add other logic related to closing here
        // For example, resetting state variables
        setShoworderBar(false);
        setCompletedOrdersbulk(0);
        setTotalOrdersbulk(0);
        setlogId(null);
        setstatusorder({ status: '' });
      };
  

  return { showorderBar, completedOrdersbulk, totalOrdersbulk, logidstate, statusstateorder, toggleProgressBarbulk };
  
};

export default UseBulkShipmentTracker;