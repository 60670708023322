import React from 'react'
import Left_menu from './Left_menu'

import Billing_nav from './Billing_nav'
import {  useNavigate } from 'react-router-dom'

import axios from "axios";
import Swal from 'sweetalert2';

import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import config from "../../config"
import { useNetworkState } from 'react-use';

const Header = () => {
    let navigate = useNavigate()
     const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const[balancestate,setbalanceState]= React.useState({total_balance:[]})
  const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
  const [userState, setUserState] = React.useState({ full_name: "" });
  const [trackstate, settrackState] = React.useState({ awb: "" });
  const[userstate , setUser] = React.useState({})
  const[searchstate,setsearch]= React.useState({search:""})


  let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
  React.useEffect(()=>{
    get_wallet_balance()
    dimension_detail()
    // user()

  },[])
  const user=()=>{
    let dataToSend = {
};

  // console.log("datatoSend",dataToSend)
  let url = config.apiUrl + '/user/find_customer_detail';
 // console.log("headers =========> ", config.headers);
  axios.post(url, dataToSend, { headers: config.headers })
  .then((res) => {
    // console.log("res ===" , res.data.output)
    setUser(res.data.output)
    let response  = res.data.ouptut
    // // console.log("response ===" , response)
    //   const obscuredAadhar = obscureAadharNumber(res.data.ouptut.aadhaar_number);
    // // console.log("obscuredAadhar == " , obscuredAadhar)
    // setAadharNumber(obscuredAadhar);
      })
      .catch((error) => {
          // console.log(error);
      });    
}

    const get_wallet_balance=()=>{
        let dataToSend = {customer_id : userData.customer_id};
       
         // console.log("datatoSend",dataToSend)
         let url = config.apiUrl + '/user/get_customer_wallet';
         axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
               // console.log("responseJson => ", res);
               if(res.data.status==true){
           setbalanceState({total_balance:res.data.find_customer_wallet})
        }
       else{
           const Toast = Swal.mixin({
               toast: true,
               position: 'bottom-end',
               showConfirmButton: false,
               timer: 3000
             })
             Toast.fire({
               background:"rgb(231, 91, 75)",
               type: 'Unsuccess',
               title: "Something Went Wrong !",
               color:"white"
             });
       } })
           
             .catch((error) => {
               const Toast = Swal.mixin({
                   toast: true,
                   position: 'bottom-end',
                   showConfirmButton: false,
                   timer: 3000
                 })
                 Toast.fire({
                   background:"rgb(231, 91, 75)",
                   type: 'Unsuccess',
                   title: "Something Went Wrong !",
                   color:"white"
                 });
                 // console.log(error);
             });    
       
       }
       const dimension_detail = () =>{
        let full_api = config.apiUrl + `/user/get_company_info_api`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
          // console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }
      const logOutButton = () =>{
        // console.log("userDetail ===>   ");
      
        let full_api = config.apiUrl + `/user/user_logout`;
          let sendData = {customer_id:userData.customer_id};
          // console.log("bb", sendData);
          axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
              // console.log("res ", res);
              if (res.data.status==true) {
                localStorage.removeItem('ship_rocket_user');
                userDetail = null;
                setUserState({full_name:""})
                navigate('/customer/login')
              }else {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                Toast.fire({
                  background:"rgb(231, 91, 75)",
                  type: 'unsuccess',
                  title: res.data.message ,
                  color:"white"
                });
                 
              }
      
          }).catch((e) => {
      
      
          });
        
      
      }

      const handlechnage=(e)=>{
       settrackState({...trackstate , awb:e.target.value})
      }
      const searchchange =(e)=>{
        setsearch({search:e.target.value})
      }

      const shipmentpage = ()=>{
        window.location.href = `/customer/shipments?number=${searchstate.search}`;
      }

      const recharge_page =(sub)=>{
        // console.log("sub == " , sub[0].aadhar_kyc)
        if(sub[0].aadhar_kyc!=undefined && sub[0].aadhar_kyc==true){
       navigate('/customer/wallet_recharge')
        }
        else{
          const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 3000
            })
            Toast.fire({
              background:"rgb(231, 91, 75)",
              type: 'Unsuccess',
              title: "Need to do Kyc first !",
              color:"white"
            });
        }
     }

  return (
    <div>
       {/* <header class="shadow-sm">
            <div class="container">
                <nav class="navbar navbar-expand-sm navbar_vendor">
                    <div class="container-fluid">
                  
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="mynavbar">
                        <ul class="navbar-nav me-auto">
                      
                        </ul>
                        <div class="d-flex">
                            <ul class="navbar-nav ">
                                <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Quick Action</a>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" style={{cursor:"pointer"}} onClick={()=>navigate({
            pathname: '/customer/create_order_1',
            search: `value=0`,
          })}>Create Order</a></li>
                                        <li><a class="dropdown-item" style={{cursor:"pointer"}} href="/customer/warehouse">Create Warehouse</a></li>
                                        <li><a class="dropdown-item" style={{cursor:"pointer"}} href="/customer/rate_calculator">Rate Calculator</a></li>
                                        <li><a class="dropdown-item"  data-bs-toggle="modal" data-bs-target="#track_order">Track Shipment</a></li>
                                        
                                    </ul>
                                </li>
                                <li class="nav-item">
                                <a class="nav-link " href=""><i class="fa fa-window-maximize" aria-hidden="true"></i> Available Credit Limit  <span class="text-muted">&#8377;{balancestate.total_balance.map((sub)=>(
                       sub.wallet_balance>0 && sub.credit_limit?  parseFloat(sub.credit_limit).toFixed(2):!sub.credit_limit?0:parseFloat(sub.wallet_balance+sub.credit_limit).toFixed(2)))}</span> </a>
                                </li>
                                <li class="nav-item">
                                <a class="nav-link " href=""><i class="fa fa-window-maximize" aria-hidden="true"></i> Wallet <span class="text-muted">&#8377;{balancestate.total_balance.map((sub)=>(
                       parseFloat(sub.wallet_balance).toFixed(2)))}</span> </a>
                                </li>
                                <li class="nav-item">
                                <a class="nav-link" href=""><i class="fa fa-bell" aria-hidden="true"></i></a>
                                </li>
                                <li class="nav-item dropdown top_user_nav_dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false"><i class="fa fa-cog" aria-hidden="true"></i></a>
                                    <ul class="dropdown-menu dropdown-menu-lg-end top_user_nav_dropdown">
                                        <li>
                                            <a class="dropdown-item" style={{cursor:"pointer"}} href="#">
                                                <span class="pe-3"><i class="fa fa-user" aria-hidden="true"></i></span> {userData.full_name}
                                            </a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item" href="/customer/customer_bank">
                                                <span class="pe-3"><i class="fa fa-bank" aria-hidden="true"></i></span> Bank Information
                                            </a>
                                        </li>
                        <li>
                          <a class="dropdown-item customer_kyc" href="/customer/customer_kyc">

                            <span class="pe-3"><i class="fa fa-id-card" aria-hidden="true"></i></span>
                            Customer KYC
                          
                          </a>
                        </li>
                                        <li>
                                            <a class="dropdown-item" style={{cursor:"pointer"}} href='#'>
                                                <span class="pe-3"><i class="fa fa-lock" aria-hidden="true"></i></span> Change Password
                                            </a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item" style={{cursor:"pointer"}} onClick={() => logOutButton()}>
                                                <span class="pe-3"><i class="fa fa-sign-out" aria-hidden="true"></i></span> Logout
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    </div>
                </nav>
            </div>
        </header>
        <div class="modal" id="track_order">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Track Shipment</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body text-center p-5">
        <input type="text" class="form-control form-control-lg" id="text" name="awb" placeholder="Enter AWB number for tracking" onChange={(e)=>handlechnage(e)} />
        <div class="d-grid mt-3">
          <a  href={"/tracking/"+ trackstate.awb} class="btn btn-primary btn-block">Track Now</a>
        </div>
      </div>
    </div>
  </div>
</div> */}
 <div class="header_wrap">
        <div class=" ">
            <nav class="navbar navbar-expand-sm navbar-light">
                <div class="container-fluid">
                    {/* <!-- <a class="navbar-brand" href="javascript:void(0)">
                        <i class='bx bx-menu toggle'></i>
                    </a> --> */}
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                    <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse cust_nav_bar" id="mynavbar">
                        <div class="search-box-nav">
                            <div class="search-icon-nav">
                                <i class="fa fa-search" onClick={shipmentpage}></i> 
                            </div>
                            <input type="text" class="search-input-nav" placeholder="Search packages..." onChange={(e)=>searchchange(e)}/>
                        </div>
                        <ul class="navbar-nav ms-auto">
                          <li class="nav-item icon_nav_men_sup">
                          <a class="nav-link" style={{cursor:"pointer"}} onClick={(e)=>recharge_page(balancestate.total_balance.map((sub)=>(
                    sub)))}>
                          <span> Recharge</span>
                          </a>
                          </li>
                            <li class="nav-item icon_nav_men_wallet">
                                <div class="dropdown-wrapper">
                                    <a class="nav-link" >
                                        <span><i class="bx bx-wallet"></i> &#8377;{balancestate.total_balance.map((sub)=>(
                       parseFloat(sub.wallet_balance).toFixed(2)))}</span>
                                    </a>
                                    <div class="dropdown-content shadow-sm">
                                        <div>
                                            <small class="text-muted">Available Wallet Balance:</small> 
                                            <h4><small class="p-1"><i class="bx bx-wallet"></i></small> &#8377;{balancestate.total_balance.map((sub)=>(
                       parseFloat(sub.wallet_balance).toFixed(2)))}</h4>
                                        </div>
                                        <div>
                                            <small class="text-muted">Available Credit Limit:</small> 
                                            <h4><small class="p-1"><i class="bx bx-wallet"></i></small>&#8377; {balancestate.total_balance.map((sub)=>(
                       sub.wallet_balance>0 && sub.credit_limit?  parseFloat(sub.credit_limit).toFixed(2):!sub.credit_limit?0:parseFloat(sub.wallet_balance+sub.credit_limit).toFixed(2)))}</h4>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item icon_nav_men_sup">
                            <a class="nav-link" href="/customer/tickets">
                                <span><i class="bx bx-support"></i> Support</span>
                            </a>
                            </li>
                            <li class="nav-item dropdown icon_nav_men">
                                <a class="nav-link " href="#" role="button" data-bs-toggle="">
                                    <span>{userData.full_name}</span>
                                </a>
                                <ul class="dropdown-menu custom_drop_down  shadow-sm">
                                    {/* <li><a class="dropdown-item" href="#">Profile</a></li>
                                    <li><a class="dropdown-item" href="#">Change Password</a></li> */}
                                    <li>
                                            <a class="dropdown-item" href="/customer/customer_bank">
                                                Bank Information
                                            </a>
                                        </li>
                        <li>
                          <a class="dropdown-item customer_kyc" href="/customer/customer_kyc">

                           
                            Customer KYC
                          
                          </a>
                        </li>
                                    <li><a class="dropdown-item" style={{cursor:"pointer"}} onClick={() => logOutButton()}>Logout</a></li>
                                </ul>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    </div>
  )
}

export default Header
