import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import axios from "axios";
import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import config from "../config"
const Print_invoice = () => {
    const location = useLocation()
    const shipment_id = useParams()
    // console.log("shipment_id",shipment_id.shipment_id)

    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }

    const[state,setState]= React.useState({shipment_list:[]})
    // console.log("state",state)
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})

    React.useEffect(() => {
  
        shipment_list()
        dimension_detail()
    
      }, [])

    const shipment_list=()=>{
        let dataToSend = {customer_id : userData.customer_id,shipment_id:shipment_id.shipment_id
    };
   
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/customer_shipments';
     
     // // console.log("headers => ", config.headers);
     
      
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            setState({shipment_list:res.data.output})
          
  
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              // console.log(error);
          });    

    }
    // const SenderAddress = () =>{
    //   if(state.shipment_list[0].items.length > 0){
    //     return state.shipment_list[0].items.reduce((previousValue, currentValue) =>{
    //       return previousValue.item_weight + currentValue.item_weight}) 
    //   }else {
    //     return 0
    //   }
    //  } 


     const dimension_detail = () =>{
      let full_api = config.apiUrl + `/user/get_company_info_api`;
      let sendData = {};
    
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
        // console.log("rnmmmmm", res);
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        // console.log("----error:   ", e);
      })
    }
  return (
//     <div>
//        {state.shipment_list.map((sub)=>(
//         <section class="mb-3 pt-5 bg-light p-5">
//        <div class="container print_receipt_cs bg-white p-5">
//     <div class="row">
//        <div class="col-8">
//         <h1>ShipRocket</h1>
//         <p>
//             Bruderstrasse 27, Hamburg<br/>
//             Hamburg, Germany - 20355
//         </p>
//         <p>
//             Ph: 040 35715358
//         </p>
//        </div>
//        <div class="col text-end">
//         <h2>Invoice </h2>
//           <table class="table table-bordered">
//             <tbody>
//               <tr>
//               </tr><tr>
//                 <td>Invoice #</td>
//                 <td><h5>{sub.shipment_id}</h5></td>
//               </tr>
//                 <tr><td>Date</td>
//                 <td><Moment format="MMM DD YYYY">
//                         {new Date(sub.created*1000)}
//             </Moment> </td>
//               </tr>
//               <tr>
//                 <td>Customer AC #</td>
//                 <td>111293838</td>
//               </tr>
//               <tr>
//                 <td>Due Date</td>
//                 <td>12/03/2023</td>
//               </tr>
//             </tbody>
//           </table>
//        </div>
//     </div>
    
//     <div class="row">
   
//         <div class="col-6 col-md-6">
         
//           <h6>Invoice Bill To</h6>
//           <h2>{sub.name}</h2>
//           <p>
//            {sub.address}<br/>
//           {sub.city},{sub.state}, {sub.country} - {sub.pincode!=undefined && sub.pincode!=""?sub.pincode:""}
//           </p>
//           <p>
//               Ph: {sub.mobile}
//           </p>
          
//         </div>
        
//        <div class="col-6 col-md-6 text-end">
//         <div class="card pt-5 border-0">
//           <div class="card-body">
//             <h2 class="fw-700 text-danger">{sub.amount_paid==true?"PAID":"UNPAID"}</h2>
//           </div>
//         </div>
//        </div>
//     </div>
//     <hr/>
//     <div class="row pt-5">
//         <div class="col-12">
//          <h5>Billing information</h5>
//             <table class="table table-bordered">
//              <thead>
//                 <tr>
//                         <th>Order date</th>
//                         <th>type</th>
//                         <th>carrier name</th>
//                         <th>service name</th>
//                         <th>total weight</th>
//                         <th>no of item</th>
//                         <th>shipping fee</th>
//                 </tr>
//              </thead>
//              <tbody>
//                 <tr>
//                         <td><Moment format="MMM DD YYYY">
//                         {new Date(sub.created*1000)}
//             </Moment> <br/>
//                             <small><Moment format="hh:mm">
//                         {new Date(sub.created*1000)}
//             </Moment></small>
//                         </td>
//                         <td>{sub.type}</td>
//                         <td>{sub.carrier_name}</td>
//                         <td>{sub.service_provider}</td>
//                         <td>{sub.total_weight!=undefined && sub.total_weight!=""?sub.total_weight:""}({dimensionState.companyDetail.map((subscriber)=>( 
//                                   <>{subscriber.set_weight}</>
//                                  ))})</td>
//                         <td>{sub.items.length}</td>
//                         <td>{dimensionState.companyDetail.map((subscriber)=>( 
//                                   <>{subscriber.set_currency}</>
//                                  ))}{sub.rate_price}</td>
//                 </tr>
//                 <tr>
//                     <td colspan="6" class="text-end">Total</td>
//                     <td><strong>{dimensionState.companyDetail.map((subscriber)=>( 
//                                   <>{subscriber.set_currency}</>
//                                  ))}{sub.rate_price}</strong></td>
//                 </tr>
//                 <tr>
//                     <td colspan="6" class="text-end">Tax: VAT (18%)</td>
//                     <td><strong>{dimensionState.companyDetail.map((subscriber)=>( 
//                                   <>{subscriber.set_currency}</>
//                                  ))}{sub.tax_amount}</strong></td>
//                 </tr>
//                 <tr>
//                     <td colspan="6" class="text-end">Insurance</td>
//                     <td><strong>{dimensionState.companyDetail.map((subscriber)=>( 
//                                   <>{subscriber.set_currency}</>
//                                  ))}{sub.insurance_amount}</strong></td>
//                 </tr>
//                 <tr>
//                     <td colspan="6" class="text-end">Grand Total</td>
//                     <td><h6>{dimensionState.companyDetail.map((subscriber)=>( 
//                                   <>{subscriber.set_currency}</>
//                                  ))}{sub.total_amount}</h6></td>
//                 </tr>
//                 <tr>
//                     <td colspan="6" class="text-end">Paid Amount:</td>
//                     <td>{dimensionState.companyDetail.map((subscriber)=>( 
//                                   <>{subscriber.set_currency}</>
//                                  ))}{sub.total_amount}</td>
//                 </tr>
//              </tbody>
//             </table>
//         </div>
//     </div>

//     <div class="row pt-5">
//         <div class="col col-md">
//          <h5>Shipment Details </h5>
//             <table class="table table-bordered">
//                 <thead>
//                   <tr>
//                     <th>items</th>
//                     <th>invoice value</th>
//                     <th>reference/order number</th>
//                     <th>weight ({dimensionState.companyDetail.map((subscriber)=>( 
//                                   <>{subscriber.set_weight}</>
//                                  ))})</th>
//                     <th>quantity</th>
//                     <th>price</th>
//                     <th>total</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {sub.items.map((item)=>(
//                   <tr>
//                     <td>
//                         Name: <strong>{item.item_name}</strong><br/>
//                         {/* Description: <strong>{item.item_description}</strong><br/> */}
//                         {/* SKU: <strong>{item.item_sku}</strong><br/> */}
//                         Invoice number: <strong>{sub.order_number}</strong><br/>
//                         Invoice date: <strong><Moment format="MMM DD YYYY">
//                         {new Date(sub.created*1000)}
//             </Moment> </strong><br/>
//                         {/* product_url: <strong>google.com</strong><br/> */}
//                         Dim ({dimensionState.companyDetail.map((subscriber)=>( 
//                                   <>{subscriber.set_dimension}</>
//                                  ))}): <strong>H{sub.height}xW{sub.width}xL{sub.length}</strong>

//                     </td>
//                     <td>{dimensionState.companyDetail.map((subscriber)=>( 
//                                   <>{subscriber.set_currency}</>
//                                  ))}{item.item_value}</td>
//                     <td>{sub.order_number}</td>
//                     <td>{sub.total_weight}({dimensionState.companyDetail.map((subscriber)=>( 
//                                   <>{subscriber.set_weight}</>
//                                  ))})</td>
//                       <td>{item.item_quantity!=undefined && item.item_quantity!=""?item.item_quantity:""}</td>
//                     <td>{dimensionState.companyDetail.map((subscriber)=>( 
//                                   <>{subscriber.set_currency}</>
//                                  ))}{item.item_value}</td>
//                     <td>{dimensionState.companyDetail.map((subscriber)=>( 
//                                   <>{subscriber.set_currency}</>
//                                  ))}{item.item_value}</td>
//                   </tr>
//                   ))}
//                 </tbody>
//               </table>
//         </div>
//     </div>

//     <div class="row pt-5">
//         <div class="col col-md">
//             <h5>Pickup information</h5>
//             <table class="table table-bordered">
//               {sub.sender.map((send)=>(
//                 <tbody>
//                   <tr>
//                     <th>Sender Name</th>
//                     <td>{send.name}</td>
//                   </tr>
//                   <tr>
//                     <th>Address</th>
//                     <td>{send.address}</td>
//                   </tr>
//                   <tr>
//                     <th>Pickup Time</th>
//                     <td>N/A</td>
//                   </tr>
//                   <tr>
//                     <th>Phone Number</th>
//                     <td>{send.mobile}</td>
//                   </tr>
//                   <tr>
//                     <th>Postal Code</th>
//                     <td>{send.pincode}</td>
//                   </tr>
//                   <tr>
//                     <th>City</th>
//                     <td>{send.city}</td>
//                   </tr>
              
//                   <tr>
//                     <th>Country</th>
//                     <td>{send.country}</td>
//                   </tr>
//                   <tr>
//                     <th>Email</th>
//                     <td>{send.email}</td>
//                   </tr>
//                 </tbody>
//                 ))}
//             </table>
//         </div>
//         <div class="col col-md">
//             <h5>Delivery information</h5>
//             <table class="table table-bordered">
//             {sub.receiver.map((rec)=>(
//                 <tbody>
//                     <tr>
//                       <th>Receiver Name</th>
//                       <td>{rec.name}</td>
//                     </tr>
//                     <tr>
//                       <th>Address</th>
//                       <td>{rec.address}</td>
//                     </tr>
//                     <tr>
//                       <th>Delivery Time</th>
//                       <td>N/A</td>
//                     </tr>
//                     <tr>
//                       <th>Phone Number</th>
//                       <td>{rec.mobile}</td>
//                     </tr>
//                     <tr>
//                       <th>Postal Code</th>
//                       <td>{rec.pincode}
//                       </td>
//                     </tr>
//                     <tr>
//                       <th>City</th>
//                       <td>{rec.city}</td>
//                     </tr>
//                     {/* <tr>
//                       <th>State</th>
//                       <td>Surrey</td>
//                     </tr> */}
//                     <tr>
//                       <th>Country</th>
//                       <td>{rec.country}</td>
//                     </tr>
//                     <tr>
//                       <th>Email</th>
//                       <td>{rec.email}</td>
//                     </tr>
//                   </tbody>
//             ))}
//             </table>
//         </div>
//         <div class="col col-md">
//             <h5>Return information</h5>
//             <table class="table table-bordered">
//             {sub.return.map((ret)=>(
//                 <tbody>
//                   <tr>
//                     <th>Name</th>
//                     <td>{ret.name!=undefined && ret.name!=""?ret.name:"----"}</td>
//                   </tr>
//                   <tr>
//                     <th>Address</th>
//                     <td>{ret.address!=undefined && ret.address!=""?ret.address:"----"}</td>
//                   </tr>
//                   <tr>
//                     <th>Pickup Time</th>
//                     <td>N/A</td>
//                   </tr>
//                   <tr>
//                     <th>Phone Number</th>
//                     <td>{ret.mobile!=undefined && ret.mobile!=""?ret.mobile:"----"}</td>
//                   </tr>
//                   <tr>
//                     <th>Postal Code</th>
//                     <td>{ret.postal_code!=undefined && ret.postal_code!=""?ret.postal_code:"----"}</td>
//                   </tr>
//                   <tr>
//                     <th>City</th>
//                     <td>{ret.city!=undefined && ret.city!=""?ret.city:"----"}</td>
//                   </tr>
//                   {/* <tr>
//                     <th>State</th>
//                     <td></td>
//                   </tr> */}
//                   <tr>
//                     <th>Country</th>
//                     <td>{ret.country!=undefined && ret.country!=""?ret.country:"----"}</td>
//                   </tr>
//                   <tr>
//                     <th>Email</th>
//                     <td>{ret.email!=undefined && ret.email!=""?ret.email:"----"}</td>
//                   </tr>
//                 </tbody>
//             ))}
//             </table>
//         </div>
//     </div>  
    
//     <div class="row pt-5">
//         <div class="col-12">
//           <h6>Terms and conditions of shipment:</h6>
//           <ul>
//             <li>Goods not paid in full may not leave, please contact the agent</li>
//             <li>Payment is always in advance whether paid </li>
//             <li>Quoted delivery times are estimate and may vary</li>
//             <li>Consignor declares that the consignment is free of any dangerous or hazardous goods as defined by regulatory bodies and by legislation</li>
//             <li>Goods not picked within 7 days of offloading may be disposed off or charged storage</li>
//             <li>Insufficiently packed goods shipped at owner's risk and we will not be held responsible</li>
//             <li>Goods would only be presumed missing if not received 2 months from expected-by date</li>
//             <li>Compensation payable for loss or damage is limited to the repair costs of the item damaged or, if lost or damaged beyond repair, the replacement costs taking account of depreciation</li>
//             <li>Any claims for insured goods would have to be supported by original purchase receipts</li>
//           </ul>
//         </div>
//     </div>
//     <hr/>
   
// </div>   
//  </section> 

// ))}
//   </div>

<div style={{
  fontSize: "12px",
  margin: '0',
  padding: '3mm',
  // background: 'rgba(235,235,235,1)',
  // backgroundColor: 'rgba(235,235,235,1)',
  fontFamily: "arial,sans-serif",
  lineHeight: '18px'
}}>
<div class="container mt-3 wrap" style={{width:'75%'}}>
    <div class="card mb-1">
        <div class="card-body">
            <div class="pb-5">
                <img src="../../../img/logo_3.png" class="img-fluid" />
            </div>
            <div class="row">
                <div class="col-7">
                    <div class="pb-4">
                        <strong>BigFoot Retail Solutions Pvt. Ltd.</strong><br/>
                        Plot No. B, Khasra-360, Sultanpur, MG Road, New Delhi, Delhi 110030<br/>
                        Phone: +91-9266623006<br/>
                        Email: support@shiprocket.in
                    </div>
                    <div>
                        <strong>PAN Number:</strong> AAECB7131Q<br/>
                        <strong>CIN Number:</strong> U72900DL2011PTC225614<br/>
                        <strong>GSTIN:</strong> 07AAECB7131Q1ZC<br/>
                        <strong>IRN:</strong> 7003fb51d39741848aa484061c27003fb51d39741848aa484061c27003fb51d39741848aa484061c2
                    </div>
                </div>
                <div class="col-5">
                    <h6 class="pb-3"><strong>TAX INVOICE</strong></h6>
                    <h2 class="text-success" style={{fontWeight: '700'}}>PAID</h2>
                    <div class="download_btn">
                        <a href="javascript:window.print();">Download Invoice</a>
                    </div>
                    <div class="pt-2">
                        <strong>Invoice No. :</strong> SRF2122/00757758<br/>
                        <strong>Invoice Date :</strong> 12/11/2021<br/>
                        <strong>Due Date :</strong> 19/11/2021
                    </div>
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col-7">
                    <strong>Invoice To:</strong>
                    Information Lifestyle <br/>
                    203/20, Andhari, Mumbai <br/>
                    Maharashtra - 421001
                </div>
                <div class="col-5">
                    <strong>State Code:</strong> 27 <br/>
                    <strong>Place of Supply:</strong> Maharashtra <br/>
                    <strong>GSTIN:</strong> 27AADCL7744G1ZB <br/>
                    <strong>Reverse Charge:</strong> No <br/>
                </div>
            </div>
        </div>
    </div> 

    <div class="card mb-1">
      <div class="card-body">
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                  <tr>
                    <th>SAC No.</th>
                    <th>Description</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                      <td>996812</td>
                      <td>Shiprocket V2 Freight*</td>
                      <td>187.05</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>18.00% IGST</td>
                      <td>33.67</td>
                    </tr>
                  <tr>
                    <td></td>
                    <td class="fw-bold">Grand Total Value</td>
                    <td>220.72</td>
                  </tr>
                </tbody>
            </table>
        </div>
      </div>
    </div>
    <div class="card mb-1">
        <div class="card-body">
          <div class="table-responsive">
              <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Transaction Date</th>
                      <th>Gateway</th>
                      <th>Transaction ID</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>12/11/2021</td>
                        <td>Credit Balance</td>
                        <td>NA</td>
                        <td>220.72</td>
                      </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td class="fw-bold">Amount Due</td>
                      <td>0.00</td>
                    </tr>
                  </tbody>
              </table>
          </div>
        </div>
    </div>
    <div class="card mb-1">
        <div class="card-body">
            <div class="border-bottom py-2">
                <strong>Bank and Other Commercial Details</strong>
            </div>
            <div>
                All Payments by transfer/check/DD should be draw in favour of <br/>
                <strong>Entity Name:</strong> Bigfoot Retail Solutions Pvt. Ltd. <br/>
                <strong>Account number:</strong> 7511603720 <br/>
                <strong>Bank:</strong> Kotak Mahindra Bank <br/>
                <strong>Branch:</strong> Saket, New Delhi <br/>
                <strong>RTGS/NEFT/IFSC Code:</strong> KKBK0000187
            </div>
        </div>
    </div>
</div>
</div>
  
  )
}

export default Print_invoice