import React from 'react'
import { useParams } from 'react-router-dom'
import Customer_header from './Customer_header'
import config from '../config'
import axios from 'axios'
import Header_nav from './Header_nav'
import Footer from './Footer';
import Swal from 'sweetalert2'
const Edit_customer_profile = () => {
    const customer_id = useParams()
    // // console.log("customer_id",customer_id)
    const[infostate,setInfoState]= React.useState({customer_info:[],isLoading:true})
    const [state, setState] = React.useState({customer_id:customer_id.cust_id, full_name: "", email: "", mobile: "",address:"", country: "", city: "", state: "",image:"" });
    // // console.log("state",state)
    const [stateSave, setSaveState] = React.useState({ uploadimg: ""});
    const [isImageSelected, setIsImageSelected] = React.useState(false);

     React.useEffect(() => {
        getProfile();
      }, []);

    const getProfile = () => {
       
          
          let userDetail = localStorage.getItem('ship_rocket_user');
          // // console.log("userDetail====", config.UserId);
          let userData = {};
          if (userDetail != null) {
            userData = JSON.parse(userDetail)
          }
          setState({...state,customer_id:state.customer_id, full_name: userData.full_name, email: userData.email, mobile: userData.mobile,address: userData.address, image:userData.image , country:userData.country, state:userData.state , city:userData.city })

        
      }

     

      const handleChange =(event)=>{
        // // console.log("event",event.target.type)
        setIsImageSelected(true);
        if (event.target.type == "file") {
            setSaveState({
                  ...stateSave,
                  uploadimg: event.target.files,
                })
            }
            else{
     
    
          setState({
            ...state,
            [event.target.name]: event.target.value,
          })
         
      
        }
    
      }

  const axios_get_api = () => {
    // // console.log("submit1",{customer_id:customer_id.cust_id, full_name:state.full_name,image: stateSave.uploadimg});
     

     const fd = new FormData();
     fd.append("customer_id", customer_id.cust_id);
     fd.append("full_name", state.full_name);
     fd.append("email", state.email); 
     fd.append("address", state.address);
     fd.append("mobile", state.mobile); 
     fd.append("country", state.country);
     fd.append("state", state.state);
     fd.append("city", state.city);
     
     fd.append("image", stateSave.uploadimg);
     
 
    if (stateSave.uploadimg!= null && stateSave.uploadimg!='') {
      fd.append('image',stateSave.uploadimg[0])
    }
//     else if (isImageSelected  == true) {
//       fd.append("image",stateSave.uploadimg[0],stateSave.uploadimg.name)
//     }
//    if (updateStateImage.image && updateStateImage.image != '') {
//             bodyFormData.append("category_img", updateStateImage.image[0]);
//         }
 
    // // console.log("hey man",fd);
   if(customer_id.cust_id!=undefined && customer_id.cust_id!=""){
     if(state.full_name!="" && state.email!='' && state.address!=' ' && state.mobile!="" && state.country!="" && state.state!="" && state.city!=""  ){
      
    axios.post(config.apiUrl + `/user/profile_update`,  fd , { headers: config.headers }).then((result)=>{
                // // console.log("result is i ",result.data.output[0].image[0]);
               
                if (result.data.output.length>0) {
                   
                     localStorage.setItem('ship_rocket_user', JSON.stringify(result.data.output[0]));
                     setSaveState({uploadimg:result.data.output[0].image})
                    //  setState(res.data.output[0])
                    // // console.log("---------------------------");
                     
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 2000
                      })
                      
                      Toast.fire({
                        background:"#e63900",
                        type: 'Success',
                        title: "Successfully Update",
                        color:"white"
                      });
                    
                    window.location.reload();
                }
                    getProfile()
                    // setaddState({web_body_id:"",body_heading:"",pic:"right",body_sub_heading:"",body_img:"",body_content:"",body_link_title:""})
                
                
                }).catch((e) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 5000
                      })
                      
                      Toast.fire({
                        background:"#e63900",
                        type: 'error',
                        title: "Something Went Wrong",
                        color:"white"
                      });
                 
                  // // console.log("----error:   ", e);
                })
            
            }
            else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 2000
                  })
                  
                  Toast.fire({
                    background:"#e63900",
                    type: 'error',
                    title: "Please Fill the details",
                    color:"white"
                  });
            }
            }
                else{
                    // const Toast = Swal.mixin({
                    //     toast: true,
                    //     position: 'bottom-end',
                    //     showConfirmButton: false,
                    //     timer: 5000
                    //   })
                      
                    //   Toast.fire({
                    //     background:"#e63900",
                    //     type: 'error',
                    //     title: "Something Went Wrong",
                    //     color:"white"
                    //   });
                }

  };
       
  
  return (
    <div >
   <Header_nav/>
   <div class="sub_header_inner">
</div>
   <div class="container width_custom bg-white p-3 mt-3">
            <div class="text-center">
                <div class="figure-profile shadow my-4">
                    <figure> {state.image!=undefined && state.image !=""?  <img src={"https://cdn.shiport.in/images/"+state.image}alt=""/>:
                        <img src="../../img/user1.png" alt="" className='img-fluid'/>}</figure>
                    <div class="btn btn-dark text-white floating-btn">
                        <i class="material-icons">camera_alt</i>
                        <input type="file" class="float-file" name="image"  onChange={(e) => handleChange(e)}/>
                    </div>
                </div>
            </div>

            <h6 class="subtitle">Basic Information</h6>
            <div class="row mt-3">
                <div class="col-12 col-md-12 mb-3">
                    <div class="form-group float-label active">
                    <label class="form-control-label">Name</label>
                        <input type="text" class="form-control" name="full_name"  onChange={(e)=>handleChange(e)} required="" value={state.full_name}/>
                        
                    </div>
                </div>
                <div class="col-12 col-md-12 mb-3">
                    <div class="form-group float-label active">
                    <label class="form-control-label">Mobile Number</label>
                        <input type="tel" class="form-control" name= "mobile"   onChange={(e)=>handleChange(e)} required="" value={state.mobile!=undefined && state.mobile!="" ? state.mobile:"" }/>
                      
                    </div>
                </div>

                <div class="col-12 col-md-12 mb-3">
                    <div class="form-group float-label active">
                    <label class="form-control-label">Email</label>
                        <input type="text" class="form-control" name="email" onChange={(e)=>handleChange(e)} required="" value={state.email!=undefined && state.email!="" ? state.email:"" }/>
                        
                    </div>
                </div>
            </div>

            <h6 class="subtitle">Address</h6>

            <div class="row mt-3">
                <div class="col-12 col-md-12 mb-3">
                    <div class="form-group float-label active">
                    <label class="form-control-label">Address</label>
                        <input type="text" class="form-control" required="" name="address"  onChange={(e)=>handleChange(e)}  value={state.address!=undefined && state.address!=""? state.address:""}/>
                       
                    </div>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <div class="form-group float-label active">
                        {/* <select class="form-control">
                            <option>United Sates</option>
                            <option>America</option>
                            <option>Africa</option>
                            <option>France</option>
                        </select> */}
                          <label class="form-control-label">Country</label>
                        <input type="text" class="form-control" required="" name="country"  onChange={(e) => handleChange(e)}value={state.country!=undefined && state.country!=""? state.country:""}/>
                      
                    </div>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <div class="form-group float-label active">
                        {/* <select class="form-control">
                            <option>New York</option>
                            <option>America</option>
                            <option>Africa</option>
                            <option>France</option>
                        </select> */}
                        <label class="form-control-label">State</label>
                         <input type="text" class="form-control" required=""  onChange={(e) => handleChange(e)} name="state" value={state.state!=undefined && state.state!=""? state.state:""}/>
                        
                    </div>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <div class="form-group float-label active">
                        {/* <select class="form-control">
                            <option>New York</option>
                            <option>America</option>
                            <option>Africa</option>
                            <option>France</option>
                        </select> */}
                         <label class="form-control-label">City</label>
                        <input type="text" class="form-control" required=""  onChange={(e) => handleChange(e)} name="city" value={state.city!=undefined && state.city!=""? state.city:""}/>
                       
                    </div>
                </div>
            </div>

            <br/>
            <div class="d-grid">
                <a  class="btn btn-lg btn-primary text-white btn-block btn-rounded"><span onClick={()=>axios_get_api()}>Update Profile</span><i class="material-icons">arrow_forward</i></a>
            </div>
          
        </div>
        <Footer/>
    </div>
  )
}

export default Edit_customer_profile
