import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar, ModalHeader, ModalTitle } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import moment from 'react-moment';
import download from 'downloadjs'
import { useNetworkState } from 'react-use';
import UseProgressTracker from './UseProgressTracker'
import Footer from './Footer'
import { parse } from 'papaparse';

const Bulk_order_log = () => {
const{log_id} = useParams() 
const isOnline = useNetworkState();
console.log("isONline  === " ,isOnline )
const[state,setState]= React.useState([])
const { showProgressBar, completedOrders, totalOrders, recordId, status, toggleProgressBar } = UseProgressTracker();
const [showorderBar, setShoworderBar] = React.useState(true);

const [statusstateorder, setstatusorder] = React.useState({status:""});

const [completedOrdersbulk, setCompletedOrdersbulk] = React.useState(0);
const [uploaded, setuploaded] = React.useState(0);
const [total, settotal] = React.useState(0);

const [totalOrdersbulk, setTotalOrdersbulk] = React.useState(0);
const [logidstate, setlogId] = React.useState(null);

let userDetail = localStorage.getItem('ship_rocket_user');
// console.log("userDetail====", config.UserId);
let userData = {};
if (userDetail != null) {
  userData = JSON.parse(userDetail)
}
    const shipment_list=(status)=>{
        let dataToSend = {log_id : log_id
    };
   
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/bulk_shipment_log_view';
     
     // console.log("headers => ", config.headers);
     
      
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            setState(res.data.output)
            if(res.data.output[0].uploaded_records!=res.data.output[0].total_records){
              summary_record()
            }
          
            // setisLoading(false)
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              // console.log(error);
          });    

    }
    const toggleProgressBarbulk = () => {
      console.log("eeeeeeee")
      setShoworderBar(!showorderBar);
      localStorage.removeItem('orderBarState');
      // You may add other logic related to closing here
      // For example, resetting state variables
      setShoworderBar(false);
      setCompletedOrdersbulk(0);
      setTotalOrdersbulk(0);
      setlogId(null);
      setstatusorder({ status: '' });
    };

    React.useEffect(()=>{
        shipment_list()
        // summary_record()
    },[])
        
    React.useEffect(()=>{
      const storedState = JSON.parse(localStorage.getItem('orderBarState'));
      console.log("storedState bulk == " , storedState)
      if (storedState) {
        // setShoworderBar(storedState.showordersBar);
        setCompletedOrdersbulk(storedState.completedOrdersbulk);
        setTotalOrdersbulk(storedState.totalOrdersbulk);
        setlogId(storedState.logId);
        setstatusorder({status:storedState.completedOrdersbulk==storedState.totalOrdersbulk?"finished":"pending"});
      }
         if(storedState!=null){
      if(storedState.completedOrdersbulk && storedState.totalOrdersbulk && storedState.completedOrdersbulk !=storedState.totalOrdersbulk){
        let url = config.apiUrl + '/shipment/view_summary_bulk_order_record?customer_id=' + userData.customer_id + '&log_id=' + log_id + '';
      
        axios({
          method: "get",
          url: url,
          headers: config.headers,
          responseType: "stream",
          onDownloadProgress: progressEvent => {
            // console.log("progressEvent ", progressEvent.currentTarget.response);
            let parseData = progressEvent.currentTarget.response;
            parseData = parseData.split("Z");
            parseData = JSON.parse(parseData[parseData.length - 1]);
    
            console.log("parsedData=====",parseData)
  
           
           if(parseData.status=="finished"){
            // setShoworderBar(storedState. )
            setCompletedOrdersbulk(parseData.uploaded_record)
            setTotalOrdersbulk(parseData.total_record)
            setstatusorder({...statusstateorder,status:"finished"})
            localStorage.setItem('orderBarState', JSON.stringify({
              // showordersBar:true,
              completedOrdersbulk:parseData.uploaded_record,
             logId:log_id,
             totalOrdersbulk:parseData.total_record,
             statusbulk: "finished"
            }));
          }
          
            // console.log(state);
            // setStateRecord({ uploaded_record: parseData.uploaded_record, total_record: parseData.total_record, status: parseData.status })
    
    
          }
        }).then((res) => {
          //  console.log("responseJson11 => ", res);
          // setState(res.data.output)
    
        })
      }
         }
    },[])
    const summary_record = () => {
      console.log("summary_record called")
     
     
      let url = config.apiUrl + '/shipment/view_summary_bulk_order_record?customer_id=' + userData.customer_id + '&log_id=' + log_id + '';
  
      // // console.log("headers => ", config.headers);
      axios({
        method: "get",
        url: url,
        headers: config.headers,
        responseType: "stream",
        onDownloadProgress: progressEvent => {
          // console.log("progressEvent ", progressEvent.currentTarget.response);
          let parseData = progressEvent.currentTarget.response;
          parseData = parseData.split("Z");
          parseData = JSON.parse(parseData[parseData.length - 1]);
  
          console.log("parsedData=====",parseData)

          if(parseData.status!="pending"){
         
          // setUploadState({ ...uploadState, status: "" })
          // setShoworderBar(true )
          setCompletedOrdersbulk(parseData.uploaded_record)
          setTotalOrdersbulk(parseData.total_record)
          setstatusorder({...statusstateorder,status:"finished"})
          setlogId(log_id)
          setuploaded(parseData.uploaded_record)
          localStorage.setItem('orderBarState', JSON.stringify({
            // showordersBar:true,
            completedOrdersbulk:parseData.uploaded_record,
            logId:log_id,
           totalOrdersbulk:parseData.total_record,
           statusbulk: "finished"
          }));
        }else{
         
          // setShoworderBar(true )
          setCompletedOrdersbulk(parseData.uploaded_record)
          setTotalOrdersbulk(parseData.total_record)
          setstatusorder({...statusstateorder,status:"pending"})
          setlogId(log_id)
          localStorage.setItem('orderBarState', JSON.stringify({
            // showordersBar:true,
            completedOrdersbulk:parseData.uploaded_record,
           logId:log_id,
           totalOrdersbulk:parseData.total_record,
           statusbulk: "pending"
          }));
        }
          // console.log(state);
          // setStateRecord({ uploaded_record: parseData.uploaded_record, total_record: parseData.total_record, status: parseData.status })
  
  
        }
      }).then((res) => {
        //  console.log("responseJson11 => ", res);
        // setState(res.data.output)
  
      })
  
    }

  return (
    <div>
          {/* <?php include 'meta.php';?>
    
    <?php include 'left_menu.php';?> */}
<Left_menu/>
    <section class="home ">
        {/* <?php include 'header.php';?>  */}

        <Header/>

        <section class="mb-3 pt-4">
          <div class="container">
            <div class="row mb-3">
              <div class="col-12 col-md-3">
                <h3><a href="/customer/orders"><i class="fa fa-chevron-left" aria-hidden="true"></i></a>  Bulk Orders Log</h3>
              </div>
            </div> 

            
               
          </div>
        </section>


        <section class="mb-3">
          <div class="container">
            <div class="table-responsive">
                <table class="table table_box">
                    <thead  class="">
                        <tr class="brd">
                        <th>Log ID</th>
                        <th>Date/Time</th>
                        <th>Orders</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        {state.map((sub)=>(

                       
                        <tr class="brd">
                            <td>
                                <h5><a href={`/customer/bulk_orders_log_detail/${sub.log_id}`}>{sub.log_id}</a></h5>
                            </td>
                            <td>
                             <small>{sub.date} | {sub.time}</small>
                            </td>
                            <td>
                                <h2><strong>{completedOrdersbulk==0?sub.uploaded_records:completedOrdersbulk}</strong><small class="text-primary">/{totalOrdersbulk==0?sub.total_records:totalOrdersbulk}</small></h2>
                            </td>
                            <td>
                                {sub.status==0?
                                <h2><span class="badge rounded-pill bg-info fw-400">Running</span></h2>:
                                <h2><span class="badge rounded-pill bg-success fw-400">Completed</span></h2>}
                            </td>
                            <td>
                                <h5><a href={`/customer/bulk_orders_log_detail/${sub.log_id}`}>View Log</a></h5>
                            </td>
                        </tr>
                         ))}
                    </tbody>
                </table>
            </div>     
          </div>
        </section>
        
    </section>

    <Footer
                    showProgressBar={showProgressBar}
                    completedOrders={completedOrders}
                    totalOrders={totalOrders}
                    viewLogs={recordId}
                    status ={status}
                     toggleProgressBar={toggleProgressBar} 

                     showorderBar={showorderBar}
                     completedOrdersbulk={completedOrdersbulk}
                     totalOrdersbulk={totalOrdersbulk}
                     viewLogslogid={logidstate}
                     statusbulk ={statusstateorder.status}
                      toggleProgressBarbulk={toggleProgressBarbulk} 
                      

                  />

    {/* <?php include 'footer.php';?> */}
    </div>
  )
}

export default Bulk_order_log
