import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Billing_nav from './Billing_nav'
import {  useNavigate } from 'react-router-dom'
// import Customer_header from './Customer_header'
import axios from "axios";
import Swal from 'sweetalert2';

import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import config from "../../config"
import { useNetworkState } from 'react-use';
import UseProgressTracker from './UseProgressTracker'
import Footer from './Footer'
import UseBulkShipmentTracker from './UseBulkShipmentTracker'
const Billing_cod_remittance = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
  const { showProgressBar, completedOrders, totalOrders, recordId, status, toggleProgressBar } = UseProgressTracker();

    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const[state,setState] = React.useState({wallet_balance:""})
    const[balancestate, setbalanceState ]= React.useState({add_balance:[], total_balance:[]})
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
    const{showorderBar, completedOrdersbulk, totalOrdersbulk, logidstate, statusstateorder, toggleProgressBarbulk}= UseBulkShipmentTracker();
    const[transaction  , settransaction] = React.useState([])

   React.useEffect(()=>{
    get_cod_wallet_balance()
    dimension_detail()
    cod_wallet_transactions()
   },[])

    const get_cod_wallet_balance=()=>{
     let dataToSend = {customer_id : userData.customer_id};
    
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/user/get_cod_wallet_balance';
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            if(res.data.status==true){
        setbalanceState({add_balance:res.data.added_wallet_transactions, total_balance:res.data.find_customer_wallet})
     }
    else{
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          Toast.fire({
            background:"rgb(231, 91, 75)",
            type: 'Unsuccess',
            title: "Something Went Wrong !",
            color:"white"
          });
    } })
        
          .catch((error) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'Unsuccess',
                title: "Something Went Wrong !",
                color:"white"
              });
              // console.log(error);
          });    
    
    }

    // const handleChange = (e) => {
    //     // console.log("e ", e.target.name);
    //     // console.log("e checked", e.target.value);
    //    setState({wallet_balance:e.target.value})
    //   }

    //   const addbalance=()=>{
    //      if(state.wallet_balance!=""){
    //     navigate({pathname:`/checkout`},{state:state.wallet_balance})
    //      }
    //      else{
    //         const Toast = Swal.mixin({
    //             toast: true,
    //             position: 'bottom-end',
    //             showConfirmButton: false,
    //             timer: 3000
    //           })
    //           Toast.fire({
    //             background:"rgb(231, 91, 75)",
    //             type: 'Unsuccess',
    //             title: "Please Fill the Amount !",
    //             color:"white"
    //           });

    //      }
    // }
    const dimension_detail = () =>{
        let full_api = config.apiUrl + `/user/get_company_info_api`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
          // console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }

      const cod_wallet_transactions=()=>{
        let dataToSend = {customer_id : userData.customer_id};
       
         // console.log("datatoSend",dataToSend)
         let url = config.apiUrl + '/shipment/get_cod_wallet_transactions';
         axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
               // console.log("responseJson => ", res);
               if(res.data.status==true){

                settransaction(res.data.output)
        }
       else{
           const Toast = Swal.mixin({
               toast: true,
               position: 'bottom-end',
               showConfirmButton: false,
               timer: 3000
             })
             Toast.fire({
               background:"rgb(231, 91, 75)",
               type: 'Unsuccess',
               title: "Something Went Wrong !",
               color:"white"
             });
       } })
           
             .catch((error) => {
               const Toast = Swal.mixin({
                   toast: true,
                   position: 'bottom-end',
                   showConfirmButton: false,
                   timer: 3000
                 })
                 Toast.fire({
                   background:"rgb(231, 91, 75)",
                   type: 'Unsuccess',
                   title: "Something Went Wrong !",
                   color:"white"
                 });
                 // console.log(error);
             });    
       
       }
  return (
    <div>
          {/* <?php include 'meta.php';?> */}
    
    {/* <?php include 'left_menu.php';?> */}
<Left_menu value={8} />
    <section class="home ">
        {/* <?php include 'header.php';?>  */}
<Header/>
        

        {/* <?php include 'billing_nav.php';?>    */}
<Billing_nav value={8}/>
        <section class="mb-3">
          <div class="container">
            <div class="card border-0">
              <div class="card-body">
                <div class="row states stboxbil py-3">
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded  ">
                        {balancestate.total_balance.map((sub)=>( <h5>&#8377;{parseFloat(sub.cod_wallet_balance).toFixed(2)}</h5>))}
                            <small>COD To Be Remitted <a href="#" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover" data-bs-content="Amount to be remitted in next cycle" class="text-muted"><i class="fa fa-question-circle-o" aria-hidden="true"></i></a></small>
                        </div>
                    </div>
                    {/* <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Last COD Remitted</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Total COD Remitted</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Total deduction from COD</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Remittance Initiated</small>
                        </div>
                    </div> */}
                </div>
              </div>
            </div>     
          </div>
        </section>
        <section class="mb-3">
          <div class="container">
            <div>
                {/* <div class="input-group mb-3 input-group-sm">
                    <input type="date" class="form-control" placeholder="Search by Order ID"/>
                    <select class="form-select">
                      <option>Last one year</option>
                      <option>2022</option>
                      <option>2021</option>
                      <option>2020</option>
                    </select>
                    <input type="text" class="form-control w-25" placeholder="Search by Order ID"/>
                    <button class="btn btn-primary" type="submit">Search</button>
                </div> */}
            </div>
            <div class=" table-responsive">
                <table class="table table-bordered table_box billing_table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Transaction Id</th>
                        <th>Shipment Id</th>
                        <th>Payout ID</th>
                        <th>Method</th>

                        <th>Amount</th>
                        <th>Status</th>
                         </tr>
                    </thead>
                    <tbody>
                      {transaction.map((sub)=>(
                      <tr>
                      <td>{sub.date}</td>
                      <td>{sub.transaction_id}</td>
                      <td>{sub.shipment_id}</td>
                      <td> <a className='text-decoration-none' href={`/customer/transaction_detail/${sub.payout_id}`} style={{cursor:"pointer"}}>{sub.payout_id?sub.payout_id:""}</a> </td>

                      <td>{sub.payment_method}</td>

                      <td>{sub.amount}</td>
                      <td>{sub.payment_status==2?<span class="badge rounded-pill bg-success fw-400">Success</span>:sub.payment_status==3?<span class="badge rounded-pill bg-warning fw-400">Under Process</span>:""}</td>

                      </tr>
                      ))}
                     
                      {/* <tr>
                        <td colspan="12" class="text-center">
                          <p>Your remittance is on its way</p>
                          <small>We release COD remittance 3 times in a week and on the 8th day from the date of delivery.</small>
                        </td>
                      </tr> */}
                    </tbody>
                </table>  
            </div>
          </div>
        </section>

        {/* <section class="mb-3">
          <div class="container">
            <div>
                <div class="input-group mb-3 input-group-sm">
                    <input type="date" class="form-control" placeholder="Search by Order ID"/>
                    <select class="form-select">
                      <option>Last one year</option>
                      <option>2022</option>
                      <option>2021</option>
                      <option>2020</option>
                    </select>
                    <input type="text" class="form-control w-25" placeholder="Search by Order ID"/>
                    <button class="btn btn-primary" type="submit">Search</button>
                </div>
            </div>
            <div class=" table-responsive">
                <table class="table table-bordered table_box billing_table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>CRF ID</th>
                        <th>UTR</th>
                        <th>COD Available</th>
                        <th>Freight Charges From COD</th>
                        <th>Early COD Charges</th>
                        <th>RTO Reversal Amount</th>
                        <th>Remittance Amount</th>
                        <th>Remittance Method</th>
                        <th>Status</th>
                        <th>Remarks</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Mon, Sep 6, 2021</td>
                        <td><a href="">3005667</a></td>
                        <td>CMS2113476617</td>
                        <td>1009.00</td>
                        <td>851.46</td>
                        <td>9.99</td>
                        <td>0.00</td>
                        <td>147.55</td>
                        <td>Postpaid</td>
                        <td>Remittance success</td>
                        <td>Remittance success</td>
                        <td><a href=""><i class="fa fa-download" aria-hidden="true"></i></a></td>
                      </tr>
                      <tr>
                        <td colspan="12" class="text-center">
                          <p>Your remittance is on its way</p>
                          <small>We release COD remittance 3 times in a week and on the 8th day from the date of delivery.</small>
                        </td>
                      </tr>
                    </tbody>
                </table>  
            </div>
          </div>
        </section> */}
        
    </section>
    <Footer
                    showProgressBar={showProgressBar}
                    completedOrders={completedOrders}
                    totalOrders={totalOrders}
                    viewLogs={recordId}
                    status ={status}
                     toggleProgressBar={toggleProgressBar} 
                     showorderBar={showorderBar}
                     completedOrdersbulk={completedOrdersbulk}
                     totalOrdersbulk={totalOrdersbulk}
                     viewLogslogid={logidstate}
                     statusbulk ={statusstateorder}
                      toggleProgressBarbulk={toggleProgressBarbulk} 
                  />

    {/* <?php include 'footer.php';?> */}
    </div>
  )
}

export default Billing_cod_remittance
