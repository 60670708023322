import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar, ModalHeader, ModalTitle } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import moment from 'react-moment';
import download from 'downloadjs'
import TablePagination from '@mui/material/TablePagination';
import { useNetworkState } from 'react-use';
import UseProgressTracker from './UseProgressTracker'
import Footer from './Footer'
import UseBulkShipmentTracker from './UseBulkShipmentTracker';

const Customer_invoice = () => {
    let navigate = useNavigate()
    const[state , setState ] = React.useState([])
    const[isLoading , setisLoading] = React.useState(true)
    const { showProgressBar, completedOrders, totalOrders, recordId, status, toggleProgressBar } = UseProgressTracker();
    const{showorderBar, completedOrdersbulk, totalOrdersbulk, logidstate, statusstateorder, toggleProgressBarbulk}= UseBulkShipmentTracker();
    React.useEffect(()=>{
        invoice_list()
    },[])
    const invoice_list=(status,index=0,onLoad)=>{
        let dataToSend = {
    };
   
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/customer_invoices';
     
     // console.log("headers => ", config.headers);
     
      
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
           
            setState(res.data.output)
            setisLoading(false)
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              // console.log(error);
          });    

    }
    const next_step = (sub)=>{
        navigate({
          pathname: '/customer/invoice_vendor',
          search: `invoice_id=${sub.invoice_id}&invoice_date=${sub.invoice_date}&amount=${sub.amount}`
        })
      }
  return (
    <div>
      {/* <?php
            include('header.php');
        ?>

        <?php
            include('left_nav.php');
        ?> */}
        <Left_menu value={14}/>
       
    <section class="home">
            {/* <?php
            include('sub_header.php');
            ?>  */}
            {/* <section className="home"></section> */}
        <Header/>
    
           <div className='container'>
        <section class="mt-3">
        <div class="row mb-3">
            <div class="col-12 col-md-4">
                <h5>Customer Invoices</h5>
            </div>
            <div class="col-12 col-md">
            </div>
        </div>
        {isLoading? 
                        <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"60px", alignItems: 'center',marginTop:"-200px", height: '100vh' }}>
                        <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />
                        </div>:
            <div class="card card-body border-0 shadow table-wrapper table-responsive ">
                <table class="table table-hover  shipm_tablew">
                    <thead>
                        <tr>
                            <th class="border-gray-200">Invoice No. #</th>
                            <th class="border-gray-200">Customer</th>
                            <th class="border-gray-200">Invoice Date</th>
                            <th class="border-gray-200">Invoice Period</th>
                            <th class="border-gray-200">Invoice Amount</th>
                            <th class="border-gray-200">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                       
                        {state.map((sub)=>(

                       
                        <tr>
                            <td>
                            <strong>{sub.invoice_id}</strong>
                            </td>  
                            <td>
                                {sub.company_name?sub.company_name:sub.full_name?sub.full_name:""}
                            </td>                      
                            <td>
                                {sub.invoice_date}
                            </td>  
                            <td>
                               {sub.invoice_period}
                            </td>
                            <td>
                                ₹{sub.amount?sub.amount:0}
                            </td>
                            <td class="fw-700">
                                {/* <a href="assets/doc/shipment_report.csv" class="btn btn-outline-primary "><i class="fa fa-file-excel-o" aria-hidden="true"></i></a> */}
                                <a onClick={()=>next_step(sub)} class="btn btn-primary"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a>
                            </td>
                        </tr>
                         ))}
                    </tbody>
                </table>
            </div> 
}

    </section>
    </div>               

    </section>
    <Footer
                    showProgressBar={showProgressBar}
                    completedOrders={completedOrders}
                    totalOrders={totalOrders}
                    viewLogs={recordId}
                    status ={status}
                     toggleProgressBar={toggleProgressBar} 
                     showorderBar={showorderBar}
                     completedOrdersbulk={completedOrdersbulk}
                     totalOrdersbulk={totalOrdersbulk}
                     viewLogslogid={logidstate}
                     statusbulk ={statusstateorder}
                      toggleProgressBarbulk={toggleProgressBarbulk} 
                  />

{/* <?php
include('footer.php');
?>   */}
    </div>
  )
}

export default Customer_invoice
