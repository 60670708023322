import React from 'react';

const Footer = ({
  showProgressBar,
  completedOrders,
  totalOrders,
  viewLogs,
  status,
  toggleProgressBar,
  showorderBar,
  completedOrdersbulk,
  totalOrdersbulk,
  viewLogslogid,
  statusbulk,
  toggleProgressBarbulk,
}) => {
  console.log("showProgressBar == ", showProgressBar);
  console.log("showorderBar == ", showorderBar);

  return (
    <div className='progress_bar_container'>
      {showProgressBar && (
        <div className="fixed-bottom progress_bar">
          <div className="row">
            <div className="col-12 col-md">
              <div className="d-flex">
                <div className="p-3">
                  {status === "pending" ? <div className="spinner"></div> : ""}
                </div>
                <div className=" ">
                  <h4>Order Processing ...</h4>
                  {status === "pending" ? (
                    <small>Order import has been started and running in background.</small>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <h4>
                {completedOrders} <small>/{totalOrders}</small>
              </h4>
              {status === "finished" ? <small>Order Completed</small> : ""}
            </div>
            <div className="col-12 col-md-2 text-end">
              <a href={`/summary/${viewLogs}`} className="btn btn-light btn-sm">
                View Logs
              </a>
            </div>
            {/* {status === "finished" ? ( */}
              <div className="col-12 col-md-2 text-end">
                <a onClick={() => toggleProgressBar()} className="btn btn-light btn-sm">
                  Close
                </a>
              </div>
            
          </div>
        </div>
      )}

      {showorderBar && (
        <div className="progress_bar fixed-bottom">
          <div className="row">
            <div className="col-12 col-md">
              <div className="d-flex">
                <div className="p-3">
                  {statusbulk === "pending" ? <div className="spinner"></div> : ""}
                </div>
                <div className=" ">
                  <h4>Bulk Shipment Processing ...</h4>
                  {statusbulk === "pending" ? (
                    <small>Bulk Shipment Booking has been started and running in background.</small>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <h4>
                {completedOrdersbulk} <small>/{totalOrdersbulk}</small>
              </h4>
              {statusbulk === "finished" ? <small>Bulk Shipment Completed</small> : ""}
            </div>
            <div className="col-12 col-md-2 text-end">
              <a href={`/customer/bulk_orders_log_detail/${viewLogslogid}`} className="btn btn-light btn-sm">
                View Logs
              </a>
            </div>
            {/* {statusbulk === "finished" ? ( */}
              <div className="col-12 col-md-2 text-end">
                <a onClick={() => toggleProgressBarbulk()} className="btn btn-light btn-sm">
                  Close
                </a>
              </div>
            {/* ) : (
              ""
            )} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;