import React, { useEffect, useState } from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Create_order_nav from './Create_order_nav'
import axios from 'axios'
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
//import cashfree from '../../cashfreeUtil';

import { load } from '@cashfreepayments/cashfree-js';
import Swal from 'sweetalert2'

import ReactLoading from 'react-loading';
import config from "../../config"

const Easebuzz_success = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    let navigate = useNavigate()
    let location = useLocation()
    let userData = {};
    if (userDetail != null) {
        userData = JSON.parse(userDetail)
    }

    React.useEffect(()=>{
        const urlParams = new URLSearchParams(window.location.search);
        let transaction_id = urlParams.get('transaction_id');
    
       if(transaction_id) {
             
                  const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 3000
                        })
                        Toast.fire({
                            background: "#1EA11A",
                            type: 'success',
                            title: "Wallet Amount Added",
                            color: "white"
                        });
                        navigate('/customer/dashboard')
                       
                 
                 
              
    
        }
    })
  

  return (
    <div>
      <div className='row'>
        <div className='card'>
            <div className='card-body'>
                <div className='col-4'></div>
                <div className='col-4'>
                    <h5>Payment Success</h5>
                </div>
                <div className='col-4'></div>
              

            </div>
        </div>
      </div>
    </div>
  )
}

export default Easebuzz_success
