import React, { useState, createRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import config from "../../config"
import { useNetworkState } from 'react-use';
import ReactLoading from 'react-loading';
import Login_footer from "./Login_footer";
const Customer_register = () => {
  let navigate = useNavigate();
  const isOnline = useNetworkState();
  console.log("isONline  === " ,isOnline )
  let userDetail = localStorage.getItem('ship_rocket_user');
  
  // console.log("userDetail ===>   ",userDetail);
  const [state, setState] = useState({ email: "",password:"" });
  const [regsterState, setRegsterState] = useState({ email: "",password:"",full_name:"", company_name:""  , mobile_number:""});
  const [modalState, setModalState] = useState({ show: false });
  const [typeState, setTypeState] = useState({ type: "login" });
  const [userState, setUserState] = useState({ full_name: "" });
  const [styleState, setStyleState] = useState({ styles: 0 });
  const [trackState, setTrackState] = useState({tracking_no:""})
  const[trackresult,setTrackresult] = useState({})
  const[errorState,setErrorState] = useState()
  const[isLoading,setisLoading] = useState(false)
  const[btnstate,setbtnstate] = useState(false)


  

  
  
  useEffect(() => {

    if(userDetail!= null) {
      let uDetail = JSON.parse(userDetail)
      setUserState({full_name:uDetail.full_name})
      config.UserId = uDetail.customer_id
    }
  }, [])
  
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000
  })

  const inputHandleChange = (e) => {
    const value = e.target.value;
    const text = e.target.name;
    setState({ ...state, [text]: value })
  }
  const inputHandleChangeR = (e) => {
    const value = e.target.value;
    const text = e.target.name;
    setRegsterState({ ...regsterState, [text]: value })
  }
  const inputHandletracking = (e) => {
    const value = e.target.value;
    const text = e.target.name;
    setTrackState({ ...trackState, [text]: value })
  }

  const loginButton = (z) => {
   
    // console.log("state => ", state);
    if(state.email != "" && state.password != "") {
    let full_api = config.apiUrl + `/user/login_api`;
    let sendData = state;
    // console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
        // console.log("res ", res);
        if (res.data.isValid) {
          
            localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
            setUserState({full_name:res.data.output.full_name})
            config.UserId = res.data.output.customer_id
            
            setModalState({ show: false })
             window.location.reload(false);
           // navigate('/')
        }else {
          Toast.fire({
            background:"rgb(231, 91, 75)",
            type: 'unsuccess',
            title: "Email or password incorrct",
            color:"white"
          });
           
        }

    }).catch((e) => {


    });
  }else {
    Toast.fire({
      background:"rgb(231, 91, 75)",
      type: 'unsuccess',
      title: "Please fill both fields",
      color:"white"
    });
  }
}
const regitserButton = () =>{
  setbtnstate(true)
  // console.log("regsterState", regsterState);
  if(regsterState.email != "" && regsterState.password != ""  && regsterState.full_name != "" && regsterState.company_name!="") {
    let full_api = config.apiUrl + `/user/register_api`;
    let sendData = regsterState;
    // console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
        // console.log("res ", res);
        if (res.data.isValid) {
          
            // localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
            // setUserState({full_name:res.data.output.full_name})
            // config.UserId = res.data.output.customer_id
            setModalState({ show: false })
            Toast.fire({
              background:"#206bc4",
              type: 'success',
              title: res.data.message ,
              color:"white"
            });
            let sendState = {email:sendData.email , mobile_number:sendData.mobile_number};
            // console.log("sendState === " , sendState)
          
             navigate('/customer/verify_email',{state:sendState})
  setbtnstate(false)

            // navigate('/customer/dashboard')
        }else {
          Toast.fire({
            background:"#206bc4",
            type: 'unsuccess',
            title: res.data.message ,
            color:"white"
          });
  setbtnstate(false)

           
        }

    }).catch((e) => {


    });
  }else {
    Toast.fire({
      background:"#206bc4",
      type: 'unsuccess',
      title: "Please fill all fields",
      color:"white"
    });
  setbtnstate(false)

  }

}
const trackButton = () =>{
  setisLoading(true)
  

        setisLoading(false)
         navigate(`/tracking/`+ trackState.tracking_no)
  
}
const logOutButton = () =>{
  // console.log("userDetail ===>   ");
  localStorage.removeItem('ship_rocket_user');
  userDetail = null;
  setUserState({full_name:""})

}
const changestyle=()=>{
  setStyleState({styles:1})
}
const changeloginstyle=()=>{
  setStyleState({styles:0})
}
const openregister=()=>{
  setModalState({show:true})
  setStyleState({styles:1})
}
const openlogin=()=>{
  setModalState({show:true})
  setStyleState({styles:0})
}
  return (
    <div>
       {/* <section class="login_box_sec">
        <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
            <div class="logo pb-5">
                <h2><img src="../../img/logo_5.png" class="img-fluid" /></h2>
            </div>
            <div class="card login_box border-0 shadow-sm py-4 rounded-5">
                <div class="card-body">
                <h4 class="border-start ps-3 mb-4 border-primary border-4">Register Your Account</h4>
                    <form action=""  autocomplete="off">
                    <div class="mb-3 mt-3">
                          <label for="email" class="form-label">Company Name:</label>
                          <input type="text" class="form-control" id="email" placeholder="Enter your company name" name="company_name" onChange={(e) => inputHandleChangeR(e)} requiredautocomplete="new-username" />
                        </div>
                        <div class="mb-3 mt-3">
                          <label for="email" class="form-label">Full Name:</label>
                          <input type="text" class="form-control" id="email" placeholder="Enter your full name" name="full_name" onChange={(e) => inputHandleChangeR(e)} requiredautocomplete="new-username" />
                        </div>
                        <div class="mb-3 mt-3">
                          <label for="email" class="form-label">Email:</label>
                          <input type="email" class="form-control" id="email" placeholder="Enter email" name="email" onChange={(e) => inputHandleChangeR(e)} required autocomplete="new-username" />
                        </div>
                        <div class="mb-3 mt-3">
                          <label for="email" class="form-label">Mobile Number:</label>
                          <input type="email" class="form-control" id="email" placeholder="Enter your mobile number" name="mobile_number" onChange={(e) => inputHandleChangeR(e)} required autocomplete="new-username" />
                        </div>
                      
                        <div class="mb-3">
                          <label for="pwd" class="form-label">Create Password:</label>
                          <input type="password" class="form-control" id="pwd" placeholder="Enter Confirm password" name="password" onChange={(e) => inputHandleChangeR(e)} required  autocomplete="new-password" />
                        </div>
                        <div class="text-center pt-3">
                            <div class="d-grid">
                              <a onClick={() =>
   regitserButton()
 } class="btn btn-primary btn-block">Register Now</a>
                            </div>
                            <div class="pt-4">
                                Existing user? <a href="/customer/login" className="text-decoration-none">Login Now</a>
                            </div>
                        </div>
                      </form>
                </div>
            </div>
        </div>
    </section> */}
  <section class=" register_box_00">
    <nav class="navbar navbar-expand-sm pt-4">   
        <div class="container">
            <a class="navbar-brand" href="javascript:void(0)">
                <img src="/img/logo_5.png" class="img-fluid" />
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="mynavbar">
            <ul class="navbar-nav me-auto">
                
            </ul>
            <div class="d-flex">
                <a href="/customer/login" class="btn btn-primary">Sign In</a>
            </div>
            </div>
        </div>
    </nav>



  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-5">
        <div class="card">
          <div class="card-body">
          <h4>Register</h4>
            <small>Hey, Enter your details to sign up your new account</small>
            <div class="row mt-4">
                <div class="col-12 mb-3">
                    <div class="input-group">
                        <span class="input-group-text"><i class="material-icons-outlined">person</i></span>
                        <input type="text" class="form-control form-control-lg" placeholder="Enter your Full Name" name="full_name" onChange={(e) => inputHandleChangeR(e)} requiredautocomplete="new-username"/>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="input-group">
                        <span class="input-group-text"><i class="material-icons-outlined">business</i></span>
                        <input type="text" class="form-control form-control-lg" placeholder="Enter your company name" name="company_name" onChange={(e) => inputHandleChangeR(e)} requiredautocomplete="new-username"/>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="input-group">
                        <span class="input-group-text"><i class="material-icons-outlined">email</i></span>
                        <input type="email" class="form-control form-control-lg" placeholder="Enter your email address" name="email" onChange={(e) => inputHandleChangeR(e)} required autocomplete="new-username"/>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="input-group">
                        <span class="input-group-text"><i class="material-icons-outlined">phone_iphone</i></span>
                        <input type="number" class="form-control form-control-lg" placeholder="Enter your mobile number" name="mobile_number" onChange={(e) => inputHandleChangeR(e)} required autocomplete="new-username"/>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="input-group">
                        <span class="input-group-text"><i class="material-icons-outlined">vpn_key</i></span>
                        <input type="password" class="form-control form-control-lg" placeholder="Create your password" name="password" onChange={(e) => inputHandleChangeR(e)} required  autocomplete="new-password"/>
                    </div>
                </div>
                {/* <div class="col-12 mb-3">
                    <div class="input-group">
                        <span class="input-group-text"><i class="material-icons-outlined">vpn_key</i></span>
                        <input type="text" class="form-control form-control-lg" placeholder="Confirm your password again"/>
                    </div>
                </div> */}
            </div>

            <div class="d-grid py-4">
            {btnstate==false?  <a onClick={() =>
   regitserButton()
 } class="btn btn-primary btn-block">Sign Up</a>:<div className='' style={{marginLeft:"150px"}}><ReactLoading type={"spin"} color={"black"} height={40} width={30}/></div>}
            </div>

            <a href="/customer/login" class="register_box_00_a text-decoration-none"> Login here</a>
          </div>
        </div>
<Login_footer/>
        {/* <?php include 'login_footer.php';?> */}
      </div>
    </div>      
  </div>
</section>
    
    </div>
  )
}

export default Customer_register