import { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';

const useProgressTracker = () => {
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [status, setStatus] = useState('');
  const [completedOrders, setCompletedOrders] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [recordId, setRecordId] = useState('');
  let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }

    useEffect(() => {
        // Retrieve state from localStorage on page load
        const storedState = JSON.parse(localStorage.getItem('progressBarState'));
        console.log("storedState == " , storedState)
        if (storedState) {
          setShowProgressBar(storedState.showProgressBar);
          setCompletedOrders(storedState.completedOrders);
          setTotalOrders(storedState.totalOrders);
          setRecordId(storedState.recordId);
          setStatus(storedState.completedOrders==storedState.totalOrders?"finished":"pending");
        }
           if(storedState!=null){
        if(storedState.completedOrders && storedState.totalOrders && storedState.completedOrders !=storedState.totalOrders){
          let dataToSend = {
            customer_id: userData.customer_id, record_id: storedState.recordId
          };
      
          // console.log("datatoSend",dataToSend)
          let url = config.apiUrl + '/shipment/view_summary_upload_record?customer_id=' + userData.customer_id + '&record_id=' + storedState.recordId + '';
      
          // // console.log("headers => ", config.headers);
          axios({
            method: "get",
            url: url,
            headers: config.headers,
            responseType: "stream",
            onDownloadProgress: progressEvent => {
              // console.log("progressEvent ", progressEvent.currentTarget.response);
              let parseData = progressEvent.currentTarget.response;
              parseData = parseData.split("Z");
              parseData = JSON.parse(parseData[parseData.length - 1]);
      
              console.log(parseData)
              localStorage.setItem('progressBarState', JSON.stringify({
                showProgressBar:true,
                completedOrders:parseData.uploaded_record,
               recordId:storedState.recordId,
               totalOrders:parseData.total_record,
               status: parseData.status
              }));
              setShowProgressBar(storedState.showProgressBar);
              setCompletedOrders(parseData.uploaded_record);
              setTotalOrders(parseData.total_record);
              setRecordId(storedState.recordId);
              setStatus(parseData.uploaded_record==parseData.total_record?"finished":"pending");
              // console.log(state);
              // setStateRecord({ uploaded_record: parseData.uploaded_record, total_record: parseData.total_record, status: parseData.status })
            }
          })
      
        }
      }
      }, []);

      const toggleProgressBar = () => {
        // Toggle the visibility of the progress bar
        setShowProgressBar(!showProgressBar);
        localStorage.removeItem('progressBarState');
    // You may add other logic related to closing here
    // For example, resetting state variables
    setShowProgressBar(false);
    setCompletedOrders(0);
    setTotalOrders(0);
    setRecordId( null );
    setStatus( '' );
      };
      
  console.log("showProgressBar use" , showProgressBar)
  console.log("completedOrders use" , completedOrders)
  console.log("totalOrders use" , totalOrders)
  console.log("recordId use" , recordId)
  console.log("status use" , status)

  return { showProgressBar, completedOrders, totalOrders, recordId, status, toggleProgressBar };
  
};

export default useProgressTracker;