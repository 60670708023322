import React, { useState, useEffect } from "react";

import Left_menu from "./Left_menu";
import Header from "./Header";
import Create_order_nav from "./Create_order_nav";
import axios from "axios";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ProgressBar,
} from "react-bootstrap";
// import './customer_style.css';
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
import config from "../../config";
import { useNetworkState } from "react-use";
// import { CornerUpLeft } from "lucide-react";

const Boxes = () => {
  const [state, setState] = React.useState([]);
  const [addState, setaddState] = React.useState({
    box_name: "",
    box_height: 0,
    box_width: 0,
    box_length: 0,
    box_weight: 0,
  });
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector(".home-section");
    if (homeSection) {
      homeSection.classList.toggle("sidebar-closed");
    }
  };
  React.useEffect(() => {
    customer_boxes();
  }, []);
  const handleChange = (e) => {
    setaddState({ ...addState, [e.target.name]: e.target.value });
  };
  const save_customer_boxes = () => {
    if (
      addState.box_name != "" &&
      addState.box_height != "" &&
      addState.box_length != "" &&
      addState.box_weight != "" &&
      addState.box_width != ""
    ) {
      let full_api = config.apiUrl + `/user/add_customer_boxes`;
      let sendData = {
        box_name: addState.box_name,
        box_height: addState.box_height,
        box_length: addState.box_length,
        box_width: addState.box_width,
        box_weight: addState.box_weight,
      };
      axios
        .post(full_api, sendData, {
          headers: config.headers,
        })
        .then((res) => {
          console.log("res ", res);

          if (res.data.status == true) {
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              background: "rgb(231, 91, 75)",
              type: "success",
              title: res.data.message,
              color: "white",
            });
            customer_boxes();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 3000,
            });
            Toast.fire({
              background: "rgb(231, 91, 75)",
              type: "unsuccess",
              title: "Something Went Wrong",
              color: "white",
            });
          }
          // navigate('/categories')
          // let newCategory = res.data.category;
          //setState(state.concat([newCategory]))
        })
        .catch((e) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
          });
          Toast.fire({
            background: "rgb(231, 91, 75)",
            type: "unsuccess",
            title: "Something Went Wrong",
            color: "white",
          });

          console.log("----error:   ", e);
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Fill All The Details!",
      });
    }
  };

  const customer_boxes = () => {
    let full_api = config.apiUrl + `/user/get_customer_boxes`;
    let sendData = {};
    axios
      .post(full_api, sendData, {
        headers: config.headers,
      })
      .then((res) => {
        console.log("res ", res);

        if (res.data.status == true) {
          setState(res.data.output);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });

        console.log("----error:   ", e);
      });
  };

  return (
    <div>
      <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      {/* <?php include 'left.php';?> */}
      {/* <!-- Main content area Starts --> */}
      <section class="home ">
        {/* <?php include 'header.php';?> */}
        <Header />
        <div class="modal" id="Box_module">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h6 class="modal-title">Create Box</h6>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-12 col-md-12 mb-3">
                    <label for="" class="form-label">
                      Enter Box Name:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="text"
                      placeholder="Enter box name"
                      name="box_name"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div class="col-12 col-md-3">
                    <label for="" class="form-label">
                      Box Height:
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="text"
                      placeholder="Enter Height"
                      name="box_height"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div class="col-12 col-md-3">
                    <label for="" class="form-label">
                      Box Width:
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="text"
                      placeholder="Enter Width"
                      name="box_width"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div class="col-12 col-md-3">
                    <label for="" class="form-label">
                      Box Length:
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="text"
                      placeholder="Enter Length"
                      name="box_length"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div class="col-12 col-md-3">
                    <label for="" class="form-label">
                      Box Weight:
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      id="text"
                      placeholder="Enter Weight"
                      name="box_weight"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>

                <div class="d-grid mt-3">
                  <a
                    onClick={() => save_customer_boxes()}
                    class="btn btn-primary btn-block"
                  >
                    Submit
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row ms-2 mb-2">
          <div class="col-12 col-md">
            <div class="d-flex">
              <div class="back_btn_wrap">
                <a href="" class="back_btn">
                  {/* <i data-lucide="corner-up-left"></i> */}
                  {/* <CornerUpLeft /> */}
                </a>
              </div>
              <div class="">
                <h2>Boxes</h2>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5 text-end btns">
            <a
              href="#"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#Box_module"
            >
              Create Boxes
            </a>
          </div>
        </div>

        <div class="content-wrapper-data">
          <div class="card border-0">
            <div class="card-body">
              <div class="table-data">
                <table class="table table-hover table-responsive">
                  <thead>
                    <tr class="table-light">
                      <th>Box Name</th>
                      <th>Box Height</th>
                      <th>Box Width</th>
                      <th>Box Length</th>
                      <th>Box Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.map((sub) => (
                      <tr class="ship-now-container">
                        <td>
                          {sub.box_name}
                        </td>
                        <td>{sub.box_height}</td>
                        <td>{sub.box_width}</td>
                        <td class="channel_logo">{sub.box_length}</td>
                        <td>
                          {sub.box_weight}KG
                          <div class="custom-buttons button-container">
                            <div class="d-flex justify-content-between">
                              <div class="p-2 ship_now_2">
                                {/* <div class="mb-1">
                                  <a
                                    href="#"
                                    class="btn btn-secondary btn-sm"
                                    data-bs-toggle="tooltip"
                                    title="Edit Box"
                                  >
                                    <i
                                      class="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div> */}
                                {/* <div class="mb-1">
                                  <a
                                    href="#"
                                    class="btn btn-secondary btn-sm"
                                    data-bs-toggle="tooltip"
                                    title="Delete Box"
                                  >
                                    <i class="fa fa-ban" aria-hidden="true"></i>
                                  </a>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        </section>
   
      {/* <!-- Main content area END --> */}
    </div>
  );
};

export default Boxes;
