import React, { useState, createRef, useEffect } from "react";
import Left_menu from './Left_menu'
import Header from './Header'
import axios from "axios";
import { useLocation, useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import queryString from 'query-string';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar, ModalTitle } from "react-bootstrap";
import { useNetworkState } from 'react-use';
import UseProgressTracker from './UseProgressTracker'
import Footer from './Footer'
import UseBulkShipmentTracker from "./UseBulkShipmentTracker";


const Ticket_details = () => {
  const isOnline = useNetworkState();
  console.log("isONline  === " ,isOnline )
    const { ticket_id } = useParams();
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====shipments====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const { showProgressBar, completedOrders, totalOrders, recordId, status, toggleProgressBar } = UseProgressTracker();

    const[state,setState]=React.useState({ticket_detail:[],ticket_shipment:[],shipment_driver:[],shipment_vehicle:[],reply_detail:[],isLoading:true})
const [reply, setReply] = useState({  reply_content:"",reply_id:"",ticket_id:ticket_id })
   
const[modals,setModals] = useState({show: false})

const{showorderBar, completedOrdersbulk, totalOrdersbulk, logidstate, statusstateorder, toggleProgressBarbulk}= UseBulkShipmentTracker();

    const axios_get_api = () => {
 
        let full_api = config.apiUrl + `/home/ticket_detail`;
        
        let sendData = {
          dstatus: 1,
          ticket_id:ticket_id,
          
        }
       
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          // console.log("-----res-----  ",res);
         setState({...state,ticket_detail:res.data.output,reply_detail:res.data.output2,isLoading:false})
          // console.log("response", res);
        
    
        }).catch((e) => {
    
          // toast.configure()
          // toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }
      React.useEffect(() => {
        axios_get_api()
      }, [])

      const handleSupportReply = () => {
    
        // console.log("submit1");
       
        let state_detailes = {reply_content:reply.reply_content,reply_id:"", ticket_id:ticket_id}
      
      
        // console.log("report detailes is ",state_detailes);
       
        axios.post(config.apiUrl + `/home/add_reply`,  state_detailes , { headers: config.headers }).then((result)=>{
                // console.log("result is i ",result);
               
                if(result && result.data != undefined){
                   
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000
                      })
                      
                      Toast.fire({
                        background:"#206bc4",
                        type: 'success',
                        title:result.data.message,
                        color:"white"
                      });
                        setReply({...reply, reply_content:""}) 
                        axios_get_api()
                        
                    }
     
             }).catch((e) => {
           
     
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"#206bc4",
                    type: 'unsuccess',
                    title: "Something Went Wrong",
                    color:"white"
                  });
              // console.log("----error:   ", e);
            });}

            const closeTicket = () => {
    
              // console.log("submit1");
             
              let state_detailes = {ticket_id:ticket_id}
             
            
              // console.log("report detailes is ",state_detailes);
             
              axios.post(config.apiUrl+ `/home/close_ticket`,  state_detailes , { headers: config.headers }).then((result)=>{
                      // console.log("result is i ",result);
                     
                      if(result && result.data != undefined){
                         
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 3000
                          })
                          
                          Toast.fire({
                            background:"#206bc4",
                            type: 'success',
                            title:result.data.message,
                            color:"white"
                          });
                              setModals({show:false}) 
                              axios_get_api()
                            //   toast.configure();
                              
                              
                          }
           
                   }).catch((e) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000
                      })
                      
                      Toast.fire({
                        background:"#206bc4",
                        type: 'unsuccess',
                        title: "Something Went Wrong",
                        color:"white"
                      });
                    // console.log("----error:   ", e);
                  });}
          
    
    
    const handleChange = (event) => {
      // console.log(event);  
      setReply({
          ...reply,
          [event.target.name]: event.target.value,
        });
      
    }
    const nameFormat = (name)=> {
        
      if(name) {
          let nameArray =  name.split(" ");
         // // console.log("nameArray  ",nameArray);
          if(nameArray.length > 1) {
              let n1 = nameArray[0].substring(0,1).toUpperCase();
              let n2 = nameArray[1].substring(0,1).toUpperCase();
              name = n1+n2;
          }else {
              name = nameArray[0].substring(0,2).toUpperCase();
          }
      }else{
          name = "NO";
      }
      return name;
  }
  const handleClick=()=>{
    setModals({show:false})
  }
  return (
    
    <div>
<Left_menu value={12}/>
<section className="home">
<Header/>
<Modal show={modals.show} >
        <Modal.Header closeButton onClick={() => handleClick()}>
          <ModalTitle>
            <h4>Close Ticket</h4>
          </ModalTitle>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-4'>
          <h6>Do you really want to close the ticket ?</h6>
            
          </div>
        
         
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={() => closeTicket()} >
            Yes
          </Button>
          <Button variant="primary" onClick={() => handleClick()} >
            No
          </Button>
        </Modal.Footer>
      </Modal>
{state.isLoading==true?<ReactLoading type="bubbles" color="black"  style={{position:'absolute',right:'45%',height:'20px',width:'10%'}} />:
<section class="bg-light p-5">
    {state.ticket_detail.map((sub)=>(
  <div class="container ">


    <section class="bg-white p-5 mb-2">
      <div class="text-muted">
         <p><a href="/customer/tickets" className="text-decoration-none"><i class="fa fa-angle-left" aria-hidden="true"></i> Back</a>
        <strong class="ms-2 me-2">Ticket# <span class=" fw-bold">{sub.ticket_id}</span></strong>
        <span class={sub.status==0?"badge bg-warning ms-2 me-2":sub.status==1?"badge bg-success ms-2 me-2":"badge bg-danger ms-2 me-2"}>{sub.status==0?"Pending":sub.status==1?"Answered":"Closed"}</span>
        </p>
      </div>
      <div class="row pt-1">
        <div class="col-md-8">
             
             <div className='row'>
              <div className='col-6'>
              <section class="mt-3 mb-5">
              <h2>{sub.subject}</h2>
            </section>
              </div>
              <div className='col-6'>
                {sub.status!=2?
              <section className='text-end'>
            <p  class="btn btn-primary " style={{marginTop:"25px",cursor:"pointer"}} onClick={() => setModals({ show: true ,})} >Close Ticket</p>
            </section>
            :""}

              </div>
             </div>
           

            
           {state.reply_detail.map((item)=>(
            <section>
            {item.reply_id==userData.customer_id? 
                <div class="card shadow-sm mb-3">
                  <div class="card-body">
                    <div class="d-flex p-3 ">
                      <div class="p-2">
                        <img src={"https://dummyimage.com/50/1976d2/ffffff&text="+nameFormat(userData.full_name)} class="img-fluid rounded-circle" style={{minWidth: "50px"}} />
                      </div>
                      <div class="p-2">
                        <p>{item.reply_content}</p>
                        <div class="">
                        <small class="text-muted"><a href={"/profile/"+ userData.customer_id} className="text-decoration-none">{userData.full_name}</a> (Customer) </small><br/>
                        <small class="text-muted">{item.date} {item.time}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
             :item.reply_id==userData.transporter_id?
                <div class="card shadow-sm mb-3">
                  <div class="card-body">
                    <div class="d-flex p-3 ">
                      <div class="p-2">
                        <p>{item.reply_content}</p>
                        <div class="text-end">
                          <small class="text-muted"><a href={"/profile-carrier/" + userData.transporter_id} className="text-decoration-none">{userData.full_name} ({sub.ticket_branch_name})</a> (Transporter) </small><br/>
                          <small class="text-muted">{item.date} {item.time}</small>
                        </div>
                      </div>
                      <div class="p-2">
                        <img src={"https://dummyimage.com/50/004ba0/ffffff&text="+nameFormat(userData.full_name)} class="img-fluid rounded-circle" style={{minWidth: "50px"}} />
                      </div>
                    </div>
                  </div>
                </div> :
                item.reply_id!=userData.customer_id ?
               
                <div class="card shadow-sm mb-3">
                  <div class="card-body">
                    <div class="d-flex p-3 ">
                      <div class="p-2">
                        <p>{item.reply_content}</p>
                        <div class="text-end">
                          <small class="text-muted"><a href="" className="text-decoration-none">{item.full_name} </a> (System Admin) </small><br/>
                          <small class="text-muted">{item.date} {item.time}</small>
                        </div>
                      </div>
                      <div class="p-2">
                        <img src={"https://dummyimage.com/50/63a4ff/ffffff&text="+nameFormat(item.full_name)} class="img-fluid rounded-circle" style={{minWidth: "50px"}} />
                      </div>
                    </div>
                  </div>
                </div>
                
               :""}
            </section>
            ))}
           
           {sub.status!=2?
            <section class="bg-light p-5">
              <label for="comment">Reply:</label>
              <textarea class="form-control" rows="5" id="comment" name="reply_content" placeholder="Please enter details" onChange={(e) => handleChange(e)} value={reply.reply_content}></textarea>
              <button type="button" class="btn btn-primary mt-3" onClick={()=>handleSupportReply({ticket_id:sub.ticket_id})}>Reply</button>
            </section>
            :""}




            
        </div>
        {/* {sub.shipment_id!="" && sub.shipment_id!=undefined?
       
        <div class="col-md-4 bg-light">
          <section class="ps-4 pt-3">
            <h3 class="fs-5 pb-3">Basic information</h3>
            <div class="row">
              <div class="col-md-6">
                <small class="text-muted">Shipment Tracking #</small>
                <h4 class="fs-5">{sub.shipment_id}</h4>
              </div>
              <div class="col-md-6">
                <small class="text-muted">PO/Refernce #</small>
                <h4 class="fs-5">293848</h4>
              </div>
              <div class="col-md-6">
                <small class="text-muted">Pickup</small>
                <h4 class="fs-6">{sub.pickup_address}</h4>
              </div>
              <div class="col-md-6">
                <small class="text-muted">Delivery</small>
                <h4 class="fs-6">{sub.delivery_address}</h4>
              </div>
              <div class="col-md-6">
                <small class="text-muted">Shipper</small>
                <h4 class="fs-5"><Link to={"/profile/" + sub.user_id}>{sub.full_name}</Link></h4>
              </div>
              <div class="col-md-6">
                <small class="text-muted">Item(s)</small>
                <h4 class="fs-5">{sub.qty}</h4>
              </div>
              <small class="text-muted">Noted: {sub.description} </small>
            </div>
          </section>

          <hr class="m-4"/>


          <section class="ps-4">
            <h3 class="fs-5 pb-3">Order details</h3>
            <div class="row">
              <div class="col-md-6">
                <small class="text-muted">Carrier</small>
                <h4 class="fs-5 text-primary">{sub.branch_name!="" && sub.branch_name!=undefined? <Link to={"/carrier/" + sub.transporter_id}>{sub.branch_name}</Link>:"Not Booked Yet"}</h4>
              </div>
              <div class="col-md-6">
                <small class="text-muted">Order on</small>
                <h4 class="fs-5"><Moment format="MMMM DD , YYYY" unix>{sub.created}</Moment>
                <small class="text-muted">{sub.time}</small></h4>
              </div>
              <div class="col-md-6">
                <small class="text-muted">Total Price #</small>
                <h4 class="fs-5">${sub.item_value}</h4>
              </div>
              <div class="col-md-6">
                <small class="text-muted">Total Weight</small>
                <h4 class="fs-5">{sub.item_weight}KG</h4>
              </div>
            </div>
          </section>

          <hr class="m-4"/>

          <section class="ps-4">
            <h3 class="fs-5 pb-3">Shipping Items</h3>
            <div class="row">
              <div class="col-md-12">
                <h4 class="fs-6">{sub.title}</h4>
                <small class="text-muted">{sub.item_weight} KG - {sub.item_length}x{sub.item_height}x{sub.item_width} m</small>
              </div>
             
              
            </div>
          </section>
          
          <hr class="m-4"/>

          <section class="ps-4">
            <h3 class="fs-5 pb-3">Shipping Equipments</h3>
            <div class="row">
              <div class="col-md-6">
                <small class="text-muted">Truck Type</small>
                <h4 class="fs-6">{sub.service_type}</h4>
              </div>
              {
              state.shipment_vehicle.map((subs)=>(
              <div class="col-md-6">
               
                <small class="text-muted">Truck Number</small>
                <h4 class="fs-6">{subs.vehicle_number}</h4>
              </div>))}
              {
              state.shipment_vehicle.map((subscriber)=>(
              <div class="col-md-6 ">
                <small class="text-muted">Driver Name</small>
                <h4 class="fs-6">{subscriber.driver_name}</h4>
              </div>))}
              {
              state.shipment_vehicle.map((subscriber)=>(
              <div class="col-md-6 ">
                <small class="text-muted">Driver Mobile Number</small>
                <h4 class="fs-6">{subscriber.mobile_number}</h4>
              </div>))}
               
            </div>
          </section>

        </div>
        :""} */}
      </div>
    </section>

    
  </div>
  ))}
</section>

}
</section>
<Footer
                    showProgressBar={showProgressBar}
                    completedOrders={completedOrders}
                    totalOrders={totalOrders}
                    viewLogs={recordId}
                    status ={status}
                     toggleProgressBar={toggleProgressBar} 
                     showorderBar={showorderBar}
                     completedOrdersbulk={completedOrdersbulk}
                     totalOrdersbulk={totalOrdersbulk}
                     viewLogslogid={logidstate}
                     statusbulk ={statusstateorder}
                      toggleProgressBarbulk={toggleProgressBarbulk} 
                  />  
    </div>
  )
}
export default Ticket_details
